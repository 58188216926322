<template>
	<div id="col_menu">
		<div id="menu_sup">
			<ul>
				<li>
					<img id="logo-vert" src="@/assets/logo.svg">
				</li>
				<li>
					<b-button
						v-b-modal.modal-confirm
						title="Página Inicial" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-white-50" icon="home"/></b-button>
				</li>
				<li>
					<b-button
						@click="emitir('show_registros')"
						title="Acessos" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-white-50" icon="people-arrows"/></b-button>
				</li>

				<li v-if="criadoPeloSistemaEventos">	
					<b-button
						@click="emitir('show_recursos')"
						title="Recursos" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-white-50" icon="paperclip"/></b-button>
				</li>
				<li>
					<b-button
						@click="emitir('abrir_configuracoes')"
						title="Configurações" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-white-50" icon="tools"/></b-button>
				</li>
				<li>
					<b-button
						@click="emitir('toggle_fullscreen')"
						title="Tela Cheia" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-white-50" icon="arrows-alt"/></b-button>
				</li>
				<b-modal id="add_moderador"
					centered
					body-bg-variant="dark"
					body-text-variant="light"
					footer-bg-variant="dark"
					footer-text-variant="light"
					hide-header
					@ok="addUsuarios('moderador')"
				>
					<AddUsuarioSala ref="add_usuarios_moderador" tipo="moderador" />
				</b-modal>
				<li v-if="this.$store.state.sala.nivel_usuario === 'moderador'">
					<b-button
						v-b-tooltip.hover.top title="Adicionar Moderador"
						v-b-modal.add_moderador
						variant="link"
					><font-awesome-icon icon="crown" class="text-white-50" /></b-button>
				</li>
			</ul>
		</div>
		<div id="menu_inf">
			<ul>
				<li>
					<b-button v-if="false"
						@click="mensagens_visible = !mensagens_visible"
						title="Mensagens Privadas" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon  icon="envelope"
							v-bind:class="{'text-white-50': !mensagens_visible, 'text-white': mensagens_visible}"/>
					</b-button>
				</li>
				<li v-if="this.$store.state.sala.nivel_usuario !== 'moderador'">
					<b-button
						@click="emitir('toggle_chat')"
						title="Chat" v-b-tooltip.hover.right
						variant="link"
					>
						<font-awesome-icon icon="comments"
							v-bind:class="{'text-white-50': !chat_visible, 'text-white': chat_visible}"/>
						<span class="badge-danger" v-if="chat_nao_lidas>0">{{ chat_nao_lidas }}</span>
					</b-button>
				</li>
				<li>
					<b-button
						@click="emitir('logout')"
						title="Sair" v-b-tooltip.hover.right
						variant="link"
						style="color: red"
					><font-awesome-icon icon="sign-out-alt" class="text-red" />
					</b-button>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import AddUsuarioSala from "@/components/widgets/AddUsuarioSala";
import {addUsuarios} from "@/modulos/sala";
export default {
	components: {AddUsuarioSala},
	name: "SalasMenu",
	props: ['chat_visible', 'chat_nao_lidas'],
	data(){
		return {
			criadoPeloSistemaEventos: false,
		}
	},
	methods:{
		addUsuarios,
		emitir(sinal){
			this.$root.$emit(sinal)
		}
	},

	created(){
		let sala_meet_id = this.$store.state.sala.id
		this.$eventos.get(`/atividades/?sala_meet_id=${sala_meet_id}`)
		.then(response => {
			if(response.data.results != ''){
				this.criadoPeloSistemaEventos = true
			}else{
				this.criadoPeloSistemaEventos = false
			}
		})
	}
}
</script>

<style scoped>


ul{
	margin: 0;
	padding: 0;
	list-style-type: none;
}
li{
	text-align: center;
	padding: 10px 5px;
	position: relative;
}

#col_menu{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50px;
	background-color: #474747;
}

#col_menu div{
	position: absolute;
	width: 50px;
}

#menu_sup{
    top: 0;
}

#menu_inf{
    bottom: 0;
}

#logo-vert{
    width: 30px;
}

.badge-danger{
    position: absolute;
    border-radius: 7px;
    font-size: 10px;
    width: 14px;
    height: 14px;
}
</style>