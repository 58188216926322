<template>
	<div>
		<div ref="jitsiContainer" id="jitsiContainer" :style="style" />
	</div>
</template>

<script>
export default {
	name: "JitsiMeetWS",
	props: {
		domain: {type: String, required: true},
		tipo: {type: String, default: 'apresentador'},
		loading_painel: {type: Boolean, default: false},
		screen: {type: Object, required: true}
	},
	data(){
		return{
			is_mounted: false,
			gravando: this.$parent.jitsi_gravando
		}
	},
	watch:{
		gravando(){
			this.$root.$emit('jitsi_gravando', this.gravando)
		}
	},
	computed: {
		style(){
			return {
				height: this.screen.mobile && this.screen.orientation === 'portrait' ? '40vh' : '100vh',
				width: '100%',
				display: 'grid'
			}
		},
		moderadores_ids() {
			let mod = []
			this.$store.state.sala.moderadores.forEach(m => mod.push(m.id))
			return mod
		},
		apresentadores_ids() {
			let apr = []
			this.$store.state.sala.apresentadores.forEach(a => apr.push(a.id))
			return apr
		},
		stream_opt(){
			if(this.$store.state.sala.criar_youtube &&
				this.$store.state.sala.youtube_streamkey &&
				this.$store.state.sala.broadcast &&
				this.$store.state.sala.nivel_usuario === 'moderador'
			){
				return {
					mode: 'stream',
					shouldShare: true,
					youtubeStreamKey: this.$store.state.sala.youtube_streamkey,
					youtubeBroadcastID: this.$store.state.sala.broadcast
				}
			}else{
				return {mode: 'file'}
			}
		}
	},
	methods:{
		embedJitsiWidget() {
			let token_sala = this.$store.state.sala.token
			if(this.$store.state.sala.interprete !== null && this.$store.state.sala.nivel_usuario === 'apresentador'){
				token_sala = this.$store.state.sala.token_complementar
			}
			const options = {
				...this.options,
				roomName: token_sala,
				defaultLaunchInBrowserOnMobile: true,
				configOverwrite: {
					liveStreamingEnabled: true,
					enableWelcomePage: false,
					enableClosePage: true,
					startWithAudioMuted: true,
					defaultLaunchInBrowserOnMobile: true,
					disableDeepLinking: true,
					disableInviteFunctions: true,
					enableNoisyMicDetection : true,
					enableLipSync: false,
					disableAEC: false
				},
				interfaceConfigOverwrite: {
					DEFAULT_LOCAL_DISPLAY_NAME: 'Você',
					MOBILE_APP_PROMO: false,
					DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
					TOOLBAR_BUTTONS: [
						'camera', 'desktop', 'sharedvideo', 'raisehand', 'videoquality', 'filmstrip',
						'stats', 'videobackgroundblur', 'download'
					]
				},
				parentNode: this.$refs.jitsiContainer,
			};
			if(this.tipo === 'moderador'){
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('microphone')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('settings')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('camera')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('mute-everyone')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('security')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('etherpad')
				options.configOverwrite['followMe'] = true
			}else{
				// if(this.$store.state.sala.microfone_bloqueado === false) options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('microphone')
				if(this.$store.state.sala.microfone_bloqueado === false) options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('microphone')
				options.configOverwrite['remoteVideoMenu'] = {disableKick: true}
				options.configOverwrite['disableRemoteMute'] = true
			}
			this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options);
		},
		loadScript(src, cb) {
			const scriptEl = document.createElement('script');
			scriptEl.src = src;
			scriptEl.async = 1;
			document.querySelector('head').appendChild(scriptEl);
			scriptEl.addEventListener('load', cb);
		},
		setDispositivo(params){
			let tipo = params.type === 'altofalante' ? 'output' : 'input'
			this.jitsiApi.isDeviceChangeAvailable(tipo).then(isDeviceChangeAvailable => {
				if(isDeviceChangeAvailable) {
					if(params.type === 'microfone'){
						this.jitsiApi.setAudioInputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}else if(params.type === 'altofalante'){
						this.jitsiApi.setAudioOutputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}else if(params.type === 'camera'){
						this.jitsiApi.setVideoInputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}
				}else{
					this.$toast.open({
						message: 'Não é possível definir o dispositivo ativo!',
						type: 'error',
						duration: 5000,
						position: 'top-right'
					})
				}
			});
		}
	},
	mounted() {
		this.$root.$on('definir_dispositivo', params=>this.setDispositivo(params));
		this.loadScript(`https://${this.domain}/external_api.js`, () => {
			if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
			this.embedJitsiWidget();
			this.jitsiApi.executeCommand('displayName', this.$store.state.user.nome)
			this.jitsiApi.executeCommand('avatarUrl', this.$store.state.user.avatar)
			this.jitsiApi.executeCommand('email', this.$store.state.user.email)
			this.jitsiApi.executeCommand('subject', ' ')
			if(this.$store.state.user.microfone) this.jitsiApi.executeCommand('toggleAudio')
			this.is_mounted = true
			this.$root.$emit('jitsi_mounted')

			this.jitsiApi.addListener('cameraError', err=>{
				console.log(err)
				this.$toast.open({
					message: 'Não é possível acessar sua câmera!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
			this.jitsiApi.addListener('micError', err=>{
				console.log(err)
				this.$toast.open({
					message: 'Não é possível acessar seu microfone!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
			this.jitsiApi.on('recordingStatusChanged', evnt=>{
				console.log(evnt)
				this.$root.$emit('recording_change', evnt)
			})
			// this.jitsiApi.addListener('dominantSpeakerChanged', ()=>{
			// 	this.$root.$emit('update_dispositivos')
			// })
			// this.jitsiApi.addListener('deviceListChanged', ()=>{
			// 	this.$root.$emit('update_dispositivos')
			// })
		});
	},
	beforeDestroy() {
		this.$root.$off('definir_dispositivo')
	}
}
</script>

<style scoped>

</style>