export var dadosWS = {
    ws: null,
    acessos: null,
    dispositivos_obj: {
        disponiveis: {
            microfones: {},
            altofalantes: {},
            cameras: {}
        },
        ativos: {
            microfones: {},
            microfones_list: [],
            altofalantes: {},
            altofalantes_list: [],
            cameras: {},
            cameras_list: []
        }
    }
}

// METHODS
export function atualizaDados(data){
    if(data.data_type === 'acessos') this.acessos = data.data
}
export function chatEnviaMensagem(msg){
    this.sendMessage({
        action: 'sendMessageChat',
        mensagem: msg
    })
}
export function emitMudanca(data){
    this.sendMessage(data)
}
export function getDispositivos(callback=null){
    this.$refs.jitsiRef.jitsiApi.isDeviceListAvailable().then(isAvailable=>{
        if(isAvailable){
            this.$refs.jitsiRef.jitsiApi.getAvailableDevices().then(devices=>{
                this.dispositivos_obj.disponiveis.microfones = devices.audioInput;
                this.dispositivos_obj.disponiveis.altofalantes = devices.audioOutput;
                this.dispositivos_obj.disponiveis.cameras = devices.videoInput;
                this.$refs.jitsiRef.jitsiApi.getCurrentDevices().then(devices=>{
                    this.dispositivos_obj.ativos.microfones = devices.audioInput;
                    if(devices.audioInput && 'deviceId' in devices.audioInput){
                        this.dispositivos_obj.ativos.microfones_list = [devices.audioInput.deviceId]
                    }else if(devices.audioInput){
                        for(let disp of devices.audioInput){
                            this.dispositivos_obj.ativos.microfones_list.push(disp.deviceId)
                        }
                    }
                    this.dispositivos_obj.ativos.altofalantes = devices.audioOutput;
                    if(devices.audioOutput && 'deviceId' in devices.audioOutput){
                        this.dispositivos_obj.ativos.altofalantes_list = [devices.audioOutput.deviceId]
                    }else if(devices.audioOutput){
                        for(let disp of devices.audioOutput){
                            this.dispositivos_obj.ativos.altofalantes_list.push(disp.deviceId)
                        }
                    }
                    this.dispositivos_obj.ativos.cameras = devices.videoInput;
                    if(devices.videoInput && 'deviceId' in devices.videoInput){
                        this.dispositivos_obj.ativos.cameras_list = [devices.videoInput.deviceId]
                    }else if(devices.videoInput){
                        for(let disp of devices.videoInput){
                            this.dispositivos_obj.ativos.cameras_list.push(disp.deviceId)
                        }
                    }
                    if(callback) callback()
                })
            })
        }else{
            this.$toast.open({
                message: 'Não é possível consultar a lista de dispositivos instalados na máquina!',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            })
        }
    });
}
export function muteUsuario(data){
    if(data.usuario === this.$store.state.user.id){
        if(this.$refs.jitsiRef.jitsiApi.isAudioMuted() !== data.muted){
            this.$refs.jitsiRef.jitsiApi.executeCommand('toggleAudio')
        }
    }
}
export function processaMudanca(data){
    return new Promise(resolve=>
    {
        // EVENTOS DE INTERESSE APENAS DO MODERADOR
        if (this.$store.state.sala.nivel_usuario === 'moderador') {
            if (data.type === 'audio-mute-status-changed' && data.usuario !== this.$store.state.user.id) {
                console.log(data)
            }
        }
        if (data.type === 'sala') {
            // MUTE DE ÁUDIO E VÍDEO PARA USUÁRIO
            let nivel = this.$store.state.sala.nivel_usuario
            let lista = nivel === 'apresentador' ? data.data.apresentadores : nivel === 'participante' ? data.data.participantes : data.data.moderadores
            lista.forEach(usr => {
                if (usr.id === this.$store.state.user.id) {
                    this.$refs.jitsiRef.jitsiApi.isAudioMuted().then(muted => {
                        if (muted === usr.microfone) {
                            console.log('RECEIVED AUDIO MUTE STATUS: ', !usr.microfone)
                            this.$store.commit('ignoreEvent', 'audioMuteStatusChanged')
                            this.$refs.jitsiRef.jitsiApi.executeCommand('toggleAudio');
                        }
                    });
                    this.$refs.jitsiRef.jitsiApi.isVideoMuted().then(muted => {
                        if (muted === usr.video) {
                            console.log('RECEIVED VIDEO MUTE STATUS: ', !usr.video)
                            this.$store.commit('ignoreEvent', 'videoMuteStatusChanged')
                            this.$refs.jitsiRef.jitsiApi.executeCommand('toggleVideo');
                        }
                    });
                }
            })
            
            // BLOQUEIO DE MICROFONE --> DESABILITADO PORQUE A VERSÃO DO JITSI RETORNA 'NOT SUPPORTED COMMAND NAME'
            // this.trataBotaoMicrofone()
            resolve()
        }
    })
}
export function eventsOn(){
    this.$root.$on('mute_usuario', data=>this.muteUsuario(data))
    this.$root.$on('mudanca', data=>this.emitMudanca(data))
    this.$root.$on('data_update', data=>this.atualizaDados(data))
    this.$root.$on('send_message_chat', msg=>this.chatEnviaMensagem(msg))
    this.$root.$on('update_dispositivos', ()=>this.getDispositivos())
    this.$root.$on('receive_message_chat', mensagem=>this.updateStoreChatMessages(mensagem))
    this.$root.$on('toggle-bloqueio-microfone', ()=>this.trataBotaoMicrofone())
}
export function eventsOff(){
    this.$root.$off('mute_usuario')
    this.$root.$off('mudanca')
    this.$root.$off('data_update')
    this.$root.$off('send_message_chat')
    this.$root.$off('update_dispositivos')
    this.$root.$off('receive_message_chat')
    this.$root.$off('toggle-bloqueio-microfone')
}
export function connect(){
    this.ws = new WebSocket(`${this.$store.state.default_ws_domain}/sala/${this.$store.state.sala.slug}/?token=${this.$store.state.accessToken}`)
    this.ws.onopen = ()=>{
        this.sendMessage({
            action: 'connect',
        })
    }
    console.log('Conectado ao WebSocket!')
    this.ws.onmessage = event=>{
        this.receiveMessage(event)
    }
}
export function disconnect(){
    this.sendMessage({
        action: 'disconnect',
    })
    this.ws.close(1000)
    console.log('Conexão com o WebSocket encerrada!')
}
export function sendMessage(message){
    message.usuario = this.$store.state.user.id
    this.ws.send(JSON.stringify(message))
}
export function receiveMessage(message){
    let data = JSON.parse(message.data)
    if('action' in data){
        this.$root.$emit(data.action, data)
    }else if(['audio-mute-status-changed'].includes(data.type)){
        this.processaMudanca(data)
    }else if(data.type === 'sala'){
        this.processaMudanca(data).then(()=>{
            this.$store.commit('updateSala', data.data)
        })
    }else if(data.type === 'youtube_stats'){
        this.$root.$emit('youtube_stats', data.message)
    }
}
export function toggleBloqueioMicrofones(){
    let sala = this.$store.state.sala
    sala['microfone_bloqueado'] = !sala['microfone_bloqueado']
    this.$http.put(`salas/${sala.id}/`, sala)
}
export function trataBotaoMicrofone(){
    if(this.$store.state.sala.nivel_usuario !== 'moderador'){
        let buttons = [
            'camera', 'desktop', 'sharedvideo', 'raisehand', 'videoquality', 'filmstrip',
            'stats', 'videobackgroundblur', 'download'
        ]
        if(this.$store.state.sala.microfone_bloqueado === false) buttons.push('microphone')
        window.jitsiRef.jitsiApi.executeCommand('overwriteConfig', {toolbarButtons: buttons});
    }
}
export function updateStoreChatMessages(message){
    this.$store.commit('updateChatMessages', message.mensagem)
}
