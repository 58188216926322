<template>
	<fullscreen @change="fullscreenChange" ref="fullscreen">
		<div ref="screen" class="screen">
			<SalaTitulo
				:moderadores="$store.state.sala.moderadores_nomes"
				:apresentadores="$store.state.sala.apresentadores_nomes"
				:participantes="$store.state.sala.participantes_nomes"
				:my_jitsi_id="my_jitsi_id"
				tipo="M"
			/>
			<JitsiMeetWS ref="jitsiRef" :domain="$store.state.sala.servico_jitsi" />
<!--				<JitsiMeetLibWS ref="jitsiRef" :domain="$store.state.sala.servico_jitsi"/>-->
			<div class="botoes_container">
				<div class="botoes">
					<b-button-group>
						<b-button
							variant="light"
							:class="{'text-danger': mic_mudo}"
							:disabled="$store.state.sala.microfone_bloqueado"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('toggleAudio')"
							v-b-tooltip.hover.top title="Microfone"
						>
							<font-awesome-icon :icon="mic_mudo ? 'microphone-slash' : 'microphone'" />
						</b-button>
						<b-button
							variant="light"
							:class="{'text-danger': mic_mudo}"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('toggleVideo')"
							v-b-tooltip.hover.top title="Câmera"
						>
							<font-awesome-icon :icon="cam_mudo ? 'video-slash' : 'video'" />
						</b-button>
						<b-button variant="light"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('toggleShareScreen')"
							v-b-tooltip.hover.top title="Compartilhar Tela"
						>
							<font-awesome-icon icon="tv" />
						</b-button>
						<b-button variant="light" style="padding-top: 2px"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('shareYoutube', 'https://www.youtube.com/watch?v=uKCZukroRSw')"
							v-b-tooltip.hover.top title="Compartilhar Vídeo do Youtube"
						>
							<svg style="height: 14px" version="1.1" viewBox="0 0 156 110" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
								<metadata>
									<rdf:RDF><cc:Work rdf:about=""><dc:format>image/svg+xml</dc:format>
									<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title/></cc:Work></rdf:RDF>
								</metadata>
								<path d="m77.922 0.40039s-48.701 0.10039-60.801 3.4004a19.6 19.6 0 0 0-13.801 13.799c-3.66 21.5-5.0784 54.26 0.10156 74.9a19.6 19.6 0 0 0 13.799 13.801c12.1 3.3 60.801 3.2988 60.801 3.2988s48.699 0.00117 60.799-3.2988a19.6 19.6 0 0 0 13.801-13.801c3.86-21.53 5.0504-54.27-0.09961-75a19.6 19.6 0 0 0-13.801-13.801c-12.1-3.3-60.799-3.2988-60.799-3.2988zm-20.121 31.199 40.398 23.4-40.398 23.4v-46.801z" fill="#000"/>
							</svg>
						</b-button>
						<b-button variant="light"
							@click="$refs.salaMenu.emitir('configuracoes')"
							v-b-tooltip.hover.top title="Selecionar Microfone e Câmera"
						>
							<font-awesome-icon icon="headset" />
						</b-button>
						<b-button variant="light"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('toggleRaiseHand')"
							v-b-tooltip.hover.top title="Erguer/Baixar Mão"
						>
							<font-awesome-icon icon="hand-paper" />
						</b-button>
					</b-button-group>
				</div>
			</div>
		</div>
		<div ref="menu" class="menu">
			<SalasMenuWS
				ref="salaMenu"
				:acessos="acessos"
				:dispositivos="dispositivos_obj"
			/>
		</div>
	</fullscreen>
</template>

<script>
import SalasMenuWS from "@/components/Salas/SalasMenuWS";
import SalaTitulo from "@/components/Salas/SalaTitulo";
import JitsiMeetWS from "@/components/Salas/Jitsi/JitsiMeetWS";
import {
	dadosWS,
	atualizaDados,
	chatEnviaMensagem,
	emitMudanca,
	eventsOn,
	eventsOff,
	getDispositivos,
	muteUsuario,
	processaMudanca,
	receiveMessage,
	sendMessage,
	connect,
	disconnect
} from "@/modulos/salaWS"
import {dados, fullscreenChange, toggleFullScreen} from "@/modulos/sala";
export default {
	name: "SalaModeradorWS",
	components: {SalasMenuWS, SalaTitulo, JitsiMeetWS},
	data(){
		return{
			sala: this.$store.state.sala,
			sala_tipo: 'moderador',
			carregando: true,
			...dados,
			...dadosWS,
		}
	},
	methods:{
		atualizaDados,
		chatEnviaMensagem,
		emitMudanca,
		eventsOn,
		eventsOff,
		getDispositivos,
		muteUsuario,
		processaMudanca,
		connect,
		disconnect,
		fullscreenChange,
		toggleFullScreen,
		receiveMessage,
		sendMessage,
		abrirContentSlide(state){
			if(state === 'open'){
				this.$refs.menu.style.width = "450px"
				this.$refs.screen.style.width = "calc(100vw - 450px)"
			}else{
				this.$refs.menu.style.width = "50px"
				this.$refs.screen.style.width = "calc(100vw - 50px)"
			}
		},
	},
	created() {
		this.connect()
		this.$store.commit('setTransition', 'slide-left')
	},
	mounted() {
		this.eventsOn()
		this.$root.$on('toggle_fullscreen', ()=>this.toggleFullScreen())
		this.$root.$on('open-content-slide', state=>this.abrirContentSlide(state))
		this.$root.$on('jitsi_mounted', ()=> {
			// LISTENERS
			this.$refs.jitsiRef.jitsiApi.addListener('readyToClose', ()=>{
				this.disconnect()
				this.$router.push({name: 'home'})
			})
			this.$refs.jitsiRef.jitsiApi.addListener('videoConferenceJoined', ()=>{
				this.carregando = false;
			})

			// ENVIA AO BACKEND O ID DO PARTICIPANTE DO JITSI
			let parts = this.$refs.jitsiRef.jitsiApi.getParticipantsInfo()
			parts.forEach(part => {
				if (this.$store.state.user.nome === part['displayName']) {
					this.my_jitsi_id = part['participantId']
					this.$http.post('usuario_configs/', {
						'usuario': this.$store.state.user.id,
						'sala': this.$store.state.sala.id,
						'jitsi_id': part['participantId']
					})
				}
			})

			// EXIBE A BARRA DE BOTÕES
			// this.carregando = false;

			// ATUALIZA LISTA DE DISPOSITIVOS
			this.getDispositivos()
		})
	},
	beforeDestroy() {
		this.eventsOff()
		this.$refs.jitsiRef.jitsiApi.executeCommand('hangup')
		this.disconnect()
		this.$root.$off('toggle_fullscreen')
		this.$root.$off('open-content-slide')
		this.$root.$off('jitsi_mounted')
	},
}
</script>

<style scoped>
	.screen{
		width: calc(100vw - 50px);
		height: 100vh;
		float: left;
		transition: all .2s linear;
		position: relative;
	}
	.botoes{
		position: absolute;
		bottom: 15px;
		right: 100px;
		opacity: .3;
	}
	.botoes:hover{
		opacity: 1;
	}
	.menu{
		width: 50px;
		height: 100vh;
		float: left;
		background-color: whitesmoke;
		transition: all .2s linear;
	}
</style>