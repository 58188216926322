<template>
    <div>
		<h3>Criar Sub-Sala</h3>
		<b-form ref="form" @submit="salvarSubSala">
			<input type="hidden" v-model="form.id">
			<b-overlay :show="show_overlay" rounded="sm">
				<b-form-group
					label="Nome"
					label-for="nome-input">
					<b-form-input
						id="nome-input"
						v-model="form.nome"
						required>
					</b-form-input>
				</b-form-group>
				<div>
					<label class="typo__label" for="participantes_selecionados">Participantes</label>
					<b-input-group>
						<b-form-select
							v-model="participante_selecionado"
							id="participantes_selecionados"
							:options="options"
							:style="{
								borderTopRightRadius: participantes_selecionados_list.length === 0 ? '.25rem' : '0',
								borderBottomRightRadius: participantes_selecionados_list.length === 0 ? '.25rem' : '0'
							}"
						/>
						<b-button variant="outline-secondary"
							v-show="participantes_selecionados_list.length > 0"
							@click="clearAll"
							v-b-tooltip.hover.top title="Limpar Participantes selecionados"
							style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-color: #CED4DA; border-left: none"
						>
							<font-awesome-icon icon="broom" />
						</b-button>
					</b-input-group>
					<b-list-group :class="{'mb-3': true, 'mt-2': form.participantes_selecionados.length > 0}">
						<b-list-group-item
							v-for="(participante, index) in form.participantes_selecionados"
							:key="index"
							class="pt-1 pb-1 border-0 border-bottom-1"
						>
							<div class="mt-0 mb-0">
								<div
									:style="{
										backgroundColor: participante.is_online ? 'green' : 'red',
										width: '.5rem',
										height: '.5rem',
										marginRight: '.2rem',
										marginTop: '.4rem',
										borderRadius: '50%'
									}"
									class="float-left"
									v-b-tooltip.hover.left :title="participante.is_online ? 'OnLine' : 'OffLine'"
								/>&nbsp;{{ participante.nome }}
								<b-button class="float-right" variant="light"
										size="sm"
										@click="removeParticipante(participante)">
									<font-awesome-icon icon="trash" />
								</b-button>
							</div>
						</b-list-group-item>
					</b-list-group>
				</div>

				<div class="text-right">
					<b-button @click="fechar" variant="light">Cancelar</b-button>
					<b-button @submit="salvarSubSala" class="ml-2" type="submit" variant="light">Salvar</b-button>
				</div>
			</b-overlay>
		</b-form>
    </div>
</template>

<script>
export default {
    name: "SalasSubSalaForm",
	props: {
		subsala: {type: Object, default: null}
	},
    data(){
		return {
            form: {
				id: null,
                participantes_selecionados: [],
                nome: '',
                token: 'token',
                participantes: [],
                sala: this.$store.state.sala.id,
            },
			show_overlay: false,
			participante_selecionado: null,
			participantes_restantes: [].concat(this.$store.state.sala.participantes),
            sub_salas: this.$store.state.sala.subsalas,
            is_loading: false,
			is_mounted: false
		}
    },
	watch:{
		participante_selecionado(novo, velho){
			if(velho === null && novo !== null) {
				let participante = this.participantes_restantes.filter(part=>part.id === this.participante_selecionado)
				this.form.participantes_selecionados.push(participante[0])
				this.getParticipantesRestantes()
				this.resetSelectedOption()
			}
		}
	},
    computed:{
		participantes_outras_subsalas(){
			let parts = []
			let subsalas = this.$store.state.sala.subsalas
			for(let subsala in subsalas){
				for(let p in subsalas[subsala].participantes){
					parts.push(subsalas[subsala].participantes[p]['user_id'])
				}
			}
			return parts
		},
		participantes_selecionados_list(){
			let parts = []
			for(let p in this.form.participantes_selecionados) {
				parts.push(this.form.participantes_selecionados[p].id)
			}
			return parts
		},
		options(){
			let restantes = [
				{value: null, text: 'Selecione...', selected: true},
				{label: 'OnLine', options: []},
				{label: 'OffLine', options: []}
			]
			for(let part in this.participantes_restantes) {
				if (this.participantes_restantes[part].is_online) {
					restantes[1]['options'].push({
						value: this.participantes_restantes[part].id,
						html: this.participantes_restantes[part].nome,
						selected: false
					})
				} else {
					restantes[2]['options'].push({
						value: this.participantes_restantes[part].id,
						html: this.participantes_restantes[part].nome,
						selected: false
					})
				}
			}
			if(restantes[1].options.length > 0) {
				restantes[1].options.sort((a, b) => {
					return a.html.localeCompare(b.html)
				})
			}
			if(restantes[2].options.length > 0) {
				restantes[2].options.sort((a, b) => {
					return a.html.localeCompare(b.html)
				})
			}
			return restantes
		}
	},
    methods: {
		fechar(){
			this.clearAll()
			this.$parent.toggleFormulario();
		},
		resetSelectedOption(){
			this.participante_selecionado = null
			if(this.is_mounted && this.options.length > 0) {
				setTimeout(()=>document.getElementById('participantes_selecionados').options[0].selected=true, 10)
			}
		},
        clearAll(substituir=[]) {
			while(this.form.participantes_selecionados.length > 0) {
				for (let part in this.form.participantes_selecionados) {
					this.removeParticipante(this.form.participantes_selecionados[part])
				}
				if(this.form.participantes_selecionados.length === 0 && substituir.length > 0){
					for(let p in substituir) this.participante_selecionado=p
				}
			}
        },
		removeParticipante(participante){
			this.form.participantes_selecionados.splice(this.participantes_selecionados_list.indexOf(participante.id), 1)
			this.participantes_restantes.push(participante)
			this.getParticipantesRestantes()
		},
        salvarSubSala(e){
			this.show_overlay = true
            e.preventDefault()
			let params = {
				tipo_text: this.form.id ? 'atualizada' : 'criada',
				metodo: this.form.id ? 'put' : 'post',
				url: this.form.id ? `/sub_salas/${this.form.id}/` : '/sub_salas/'
			}
            this.form.participantes_selecionados.map(obj=>this.form.participantes.push(obj.id));
            this.$http({
				method: params.metodo,
				url: params.url,
				data: this.form
			}).then(response => {
                if(response.status === 200) {
					this.$toast.open({
						message: `Sub-Sala ${params.tipo_text} com sucesso!`,
						type: 'success',
						duration: 5000,
						position: 'top-right'
					});
					this.show_overlay = false
					this.$root.$emit('sala_atualizada')
					this.fechar()
				}else {
					this.$toast.open({
						message: `Ocorreu um erro e a Sub-Sala não pôde ser ${params.tipo_text}.`,
						type: 'error',
						duration: 5000,
						position: 'top-right'
					});
					this.show_overlay = false
				}
			})
        },
		getParticipantesRestantes(){
			for(let part in this.participantes_restantes){
				if(
					(
						this.participantes_selecionados_list.length > 0 &&
						this.participantes_selecionados_list.includes(this.participantes_restantes[part].id)
					) || (
						this.participantes_outras_subsalas.length > 0 &&
						this.participantes_outras_subsalas.includes(this.participantes_restantes[part].id)
					)
				) {
					this.participantes_restantes.splice(part, 1)
				}
			}
			this.form.participantes_selecionados.sort((a, b)=>{
				return a.nome.localeCompare(b.nome)
			})
			this.resetSelectedOption()
		}
    },
	created() {
		this.form.id = this.subsala.id
		this.form.nome = this.subsala.nome
		this.form.token = this.subsala.token
		this.form.participantes = this.subsala.participantes
		new Promise((resolve)=>{
			for(let p in this.subsala.participantes){
				let participante = this.$store.state.sala.participantes.filter(part=>part.id === this.subsala.participantes[p]['user_id'])
				this.form.participantes_selecionados.push(participante[0])
			}
			resolve()
		}).then(()=>{
			this.getParticipantesRestantes()
		})
	},
	mounted() {
		this.getParticipantesRestantes()
		this.is_mounted = true
	}
}
</script>

<style scoped>
</style>