import '@babel/polyfill'
import 'mutationobserver-shim'
import fullscreen from 'vue-fullscreen'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import NProgress from "vue-nprogress";
import App from './App.vue'
// import './registerServiceWorker'
import VueCookies from 'vue-cookies'
import router from './router'
import store from './store'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueToast from 'vue-toast-notification'
import Select2 from 'v-select2-component'
import VueSession from 'vue-session'
import {isMobile} from "mobile-device-detect";
import VueMeta from 'vue-meta';
import './plugins/axios'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import 'vue-toast-notification/dist/theme-default.css';
import Multiselect from 'vue-multiselect'
import VueQrcode from '@chenfengyuan/vue-qrcode';
// import VueNativeSock from 'vue-native-websocket'

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(fullscreen)
Vue.use(VueCookies)
// Vue.use(VueNativeSock, 'ws://localhost:8080/ws/connect',
// Vue.use(VueNativeSock, 'wss://defmeet.defensoria.to.def.br/ws/connect',
//     {
//         reconnection: true,
//         reconnectionAttempts: 5,
//         reconnectionDelay: 3000,
//         format: 'json'
//     }
// )
Vue.use(VueYouTubeEmbed, {global: true, componentId: "youtube-media"})
Vue.use(VueToast);
Vue.use(Select2);
Vue.use(VueSession)
Vue.use(isMobile)
Vue.use(VueMeta)
Vue.use(require('vue-moment'));
Vue.use(NProgress, {latencyThreshold: 100, router: true, http: true});
Vue.component('multiselect', Multiselect)
Vue.component(VueQrcode.name, VueQrcode)

Vue.prototype.$JitsiMeetJS = window.JitsiMeetJS

const nprogress = new NProgress()

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresLogin)){
        if(!store.getters.loggedIn){
            next({name: 'login'})
        } else {
            next()
        }
    } else {
        next()
    }
})

new Vue({
    nprogress,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

