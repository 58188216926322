import Vue from 'vue'
import Vuex from 'vuex'
import {settings} from '@/settings.js'
import {auth} from  '../plugins/axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        salaIdOnList: null,
        accessToken: null,
        refreshToken: null,
        isRefreshing: false,
        refreshingCall: null,
        APIData: '',
        sala: null,
        user: {},
        settings,
        content_active: true,
        nome_sala_index: '',
        // default_domain: 'http://localhost:8000',
        // default_ws_domain: 'ws://localhost:8000/ws',
        default_ws_domain: 'wss://defmeet.defensoria.to.def.br/ws',
        default_domain: 'https://defmeet.defensoria.to.def.br',
        versao: null,
        transition: 'slide-right',
        ignoreEvent: null
    },
    mutations: {
        ignoreEvent(state, evento){
            state.ignoreEvent = evento
        },
        setTransition(state, transition){
            state.transition = transition
        },
        setRefreshingState(state, status){
            state.isRefreshing = status
        },
        setVersao(state, versao){
            state.versao = versao
        },
        setRefreshingCall(state, val){
            state.refreshingCall = val
        },
        updateStorage(state, { access, refresh }){
            state.accessToken = access
            state.refreshToken = refresh
            window.localStorage.setItem('accessToken', access)
            window.localStorage.setItem('refreshToken', refresh)
        },
        destroyToken(state){
            state.accessToken = null
            state.refreshToken = null
            window.localStorage.removeItem("access");
            window.localStorage.removeItem("refresh");
            window.localStorage.removeItem("username")
            window.localStorage.removeItem("nome_user")
            window.localStorage.removeItem("id_user")
            window.localStorage.removeItem("email_user")
            window.localStorage.removeItem("sala")
            window.localStorage.removeItem("user")
            window.localStorage.removeItem("accessToken")
            window.localStorage.removeItem("refreshToken")
        },
        updateChatMessages(state, mensagem){
            state.sala.chat_mensagens.push(mensagem)
        },
        updateSala(state, sala){
            if(this.getters.loggedIn) {
                state.sala = sala
            }
        },
        updateSubSala(state, subsala){
            for(let i=0; i<state.sala.subsalas.length; i++){
                if(state.sala.subsalas[i]['token'] === subsala['token']){
                    state.sala.subsala.splice(i, 1)
                }
                state.sala.subsalas.push(subsala)
            }
        },
        updateSalaInfos(state, infos){
            if(this.getters.loggedIn){
                let verificaCor = (us, tp)=>{
                    let usu = state.sala[tp].find(obj=>obj.id===us.id)
                    if(!us.avatar && usu && !usu.cor || !usu){
                        us.cor = '#'+Math.floor(Math.random()*16777215).toString(16)
                    }else if(usu && usu.cor){
                        us.cor = usu.cor
                    }
                }
                // let persisteJitsiId = (us, tp)=>{
                //     let usu = state.sala[tp].find(obj=>obj.id===us.id)
                //     if(usu && ('jitsi_id' in usu)){
                //         if(!us.jitsi_id) {
                //             us.jitsi_id = usu.jitsi_id
                //         }
                //     }
                // }
                if(infos.moderadores){
                    infos.moderadores.forEach(a=>verificaCor(a, 'moderadores'))
                    // infos.apresentadores.forEach(a=>persisteJitsiId(a, 'moderadores'))
                    state.sala.moderadores = infos.moderadores
                }
                if(infos.apresentadores){
                    infos.apresentadores.forEach(a=>verificaCor(a, 'apresentadores'))
                    // infos.apresentadores.forEach(a=>persisteJitsiId(a, 'apresentadores'))
                    state.sala.apresentadores = infos.apresentadores
                }
                if(infos.participantes)
                {
                    infos.participantes.forEach(p=>verificaCor(p, 'participantes'))
                    // infos.participantes.forEach(p=>persisteJitsiId(p, 'participantes'))
                    state.sala.participantes = infos.participantes
                }
                if(infos.microfone_bloqueado) state.sala.microfone_bloqueado = infos.microfone_bloqueado
                if(infos.finalizado) state.sala.finalizado = infos.finalizado
                if(infos.subsalas_ativo) state.sala.subsalas_ativo = infos.subsalas_ativo
                if(infos.servico_jitsi) state.sala.servico_jitsi = infos.servico_jitsi
            }
        },
        setUsuarioJitsiId(state, params){
            let usu = state.sala[params['tipo']].find(obj=>obj.id===params['id'])
            usu['jitsi_id'] = params['jitsiId']
        },
        updateContentActive(state, valor){
            state.content_active = valor
        },
        setUser(state, usuario){
            state.user = usuario
            state.user['subsala'] = null
            window.localStorage.setItem("user", JSON.stringify(usuario));
        },
        updateUserInfos(state, infos){
            if(('microfone' in infos)) state.user.microfone = infos['microfone']
            if(('video' in infos)) state.user.video = infos['video']
        },
        updateSubsala(state, status){
            state.user['subsala'] = status
        }
    },
    getters: {
        loggedIn(state){
            if(!('username' in state.user)) {
                let uls = window.localStorage.getItem('user')
                if (uls && uls.length > 3) {
                    uls = JSON.parse(uls)
                    state.user = uls
                }
            }
            if(!state.accessToken){
                let atls = window.localStorage.getItem("accessToken")
                if(atls && atls.length > 3){
                    state.accessToken = atls
                }
            }
            return state.accessToken !== null && window.localStorage.getItem("accessToken") !== null && 'username' in state.user
        }
    },
    actions: {
        updateVersao(context, versao){
            return new Promise((resolve, reject)=>{
                try {
                    context.commit('setVersao', versao)
                    document.cookie = `defmeet_versao=${versao}`
                    resolve()
                }
                catch (exception){
                    console.log(exception)
                    reject(exception)
                }
            })
        },
        userLogout(context){
            context.commit('destroyToken')
            context.commit('setUser', {})
            return true
        },
        userLogin(context, tokens){
            return new Promise((resolve, reject)=>{
                try {
                    window.localStorage.setItem('accessToken', tokens['access'])
                    window.localStorage.setItem('refreshToken', tokens['refresh'])
                    context.commit(
                        'updateStorage',
                        {
                            access: tokens['access'],
                            refresh: tokens['refresh']
                        }
                    )
                    resolve()
                }
                catch (exception){
                    console.log(exception)
                    reject(exception)
                }
            })
        },
        userLogin_old(context, usercredentials){
            return new Promise((resolve, reject) => {
                auth.post('/api-token/', {
                    username: usercredentials.username,
                    password: usercredentials.password
                })
                .then(response => {
                    window.localStorage.setItem('accessToken', response['data']['access'])
                    window.localStorage.setItem('refreshToken', response['data']['refresh'])
                    context.commit(
                        'updateStorage',
                        {
                            access: response['data']['access'],
                            refresh: response['data']['refresh']
                        }
                    )
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
            })
        }
    },
    modules: {}
})
