<template>
	<div class="p-2">
		<h3 class="pb-2 border-bottom">Configurações</h3>
		<b-tabs content-class="mt-3" align="center">
			<b-tab active>
				<template #title>
					<font-awesome-icon icon="microphone-alt" /> Microfone
				</template>
				<b-list-group v-if="dispositivos.disponiveis.microfones.length > 0">
					<b-list-group-item button
						v-for="dispositivo in dispositivos.disponiveis.microfones" :key="dispositivo.deviceId"
						@click="emitir('definir_dispositivo', {
							type: 'microfone',
							deviceLabel: dispositivo.label,
							deviceId: dispositivo.deviceId
						})"
					>
						{{ dispositivo.label }} <b-badge variant="success" v-if="dispositivos.ativos.microfones_list.includes(dispositivo.deviceId)">Ativo</b-badge><br>
	<!--					<small>{{ dispositivo.deviceId }}</small>-->
					</b-list-group-item>
				</b-list-group>
				<div v-else class="sem_registros">
					<font-awesome-icon icon="microphone-alt-slash" size="3x"/>
					<br>
					Sem Microfones disponíveis!
				</div>
			</b-tab>
			<b-tab>
				<template #title>
					<font-awesome-icon icon="volume-up" /> Alto-Falante
				</template>
				<b-list-group v-if="dispositivos.disponiveis.altofalantes.length > 0">
					<b-list-group-item button
						v-for="dispositivo in dispositivos.disponiveis.altofalantes" :key="dispositivo.deviceId"
						@click="emitir('definir_dispositivo', {
							type: 'altofalante',
							deviceLabel: dispositivo.label,
							deviceId: dispositivo.deviceId
						})"
					>
						{{ dispositivo.label }} <b-badge variant="success" v-if="dispositivos.ativos.altofalantes_list.includes(dispositivo.deviceId)">Ativo</b-badge><br>
	<!--					<small>{{ dispositivo.deviceId }}</small>-->
					</b-list-group-item>
				</b-list-group>
				<div v-else class="sem_registros">
					<font-awesome-icon icon="volume-mute" size="3x"/>
					<br>
					Sem Alto-falantes disponíveis!
				</div>
			</b-tab>
			<b-tab>
				<template #title>
					<font-awesome-icon icon="video" /> Câmera
				</template>
				<b-list-group v-if="dispositivos.disponiveis.cameras.length > 0">
					<b-list-group-item button
						v-for="dispositivo in dispositivos.disponiveis.cameras" :key="dispositivo.deviceId"
						@click="emitir('definir_dispositivo', {
							type: 'camera',
							deviceLabel: dispositivo.label,
							deviceId: dispositivo.deviceId
						})"
					>
						{{ dispositivo.label }} <b-badge variant="success" v-if="dispositivos.ativos.cameras_list.includes(dispositivo.deviceId)">Ativo</b-badge><br>
	<!--					<small>{{ dispositivo.deviceId }}</small>-->
					</b-list-group-item>
				</b-list-group>
				<div v-else class="sem_registros">
					<font-awesome-icon icon="video-slash" size="3x"/>
					<br>
					Sem Câmeras disponíveis!
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
export default {
name: "SalaDispositivos",
	props:{
		dispositivos: {
			type: Object,
			required: true
		}
	},
	methods:{
		emitir(sinal, params){
			this.$root.$emit(sinal, params)
		}
	}
}
</script>

<style scoped>
.list-group-item {
	border: none !important;
}
.sem_registros {
    color: silver;
    margin: 30px auto;
    font-size: small;
    text-align: center;
}
</style>