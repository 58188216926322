<template>
	<div>
		<p class="text-center border-bottom" style="background-color: #f8f9fa;">
			<b-button-group>
				<b-button variant="light" size="sm"
					@click="confirmarSaida"
				>
					<font-awesome-icon icon="home" />
				</b-button>
				<b-button variant="light" size="sm"
					@click="$root.$emit('toggle_fullscreen')"
				>
					<font-awesome-icon icon="arrows-alt" />
				</b-button>
				<b-button
					@click="emitir('acessos')"
					title="Acessos" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="people-arrows"/>
				</b-button>
				<b-button
					@click="emitir('recursos')"
					title="Recursos" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="paperclip"/>
				</b-button>
				<b-button
					@click="emitir('configuracoes')"
					title="Configurações" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="tools"/>
				</b-button>
				<b-button
					@click="emitir('infos')"
					title="Informações da Sala" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="info"/>
				</b-button>
				<b-button
					@click="emitir('mensagens')"
					title="Mensagens Privadas" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="envelope"/>
				</b-button>
				<b-button
					@click="emitir('chat')"
					title="Chat" v-b-tooltip.hover.right
					variant="light"
				>
					<font-awesome-icon icon="comments"/>
					<span class="badge" v-show="this.chat_nao_lidas > 0">{{ this.chat_nao_lidas }}</span>
				</b-button>
			</b-button-group>
		</p>
	</div>
</template>

<script>
export default {
	name: "SalasMenuWSMobile",
	methods:{
		confirmarSaida(){
			this.$bvModal.msgBoxConfirm('Deseja realmente sair da Sala?', {
				title: 'Please Confirm',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'success',
				okTitle: 'Sim',
				cancelTitle: 'Não',
				cancelVariant: 'dark',
				footerClass: 'p-2',
				hideHeader: true,
				centered: true,
				bodyBgVariant: "dark",
				bodyTextVariant: "light",
				footerBgVariant: "dark",
				footerTextVariant: "light",
			})
			.then(value => {
				if(value) this.$router.push({name:'home'}).catch(()=>{})
			})
		}
	}
}
</script>

<style scoped>

</style>