<template>
	<div>
		<div ref="jitsiContainer" id="jitsiContainer" :style="style" />
		<b-modal id="modal-jitsicl" v-model="cl_modal" centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				title="Jitsi Command-Line"
				cancel-title="Fechar"
				@cancel="cl_modal=false"
				hide-header
		>
			<p>
				<b-form-input placeholder="ExecuteCommand"
					v-model="cl_comando"
					v-on:keyup.enter="executeCL"
				/>
			</p>
			<p v-html="cl_return" class="mono_font" />
		</b-modal>
	</div>
</template>

<script>
export default {
	props: {
		domain: {
			type: String,
			required: true
			// default: 'localhost:8444'
			// default: 'newmeet.defensoria.to.def.br'
			// default: 'meet.jit.si'
			// default: this.$store.state.sala.servico_jitsi
		},
		tipo: {
			type: String,
			default: 'apresentador'
		},
		loading_painel:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return{
			style: {
				height: this.$store.state.sala.nivel_usuario === 'moderador' ? '100%' : '100vh',
				width: '100%',
				minHeight: this.$store.state.sala.nivel_usuario === 'moderador' ? '300px' : '100vh',
				display: 'grid'
			},
			cl_modal: false,
			cl_comando: '',
			cl_return: '',
			is_mounted: false,
			gravando: this.$parent.jitsi_gravando
		}
	},
	watch:{
		gravando(){
			this.$root.$emit('jitsi_gravando', this.gravando)
		}
	},
	computed: {
		moderadores_ids() {
			let mod = []
			this.$store.state.sala.moderadores.forEach(m => mod.push(m.id))
			return mod
		},
		apresentadores_ids() {
			let apr = []
			this.$store.state.sala.apresentadores.forEach(a => apr.push(a.id))
			return apr
		},
		stream_opt(){
			if(this.$store.state.sala.criar_youtube &&
				this.$store.state.sala.youtube_streamkey &&
				this.$store.state.sala.broadcast &&
				this.$store.state.sala.nivel_usuario === 'moderador'
			){
				return {
					mode: 'stream',
					shouldShare: true,
					youtubeStreamKey: this.$store.state.sala.youtube_streamkey,
					youtubeBroadcastID: this.$store.state.sala.broadcast
				}
			}else{
				return {mode: 'file'}
			}
		}
	},
	methods: {
		redirectToSubSala(subSalaParticipantes){
			console.log("component jitsi")

			this.$http.get(`/salas/?pk=${this.$store.state.sala.id}`)
			.then(() => {
				console.log(subSalaParticipantes)
				for(var participante of subSalaParticipantes){
					console.log(participante)
				}

			})
			
		},
		getDispositivosDisponiveis(){
			this.jitsiApi.getAvailableDevices().then(devices => {
				return(devices)
			});
		},
		getDispositivosAtivos(){
			this.jitsiApi.getCurrentDevices().then(devices => {
				return(devices)
			});
		},
		iniciarGravacao(){
			console.log('iniciar gravação')
			if (this.$store.state.sala.gravar){
				this.executeCommand('startRecording', this.stream_opt);
				this.gravando = true
				if(this.$store.state.sala.broadcast) {
					setTimeout(()=>this.$root.$emit('get_live_youtube'), 10000)
					this.$root.$emit('inicia_loop_consulta_youtube')
				}
				this.$toast.open({
                    message: 'Gravação Iniciada.',
                    type: 'info',
                    duration: 5000,
                    position: 'top-right'
                });
			}else{
				this.$toast.open({
                    message: 'Não foi habilitada gravação para esta sala.',
                    type: 'warning',
                    duration: 5000,
                    position: 'top-right'
                });
			}
			this.$emit('update:loading_painel', false)
		},
		pararGravacao(){
			console.log('parar gravação')
			this.executeCommand('stopRecording', this.stream_opt.mode)
			if(this.$store.state.sala.broadcast) {
				this.$root.$emit('parar_loop_consulta_youtube')
				setTimeout(()=>this.$root.$emit('get_live_youtube'), 10000)
			}
			this.$toast.open({
				message: 'Gravação Finalizada.',
				type: 'info',
				duration: 5000,
				position: 'top-right'
			});
			this.gravando = false
			this.$emit('update:loading_painel', false)
		},
		esta_mudo(){
			return this.jitsiApi.isAudioMuted().then(muted=> {
				return muted
			})
		},
		executeCL(cmd=null){
			if(!cmd){
				cmd = eval('this.'+this.cl_comando)
				console.log(eval('this.'+this.cl_comando))
			}else{
				eval('this.'+cmd)
				console.log(eval('this.'+cmd))
			}
			if(cmd) this.cl_return = this.cl_return + '<p>' + cmd + '</p>'
			this.cl_comando = ''
		},
		loadScript(src, cb) {
			const scriptEl = document.createElement('script');
			scriptEl.src = src;
			scriptEl.async = 1;
			document.querySelector('head').appendChild(scriptEl);
			scriptEl.addEventListener('load', cb);
		},
		embedJitsiWidget() {
			let token_sala = this.$store.state.sala.token
			if(this.$store.state.sala.interprete !== null && this.$store.state.sala.nivel_usuario === 'apresentador'){
				token_sala = this.$store.state.sala.token_complementar
			}
			const options = {
				...this.options,
				roomName: token_sala,
				defaultLaunchInBrowserOnMobile: true,
				configOverwrite: {
					liveStreamingEnabled: true,
					enableWelcomePage: false,
					enableClosePage: true,
					startWithAudioMuted: true,
					defaultLaunchInBrowserOnMobile: true,
					disableDeepLinking: true,
					disableInviteFunctions: true,
					enableNoisyMicDetection : true,
					enableLipSync: false,
					disableAEC: false
				},
				interfaceConfigOverwrite: {
					DEFAULT_LOCAL_DISPLAY_NAME: 'Eu',
					MOBILE_APP_PROMO: false,
					DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
					TOOLBAR_BUTTONS: [
						'camera', 'desktop', 'sharedvideo', 'raisehand', 'videoquality', 'filmstrip',
						'stats', 'videobackgroundblur', 'download'
					]
				},
				parentNode: this.$refs.jitsiContainer,
			};
			if(this.tipo === 'moderador'){
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('microphone')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('settings')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('camera')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('mute-everyone')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('security')
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('etherpad')
				options.configOverwrite['followMe'] = true
			}else{
				options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('microphone')
				options.configOverwrite['remoteVideoMenu'] = {disableKick: true}
				options.configOverwrite['disableRemoteMute'] = true
			}
			this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options);
		},
		executeCommand(command, ...value) {
			this.jitsiApi.executeCommand(command, ...value);
		},
		addEventListener(event, fn) {
			this.jitsiApi.on(event, fn);
		},
		removeJitsiWidget() {
			if (this.jitsiApi) this.jitsiApi.dispose();
		},
		getUsuariosInfos(){
			return this.jitsiApi.getParticipantsInfo()
		},
		toggleMicrofone(){
			this.jitsiApi.executeCommand('toggleAudio');
		},
		toggleCamera(){
			this.jitsiApi.executeCommand('toggleVideo');
		},
		setVideo(jitsiId){
			// this.jitsiApi.setLargeVideoParticipant(jitsiId)
			// this.jitsiApi.pinParticipant(jitsiId)
			this.$http.post('sala/comandos/', {
				'sala': this.$store.state.sala.id,
				'tipo': 1,
				'comando': [
					`setLargeVideoParticipant`,
					`pinParticipant`
				],
				'parametros': [`'${jitsiId}'`, `'${jitsiId}'`]
			}).then(
				success=>{
					console.log(success)
					this.$toast.open({
						message: 'Vídeo do usuário fixado.',
						type: 'success',
						duration: 2500,
						position: 'top-right'
					})
				},
				erro=>{
					console.log(erro)
					this.$toast.open({
						message: 'Ocorreu um erro ao executar o comando remotamente.',
						type: 'error',
						duration: 5000,
						position: 'top-right'
					})
				}
			)
		},
		bloquearMicrofones(bloqueio){
			this.$http.post('sala/microfones/', {
				'sala': this.$store.state.sala.id,
				'microfone_bloqueado': bloqueio
			}).then(
				resp=>{
					if(resp.data['microfone_bloqueado']){
						this.$toast.open({
							message: 'Os microfones foram bloqueados.',
							type: 'success',
							duration: 5000,
							position: 'top-right'
						});
					}else{
						this.$toast.open({
							message: 'Os microfones foram desbloqueados.',
							type: 'success',
							duration: 5000,
							position: 'top-right'
						});
					}
				},
				erro=>{
					this.$toast.open({
						message: erro.text,
						type: 'error',
						duration: 5000,
						position: 'top-right'
					});
				}
			);
		},
		mudo(mudo, usuario=null){
			if(!usuario){
				this.bloquearMicrofones(mudo)
			}else{
				this.$http.post('usuario_configs/', {
					'usuario': usuario,
					'sala': this.$store.state.sala.id,
					'microfone': !mudo
				}).then(
					()=>{
						let msg = (mudo) ? 'desativado.' : 'ativado'
						this.$toast.open({
							message: 'O microfone do usuário foi '+msg,
							type: 'info',
							duration: 2500,
							position: 'top-right',
							queue: true
						});
					},
					erro=>{
						this.$toast.open({
							message: erro.text,
							type: 'error',
							duration: 5000,
							position: 'top-right'
						});
					}
				);
			}
		},
		camera(camera, usuario=null){
			this.$http.post('usuario_configs/', {
				'usuario': usuario,
				'sala': this.$store.state.sala.id,
				'camera': camera
			}).then(
				()=>{
					let msg = (camera) ? 'ativada.' : 'desativada.'
					this.$toast.open({
						message: 'A câmera do usuário foi '+msg,
						type: 'info',
						duration: 2500,
						position: 'top-right',
						queue: true
					});
				},
				erro=>{
					this.$toast.open({
						message: erro.text,
						type: 'error',
						duration: 5000,
						position: 'top-right'
					});
				}
			);
		},
		setDispositivo(params){
			let tipo = params.type === 'altofalante' ? 'output' : 'input'
			this.jitsiApi.isDeviceChangeAvailable(tipo).then(isDeviceChangeAvailable => {
				if(isDeviceChangeAvailable) {
					if(params.type === 'microfone'){
						this.jitsiApi.setAudioInputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}else if(params.type === 'altofalante'){
						this.jitsiApi.setAudioOutputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}else if(params.type === 'camera'){
						this.jitsiApi.setVideoInputDevice(params.deviceLabel, params.deviceId).then(() => {
							this.$root.$emit('atualizar_dispositivos')
						})
					}
				}else{
					this.$toast.open({
						message: 'Não é possível definir o dispositivo ativo!',
						type: 'error',
						duration: 5000,
						position: 'top-right'
					})
				}
			});
		}
	},
	mounted() {
		this.$root.$on('redirectToSubSala',  params => this.redirectToSubSala(params.subSalaParticipantes));
		// this.$root.$on('usuario_mudo', params=>this.mudo(params.mudo, params.usuario));
		// this.$root.$on('usuario_camera', params=>this.camera(params.video, params.usuario));
		this.$root.$on('definir_dispositivo', params=>this.setDispositivo(params));
		this.loadScript(`https://${this.domain}/external_api.js`, () => {
			if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
			this.embedJitsiWidget();
			this.executeCommand('displayName', this.$store.state.user.nome)
			this.executeCommand('avatarUrl', this.$store.state.user.avatar)
			this.executeCommand('email', this.$store.state.user.email)
			this.executeCommand('subject', ' ')
			// this.addEventListener('participantJoined', resp=>{console.log(resp)})
			if(this.$store.state.user.microfone) this.toggleMicrofone()
			this.is_mounted = true
			this.$root.$emit('jitsi_mounted')
			// window.scrollTo(0, document.body.scrollHeight);

			this.addEventListener('cameraError', err=>{
				console.log(err)
				this.$toast.open({
					message: 'Não é possível acessar sua câmera!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
			this.addEventListener('micError', err=>{
				console.log(err)
				this.$toast.open({
					message: 'Não é possível acessar seu microfone!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
			this.jitsiApi.addListener('audioMuteStatusChanged', evnt=>{
				this.$root.$emit('mudanca', {
					action: 'mute_usuario',
					type: 'audio-mute-status-changed',
					usuario: this.$store.state.user.id,
					muted: evnt.muted
				})
			})
		});
	},
	beforeDestroy() {
		this.removeJitsiWidget();
		this.jitsiApi.removeEventListeners([ 'cameraError', 'micError' ]);
		// this.$root.$off('usuario_mudo');
		// this.$root.$off('usuario_camera');
		this.$root.$off('definir_dispositivo');
	},
};
</script>

<style scoped>
	#jitsiContainer>iframe{
		display: block !important;
	}
	.mono_font{
		font-family: sans-serif, serif, fantasy, cursive, monospace
	}
</style>
