var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Criar Sub-Sala")]),_c('b-form',{ref:"form",on:{"submit":_vm.salvarSubSala}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id),expression:"form.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "id", $event.target.value)}}}),_c('b-overlay',{attrs:{"show":_vm.show_overlay,"rounded":"sm"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"nome-input"}},[_c('b-form-input',{attrs:{"id":"nome-input","required":""},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}})],1),_c('div',[_c('label',{staticClass:"typo__label",attrs:{"for":"participantes_selecionados"}},[_vm._v("Participantes")]),_c('b-input-group',[_c('b-form-select',{style:({
								borderTopRightRadius: _vm.participantes_selecionados_list.length === 0 ? '.25rem' : '0',
								borderBottomRightRadius: _vm.participantes_selecionados_list.length === 0 ? '.25rem' : '0'
							}),attrs:{"id":"participantes_selecionados","options":_vm.options},model:{value:(_vm.participante_selecionado),callback:function ($$v) {_vm.participante_selecionado=$$v},expression:"participante_selecionado"}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.participantes_selecionados_list.length > 0),expression:"participantes_selecionados_list.length > 0"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticStyle:{"border-top-left-radius":"0","border-bottom-left-radius":"0","border-color":"#CED4DA","border-left":"none"},attrs:{"variant":"outline-secondary","title":"Limpar Participantes selecionados"},on:{"click":_vm.clearAll}},[_c('font-awesome-icon',{attrs:{"icon":"broom"}})],1)],1),_c('b-list-group',{class:{'mb-3': true, 'mt-2': _vm.form.participantes_selecionados.length > 0}},_vm._l((_vm.form.participantes_selecionados),function(participante,index){return _c('b-list-group-item',{key:index,staticClass:"pt-1 pb-1 border-0 border-bottom-1"},[_c('div',{staticClass:"mt-0 mb-0"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"float-left",style:({
										backgroundColor: participante.is_online ? 'green' : 'red',
										width: '.5rem',
										height: '.5rem',
										marginRight: '.2rem',
										marginTop: '.4rem',
										borderRadius: '50%'
									}),attrs:{"title":participante.is_online ? 'OnLine' : 'OffLine'}}),_vm._v(" "+_vm._s(participante.nome)+" "),_c('b-button',{staticClass:"float-right",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.removeParticipante(participante)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1)])}),1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.fechar}},[_vm._v("Cancelar")]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"submit","variant":"light"},on:{"submit":_vm.salvarSubSala}},[_vm._v("Salvar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }