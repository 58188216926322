<template>
	<div>
		<div v-bind:class="{
				avatar: true,
				online: usuario.is_online,
				offline: !usuario.is_online
			}"
			:style="avatar"
		>
			<span v-if="!usuario.avatar" v-html="letras" />
			<div
				:style="usuario_status"
				v-b-tooltip.hover.right
				:title="usuario.is_online ? 'On-Line' : 'Off-Line'"
			/>
		</div>
		<div class="usuario_detalhes pl-2">
			<p class="usuario_linha">{{usuario.nome}}</p>
			<p class="usuario_linha text-justify">
				<b-button-group>
					<b-button size="sm"
						:variant="mudo ? 'danger' : 'success'"
						v-b-tooltip.hover.top
						:title="mudo ? 'Ativar Microfone' : 'Desativar Microfone'"
						:disabled="!usuario.is_online"
						@click="microfone()"
					>
						<font-awesome-icon :icon="mudo ? 'microphone-slash' : 'microphone'" />
					</b-button>
					<b-button size="sm"
						:variant="video ? 'success' : 'danger'"
						v-b-tooltip.hover.top
						:title="video ? 'Ativar Câmera' : 'Desativar Câmera'"
						:disabled="!usuario.is_online"
						@click="camera()"
					>
						<font-awesome-icon :icon="video ? 'video' : 'video-slash'" />
					</b-button>
					<b-button variant="dark" size="sm"
						:disabled="!usuario.is_online || typeof usuario.jitsi_id === 'undefined'"
						v-b-tooltip.hover.top
						title="Atribuir Foco do Vídeo"
						@mouseover="b_view_hover=!b_view_hover"
						@mouseleave="b_view_hover=!b_view_hover"
						@click="setVideo(usuario.jitsi_id)"
					>
						<font-awesome-icon icon="eye" />
					</b-button>
<!--					<b-button variant="dark" size="sm"-->
<!--						:disabled="!usuario.is_online"-->
<!--						v-b-tooltip.hover.top-->
<!--						title="Banir usuário da Sala"-->
<!--						@mouseover="b_ban_hover=!b_ban_hover"-->
<!--						@mouseleave="b_ban_hover=!b_ban_hover"-->
<!--					>-->
<!--						<font-awesome-icon icon="ban" style="color: orangered" />-->
<!--					</b-button>-->
				</b-button-group>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {usuario: Object, tipo: String},
	data(){
		return {
			b_mic_hover: false,
			b_view_hover: false,
			b_ban_hover: false,
		}
	},
	computed:{
		mudo(){
			return !this.usuario.microfone
		},
		video(){
			return this.usuario.video
		},
		avatar(){
			if(this.usuario.avatar) {
				return {
					backgroundImage: 'url(' + this.usuario.avatar + ')',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center'
				}
			}else{
				return {
					backgroundColor: this.usuario.cor,
					paddingTop: '5px'
				}
			}
		},
		usuario_status(){
			return {
				position: 'absolute',
				right: '1px',
				bottom: '1px',
				width: '15px',
				height: '15px',
				borderRadius: '8px',
				backgroundColor: (this.usuario.is_online) ? 'green' : 'red'
			}
		},
		letras(){
			if(!this.usuario.avatar){
				let nome =  this.usuario.nome.split(' ')
				return nome[0][0] + (nome.length > 0 ? nome[1][0] : '')
			}
			return ''
		}
	},
	methods:{
		setVideo(usuario_id){
			this.$root.$emit('set_video', usuario_id)
		},
		over_button(btn){
			this[btn]=true
		},
		leave_button(btn){
			this[btn]=false
		},
		microfone(){
			this.$root.$emit('usuario_mudo', {mudo: !this.mudo, usuario: this.usuario.id})
			this.usuario.microfone = !this.mudo
		},
		camera(){
			this.$root.$emit('usuario_camera', {video: !this.video, usuario: this.usuario.id})
			this.usuario.video = !this.video
		}
	}
}
</script>

<style scoped>
	.btn-group>.btn{
		min-width: 36px;
		text-align: center;
	}
	.avatar{
		width: 50px;
		height: 50px;
		border-radius: 25px;
		float: left;
		font-size: 26px;
		font-weight: lighter;
		margin-top: 2px;
		text-align: center !important;
		color: white;
		position: relative;
	}
	.avatar.online{
	}
	.avatar.offline{
		opacity: .5;
	}
	.usuario_avatar{
		display: inline-flex;
		color: whitesmoke;
	}
	.usuario_detalhes{
		color: whitesmoke;
		display: table;
	}
	.usuario_action_button{
		cursor: pointer;
		width: 20px;
		height: 20px;
		padding: 2px 3px;
		margin:0 2px;
	}
	.usuario_action_button:hover{
		color: whitesmoke;
		background-color: rgba(0, 0, 0, .3);
	}
	.usuario_linha{
		display: table-row;
	}
</style>