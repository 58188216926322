<template>
	<div class="p-2">
		<h3 class="pb-2 border-bottom">Acessos</h3>
		<b-table striped hover :items="acessos_comp" :fields="fields"></b-table>
	</div>
</template>

<script>
export default {
	name: "Acessos",
	props: {
		acessos: {type: Array}
	},
	data(){
		return {
			fields: [
				{
					key: 'tipo_display',
					label: 'Tipo',
					sortable: true
				},
				{
					key: 'horario',
					label: 'Horário',
					sortable: true
				},
				{
					key: 'nome',
					label: 'Nome',
					sortable: true
				},

			]
		}
	},
	computed:{
		acessos_comp(){
			let zeroPadding = num=>num<10?'0'+num:num
			let acessos = []
			this.acessos.forEach(a => {
				let dt = new Date(a.horario)
				let hora = zeroPadding(dt.getHours())
				let	minuto = zeroPadding(dt.getMinutes())
				let segundos = zeroPadding(dt.getSeconds())
				acessos.push({
					id: a.id,
					tipo_display: a.tipo_display,
					horario: `${hora}:${minuto}:${segundos}`,
					nome: a.nome
				})
			})
			return acessos
		}
	}
}
</script>

<style scoped>

</style>