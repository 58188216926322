<template>
	<div class="p-2 position-relative vh-100">
		<h3 class="pb-2 border-bottom">Gravação e Transmissão</h3>
		<div id="conteudo">
			<b-overlay :show="loading" rounded="sm">
				<b-card v-if="$store.state.sala.broadcast"
					:img-src="$store.state.sala.youtube.broadcast.snippet.thumbnails.medium.url"
					img-top
					style="width: 100%; position: relative"
					class="mt-2 mb-2"
				>
					<div class="position-absolute pl-2 pt-1"
						style="top: 0; left: 0; right: 0; font-size: 1.5rem; background-color: rgba(255, 255, 255, .7); height: 3rem;">
						{{ $store.state.sala.youtube.broadcast.snippet.title }}
					</div>
					<div class="position-absolute pl-2 pb-2 text-justify"
						style="top: 3rem; left: 0;  right: 0; font-size: .8rem; background-color: rgba(255, 255, 255, .7); text-overflow: ellipsis; max-height: 10rem;"
					>
						{{ $store.state.sala.youtube.broadcast.snippet.description }}
					</div>
					<div class="position-absolute" style="top:6px; right:6px">
						<b-button pill variant="light" size="sm"
							v-b-popover.hover.top="tipo_gravacao.descricao" :title="'Gravação '+tipo_gravacao.nome"
						>
							<font-awesome-icon icon="question-circle" />
						</b-button>
					</div>
					<b-card-text>
						<b-row class="mb-1">
							<b-col class="text-center">
								<b>Visibilidade: </b>{{ visibilidade }}
							</b-col>
						</b-row>
						<b-row class="mt-1 mb-1">
							<b-col class="text-center"><b>Inicio:</b> {{ new Date($store.state.sala.youtube.broadcast.snippet.scheduledStartTime).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }}</b-col>
							<b-col class="text-center"><b>Fim:</b>&nbsp;
								<span v-if="$store.state.sala.youtube.broadcast.snippet.scheduledEndTime">{{ new Date($store.state.sala.youtube.broadcast.snippet.scheduledEndTime).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }}</span>
								<span v-else>Não Definido</span>
							</b-col>
						</b-row>
						<b-row class="text-center mt-1 mb-1">
							<b-col cols="8">
								<input type="hidden" id="url_youtube_field" :value="url_youtube">
								<b-button-group>
									<a :href="url_youtube"
										target="_blank"
										class="btn btn-sm btn-light"
									>
										{{ url_youtube }}
									</a>
									<b-button variant="light" size="sm" @click="copiarLink('url_youtube_field')" title="Copiar URL">
										<font-awesome-icon icon="copy" />
									</b-button>
									<b-button id="btn_qr_url_youtube" variant="light" size="sm">
										<font-awesome-icon icon="qrcode" />
									</b-button>
									<b-popover target="btn_qr_url_youtube" triggers="hover">
										<qrcode :value="url_youtube" :options="{ width: 100 }" />
									</b-popover>
								</b-button-group>
							</b-col>
							<b-col>
								<b-button size="sm" variant="danger" :href="url_studio" target="_blank"
									v-b-tooltip.hover.top title="Ir para o Youtube Studio"
								>
									<svg style="height: 12px; margin-top: -2px" version="1.1" viewBox="0 0 156 110" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
										<metadata>
											<rdf:RDF><cc:Work rdf:about=""><dc:format>image/svg+xml</dc:format>
											<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title/></cc:Work></rdf:RDF>
										</metadata>
										<path d="m77.922 0.40039s-48.701 0.10039-60.801 3.4004a19.6 19.6 0 0 0-13.801 13.799c-3.66 21.5-5.0784 54.26 0.10156 74.9a19.6 19.6 0 0 0 13.799 13.801c12.1 3.3 60.801 3.2988 60.801 3.2988s48.699 0.00117 60.799-3.2988a19.6 19.6 0 0 0 13.801-13.801c3.86-21.53 5.0504-54.27-0.09961-75a19.6 19.6 0 0 0-13.801-13.801c-12.1-3.3-60.799-3.2988-60.799-3.2988zm-20.121 31.199 40.398 23.4-40.398 23.4v-46.801z" fill="#FFF"/>
									</svg>
									Studio
								</b-button>
							</b-col>
						</b-row>
						<b-row class="mt-1 mb-1">
							<b-col class="text-center">
								<input type="hidden" id="studio_youtube_field" readonly :value="$store.state.sala.youtube_streamkey">
								<b>Chave:</b>
								<b-button-group>
									<b-button variant="light" size="sm" @click="copiarLink('studio_youtube_field')">
										{{ $store.state.sala.youtube_streamkey }}
									</b-button>
									<b-button variant="light" size="sm" @click="copiarLink('studio_youtube_field')" title="Copiar Chave">
										<font-awesome-icon icon="copy" />
									</b-button>
								</b-button-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-center">
								<b>Vínculo: </b>
								<b-button-group>
									<b-button variant="info" size="sm"
										v-b-tooltip.hover.bottom title="Edita as informações no Youtube"
									>
										<font-awesome-icon icon="edit" /> Editar
									</b-button>
									<b-button variant="info" size="sm"
										v-b-tooltip.hover.bottom title="Cria uma nova transmissão no Youtube"
									>
										<font-awesome-icon icon="asterisk" /> Criar
									</b-button>
									<b-button variant="info" size="sm"
										v-b-tooltip.hover.bottom title="Importa uma transmissão do Youtube já existente"
									>
										<font-awesome-icon icon="download" /> Importar
									</b-button>
									<b-button variant="info" size="sm"
										v-b-tooltip.hover.bottom title="Remove vínculo com o Youtube"
									>
										<font-awesome-icon icon="trash" /> Remover
									</b-button>
								</b-button-group>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>

				<b-row>
					<b-col>
						<b-list-group horizontal class="text-center">
							<b-list-group-item style="width: 33%"
								:variant="stream_status.variant"
								v-b-tooltip.hover.bottom :title="stream_status.desc"
							>
								<b>Streaming</b><br>
								{{ stream_status.title }}
							</b-list-group-item>
							<b-list-group-item style="width: 34%"
								:variant="broadcast_status.variant"
								v-b-tooltip.hover.bottom :title="broadcast_status.desc"
							>
								<b>Broadcast</b><br>
								{{ broadcast_status.title }}
							</b-list-group-item>
							<b-list-group-item style="width: 33%"
								:variant="recording_status.variant"
								v-b-tooltip.hover.bottom :title="recording_status.desc"
							>
								<b>Conexão</b><br>
								{{ recording_status.title }}
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col
						v-html="this.youtube.broadcast.contentDetails.monitorStream.embedHtml">
<!--						<youtube-media-->
<!--							v-show="['Live', 'Testando', 'Pronto'].includes(broadcast_status.title)"-->
<!--							ref="monitor"-->
<!--							player-width="100%"-->
<!--							player-height="360px"-->
<!--							:mute="true"-->
<!--							:player-vars="{-->
<!--								autoplay: 1,-->
<!--								// controls: 0,-->
<!--								livemonitor: 1-->
<!--							}"-->
<!--							:video-id="record_options.youtubeBroadcastID"-->
<!--							@ready="monitorReady"-->
<!--							@ended="monitorEnded"-->
<!--							@playing="monitorPlaying"-->
<!--							@paused="monitorPaused"-->
<!--							@buffering="monitorBuffering"-->
<!--							@qued="monitorQued"-->
<!--							@error="monitorError"-->
<!--							:key="monitor_key"-->
<!--						/>-->
<!--						<p class="mt-3 text-center" v-show="desconectado">-->
<!--							<img-->
<!--								src="@/assets/desconectado.svg"-->
<!--								width="200px"-->
<!--							/><br>-->
<!--							<small>O monitor da transmissão será exibido quando houver conexão.</small>-->
<!--						</p>-->
					</b-col>
				</b-row>
			</b-overlay>
			<div id="botoes" class="border-top pt-2 pb-2">
				<b-col class="text-center">
					<b-button-group>
						<b-button size="lg" variant="danger"
							@click="reloadMonitor">
							<font-awesome-icon icon="refresh" />
						</b-button>
						<b-button size="lg" variant="danger"
							v-if="stream_status.title !== 'Ativo' && !gravando"
							@click="iniciarGravacao">
							<font-awesome-icon icon="play-circle" />
							Gravar
						</b-button>
						<b-button size="lg" variant="dark"
							v-if="this.youtube && stream_status.title === 'Ativo' || gravando"
							@click="pararGravacao">
							<font-awesome-icon icon="stop" />
							Parar Gravação
						</b-button>
						<b-button size="lg" variant="danger"
							v-if="stream_status.title === 'Ativo' && broadcast_status.title !== 'Live' || !transmitindo"
						>
							<font-awesome-icon icon="play-circle" />
							Transmitir
						</b-button>
					</b-button-group>
				</b-col>
			</div>
		</div>
	</div>
</template>

<script>
import {getIdFromURL} from 'vue-youtube-embed'

export default {
	name: "Streaming",
	data(){
		return{
			youtube: {},
			loading: true,
			desconectado: false,
			gravando: false,
			transmitindo: false,
			interval: null,
			check_recording: null,
			waiting_delay: true,
			monitor_key: Math.random()
		}
	},
	watch:{
		youtube(){
			if(typeof this.youtube.broadcast === 'string'){
				this.youtube.broadcast = JSON.parse(this.youtube.broadcast)
				this.youtube.streaming = JSON.parse(this.youtube.streaming)
				// this.youtube.chat = JSON.parse(this.youtube.chat)
			}
			if(this.youtube.broadcast && this.broadcast_status.title === 'Pronto'){
				this.testarTransmissao()
			}
		}
	},
	computed:{
		tipo_gravacao(){
			return this.$store.state.sala.broadcast ? {
				nome: 'Streaming Youtube',
				descricao: 'A gravação é transmitida para o canal do Youtube. Para torná-la local, remova o vínculo com o Youtube.'
			} : {
				nome: 'Local',
				descricao: 'A gravação é armazenada nos servidores da Defensoria e pode ser disponibilizada conforme necessidade. Para torná-la um Streaming, vincule ou crie um Streaming do Youtube.'
			}
		},
		url_youtube(){
			return 'https://youtu.be/'+this.$store.state.sala.broadcast
		},
		url_studio(){
			return 'https://studio.youtube.com/video/'+this.$store.state.sala.broadcast+'/livestreaming'
		},
		url_embed_monitor(){
			let url = this.youtube.broadcast.contentDetails.monitorStream.embedHtml.split('src="')[1]
			url = url.split('" ')[0]
			return url
		},
		visibilidade(){
			let privacy = this.$store.state.sala.youtube.broadcast.status.privacyStatus
			return privacy === 'unlisted' ? 'Não Listado' : privacy === 'public' ? 'Público' : 'Privado'
		},
		stream_status(){
			let padrao = {variant: 'dark', title: 'Desconhecido', desc: 'O YouTube não retornou dados a respeito.'}
			if(this.loading === true) return padrao
			if('status' in this.youtube.streaming) {
				if (this.youtube.streaming.status.streamStatus === 'active') {
					return {
						variant: 'success',
						title: 'Ativo',
						desc: 'O YouTube está recebendo dados.'
					}
				} else if (this.youtube.streaming.status.streamStatus === 'created') {
					return {
						variant: 'primary',
						title: 'Criado',
						desc: 'Criado, mas possui configurações inválidas.'
					}
				} else if (this.youtube.streaming.status.streamStatus === 'error') {
					return {
						variant: 'danger',
						title: 'Erro',
						desc: 'Existe uma condição de erro no stream.'
					}
				} else if (this.youtube.streaming.status.streamStatus === 'inactive') {
					return {
						variant: 'warning',
						title: 'Inativo',
						desc: 'O YouTube não está recebendo dados.'
					}
				} else if (this.youtube.streaming.status.streamStatus === 'ready') {
					return {
						variant: 'info',
						title: 'Pronto',
						desc: 'Configurações válidas. Pronto para iniciar.'
					}
				}
			}
			return padrao
		},
		broadcast_status(){
			let padrao = {variant: 'dark', title: 'Desconhecido', desc: 'O YouTube não retornou dados a respeito.'}
			if(this.youtube.broadcast.length === 0 || this.loading){
				return padrao
			}
			if(this.youtube.broadcast.status.lifeCycleStatus === 'complete'){
				return {
					variant: 'primary',
					title: 'Concluído',
					desc: 'A transmissão foi finalizada.'
				}
			}else if(this.youtube.broadcast.status.lifeCycleStatus === 'created'){
				return {
					variant: 'warning',
					title: 'Criado',
					desc: 'Configurações incompletas. Não está pronta para iniciar ou testar a transmissão, mas foi criada e é válida.'
				}
			}else if(this.youtube.broadcast.status.lifeCycleStatus === 'live'){
				return {
					variant: 'success',
					title: 'Live',
					desc: 'A transmissão está ativa.'
				}
			}else if(this.youtube.broadcast.status.lifeCycleStatus === 'ready'){
				return {
					variant: 'info',
					title: 'Pronto',
					desc: 'Configurações estão concluídas e pode iniciar ou testar a transmissão.'
				}
			}else if(this.youtube.broadcast.status.lifeCycleStatus === 'revoked'){
				return {
					variant: 'danger',
					title: 'Removida',
					desc: 'Foi removido pelo administrador.'
				}
			}else if(this.youtube.broadcast.status.lifeCycleStatus === 'testing'){
				return {
					variant: 'light',
					title: 'Testando',
					desc: 'Transmissão em teste. Visível apenas para o transmissor.'
				}
			}else{
				return padrao
			}
		},
		recording_status(){
			let padrao = {variant: 'dark', title: 'Desconhecido', desc: 'O YouTube não retornou dados a respeito.'}
			if(this.youtube.broadcast.length === 0 || this.loading){
				return padrao
			}
			if(this.youtube.broadcast.status.recordingStatus === 'notRecording' && this.youtube.streaming.status.streamStatus !== 'active'){
				return {
					variant: 'danger',
					title: 'Off-Line',
					desc: 'O Meet não está conectado ao YouTube.'
				}
			}else if(this.youtube.broadcast.status.recordingStatus === 'recorded'){
				return {
					variant: 'primary',
					title: 'Gravado',
					desc: 'Já existe vídeo gravado.'
				}
			}else if(this.youtube.broadcast.status.recordingStatus === 'recording' || this.youtube.streaming.status.streamStatus === 'active'){
				return {
					variant: 'success',
					title: 'On-Line',
					desc: 'O Meet está conectado ao YouTube.'
				}
			}else{
				return padrao
			}
		},
		record_options(){
			if(this.$store.state.sala.youtube &&
				this.$store.state.sala.youtube_streamkey &&
				this.$store.state.sala.broadcast
			){
				return {
					mode: 'stream',
					shouldShare: true,
					youtubeStreamKey: this.$store.state.sala.youtube_streamkey,
					youtubeBroadcastID: this.$store.state.sala.broadcast
				}
			}else{
				return {mode: 'file'}
			}
		}
	},
	methods:{
		getIdFromURL,
		reloadMonitor(){
			let original_url = this.youtube.broadcast.contentDetails.monitorStream.embedHtml
			this.youtube.broadcast.contentDetails.monitorStream.embedHtml = ' '
			this.youtube.broadcast.contentDetails.monitorStream.embedHtml = original_url
		},
		copiarLink(campo_id){
			let confs = {
				duration: 5000,
				position: 'top-right',
			}

			let copiar = document.querySelector('#'+campo_id)
			copiar.setAttribute('type', 'text')
			copiar.select()

			try {
				let successful = document.execCommand('copy');
				if(successful) {
					confs.type = 'info'
					confs.message = 'Copiado com sucesso!'
				}else{
					confs.type = 'error'
					confs.message = 'Erro ao copiar!'
				}
			} catch (err) {
				confs.type = 'error'
				confs.message = 'Erro ao copiar!'
			}
			this.$toast.open(confs);

			copiar.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()
		},
		updateStats(data){
			try{
				if (this.youtube.streaming && data.streaming && (this.youtube.streaming.status.streamStatus !== data.streaming.status.streamStatus)) {
					this.reloadMonitor()
				}
			}catch (e){
				console.log(e)
			}
			this.youtube = data
			this.loading = false
		},
		monitorReady(){
			console.log('Pronto')
			this.$refs.monitor.player.playVideo()
		},
		monitorEnded(){
			this.monitor_key = Math.random();
			console.log('Finalizado')
		},
		monitorPaused(){
			this.monitor_key = Math.random();
			this.$refs.monitor.player.playVideo()
			console.log('Pausado')
		},
		monitorBuffering(){
			this.$refs.monitor.player.playVideo()
		},
		monitorQued(){
			console.log('Na Fila')
			this.$refs.monitor.player.playVideo()
		},
		monitorPlaying(){
			this.desconectado = false
		},
		monitorError(){
			// this.monitor_key = Math.random();
			console.log('Erro Monitor')
			this.desconectado = true
			this.$refs.monitor.player.playVideo()
		},
		iniciarGravacao() {
			return new Promise((resolve, reject)=> {
				try {
					window.jitsiRef.jitsiApi.executeCommand('startRecording', this.record_options)
					this.gravando = true
					console.log('--- INICIOU GRAVAÇÃO')
                    resolve()
                }
                catch (exception){
					this.gravando = false
                    console.log(exception)
                    reject(exception)
                }
			})
		},
		pararGravacao() {
			this.waiting_delay = true
			setTimeout(()=>{this.waiting_delay=false}, 12000)
			window.jitsiRef.jitsiApi.executeCommand('stopRecording', this.record_options.mode)
			this.gravando = false
		},
		testarTransmissao(){
			this.transmitindo = true
			console.log(this.youtube.broadcast.status.lifeCycleStatus)
			if(['ready', 'created'].includes(this.youtube.broadcast.status.lifeCycleStatus)){
				this.$http.post('/youtube_live/', {
					slug: this.$store.state.sala.slug,
					tipo: 'testar'
				})
				.then(resp=>{
					console.log(resp)
					if(resp.status !== 200){
						setTimeout(()=>{this.testarTransmissao()}, 5000)
					}
				}
				//, erro=>{
				// 	console.log(erro)
				// 	// setTimeout(()=>{this.testarTransmissao()}, 5000)
				// }
				)
			}
		}
	},
	created() {
		this.youtube = this.$store.state.sala.youtube
		this.youtube.streaming = this.youtube.stream
	},
	mounted() {
		this.$root.$on('youtube_stats', data=>this.updateStats(data))
		this.$root.$on('recording_change', evnt=>{
			console.log(evnt)
		})
		this.$root.$on('jitsi_mounted', ()=>{
			if(this.youtube){
				this.iniciarGravacao()
			}
		})
		// this.check_recording = setInterval(()=>{
		// 	if(window.jitsiRef.is_mounted && this.youtube.streaming.status.streamStatus !== 'active'){
		// 		this.iniciarGravacao().then(()=>{
		// 			this.gravando = true
		// 			if(this.$store.state.sala.broadcast) {
		// 				this.testarTransmissao()
		// 			}
		// 		})
		// 	}
		// }, 5000)
	},
	beforeDestroy() {
		// clearInterval(this.interval)
		this.$root.$off('youtube_stats')
	}
}
</script>

<style scoped>
	.btn-sm{
		font-size: .8rem;
	}
	.card-body{
		padding: .75rem;
	}
	#conteudo{
		overflow-y: auto;
		overflow-x: hidden;
		font-size: .8rem;
	}
	#botoes{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: white;
	}

	.slide-fade-enter-active {
		transition: all .3s ease;
	}

	.slide-fade-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */
	{
		transform: translateY(10px);
		opacity: 0;
		overflow-x: hidden !important;
	}
</style>
