<template class="fundo_escuro">
    <fullscreen @change="fullscreenChange" ref="fullscreen">
        <transition name="slide">
            <div id="side_modal" v-if="show_modal">
                <b-link @click="show_modal = !show_modal" variant="link">
                    <font-awesome-icon class="float-left mr-2 ml-2 mt-2" icon="times-circle" size="lg"
                                       style="color: #0f0f16"/>
                </b-link>
                <component v-bind:is="component_modal"/>
            </div>
        </transition>
        <div id="container">
            <b-modal id="modal-confirm" centered
                     body-bg-variant="dark"
                     body-text-variant="light"
                     footer-bg-variant="dark"
                     footer-text-variant="light"
                     title="Confirmação" ok-title="Sim" cancel-title="Não" @ok="irHome" hide-header>
                <p class="my-4">Deseja realmente sair da sala?</p>
            </b-modal>
			<b-modal id="modal-configuracoes" centered ok-title="Fechar" ok-only size="lg">
				<template #modal-title>
					<font-awesome-icon icon="tools" /> Configurações
				</template>
				<SalaDispositivos :dispositivos="dispositivos_obj" />
            </b-modal>
            <div id="ballon_avatar" class="ballon" v-if="ballon_avatar">
                <b-row>
                    <b-col cols="3" class="pt-3">
                        <img src="@/assets/no_avatar.png" class="rounded-circle" height="100px">
                        <input type="file" ref="imagem_avatar" id="imagem_avatar" style="display: none"
						@change="uploadAvatar($event)">
                    </b-col>
                    <b-col>
                        <h3 class="text-center">Quem é você???</h3>
                        <p class="text-justify mb-0">Atualize seu avatar com sua foto e facilite sua identificação pelos demais integrantes da
                            Sala!
                        </p>
                        <p class="text-right">
                            <b-button size="sm" variant="none"
                            @click="ballon_avatar = !ballon_avatar">
                                Agora Não</b-button> &nbsp;
                            <b-button size="sm" variant="primary" @click="$refs.imagem_avatar.click()">Enviar</b-button>
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div id="ballon_countdown" class="ballon" v-if="countdown_ativo">
                <b-row>
                    <b-col>
                        <h3 v-html="countdown_tempo"></h3>
                        <p>Esta sala está sem moderador e o evento precisará ser finalizado caso o mesmo não retorne.</p>
                    </b-col>
                </b-row>
            </div>
			<div id="ballon_interprete" @click="abreSalaInterprete" class="ballon"
				v-if="$store.state.sala.nivel_usuario === 'interprete'"
				v-b-tooltip.hover.bottom title="Abrir janela para Linguagem de Sinais"
			>
				<font-awesome-icon icon="american-sign-language-interpreting" size="2x" />
			</div>
            <transition name="slide_chat">
            <DraggableDiv id="chat" v-show="this.chat_visible" :options="chat_options" :close_event="toggleChat">
                <template slot="header">Chat</template>
                <template slot="main" >
                    <Chat ref="chat_component" :moderador_is_on="moderador_is_on" :chat_visible="chat_visible" />
                </template>
            </DraggableDiv>
            </transition>
            <div id="mensagens" v-if="this.mensagens_visible"></div>
<!--            <div id="toolbar" v-if="moderador_is_on && em_atividade">-->
			<div id="toolbar" v-show="false">
				<b-button-group>
					<b-button
						title="Mosaico de Usuários" v-b-tooltip.hover.top
						@click="jitsiCmd('toggleTileView')"
					>
						<font-awesome-icon icon="th-large" />
					</b-button>
					<b-button
						:title="this.mudo ? 'Ativar Microfone' : 'Desativar Microfone'"
						v-b-tooltip.hover.top
						@click="toggleMicrofone()"
						:disabled="this.microfone_bloqueado"
						:variant="this.mudo ? 'danger' : 'success'"
					>
						<font-awesome-icon
							:icon="this.mudo ? 'microphone-slash' : 'microphone'" />
					</b-button>
					<b-button
						:title="this.camera_status ? 'Desativar Câmera' : 'Ativar Câmera'"
						v-b-tooltip.hover.top
						@click="toggleCamera()"
						:variant="this.camera_status ? 'success' : 'danger'"
					>
						<font-awesome-icon
							:icon="this.mudo ? 'video' : 'video-slash'" />
					</b-button>
				</b-button-group>
			</div>
			<SalasMenu :chat_visible="chat_visible" :chat_nao_lidas="chat_nao_lidas" />
            <div id="col_video" class="m-0 p-0">
                <SalaTitulo :moderadores="moderadores_nomes"
							:apresentadores="apresentadores_nomes"
                            :participantes="participantes_nomes"
							:my_jitsi_id="my_jitsi_id"
							tipo="A"
				/>
                <div id="carregando" class="text-center justify-content-between">
                    <div class="justify-content-center" style="width: 100%">
                        <b-spinner variant="light" style="width: 4rem; height: 4rem;" class="mb-2" />
                        <h3>Carregando complementos</h3>
                    </div>
                </div>
				<JitsiMeet ref="jitsiRef" tipo="apresentador" v-if="moderador_is_on && em_atividade" :domain="$store.state.sala.servico_jitsi"/>
				<div v-else-if="is_mounted">
					<div class="ilustracao">
						<font-awesome-icon icon="chalkboard-teacher" size="6x"/>
						<br>
						<strong class="loading">Aguardando o Moderador</strong><br>
						<small>O evento iniciará quando o moderador acessar a sala.</small>
					</div>
				</div>
            </div>
        </div>
    </fullscreen>
</template>

<script>
import JitsiMeet from "@/components/Salas/Jitsi/JitsiMeet";
import Chat from "@/components/widgets/Chat";
import Registros from "@/components/widgets/Registros";
import DraggableDiv from "@/components/widgets/DraggableDiv";
import SalaTitulo from "@/components/Salas/SalaTitulo";
import Recursos from "@/components/widgets/Recursos";
import SalasMenu from "@/components/Salas/SalasMenu";
import SalaDispositivos from "@/components/Salas/SalaDispositivos";
import {
	// DATA
	dados,
	// COMPUTED
	moderadores_nomes,
	apresentadores_nomes,
	participantes_nomes,
	camera_status,
	// MÉTODOS
	abreSalaInterprete,
	abrirConfiguracoes,
	getDispositivos,
	jitsiId,
	getComandos,
	getInfosUsuarios,
	toggleMicrofone,
	toggleCamera,
	jitsiCmd,
	logout,
	irHome,
	iniciaCountdown,
	cancelaCountdown,
	getStatusSala,
	getStatusUsuario,
	toggleChat,
	toggleFullScreen,
	fullscreenChange,
	verificaPermissao,
	registraEntradaUsuario,
	registraSaidaUsuario,
	getAcessoSalaLength,
	showRecursos,
	showRegistros,
	getChatNaoLidas,
	uploadAvatar,
	getSala,
	redirecionaProximoEvento
} from "@/modulos/sala"

export default {
    components: {SalaDispositivos, SalasMenu, SalaTitulo, DraggableDiv, JitsiMeet, Chat, Registros, Recursos},
    data() {
        return {
			sala: this.$store.state.sala,
			sala_tipo: 'apresentador',
			...dados
		}
    },
	watch: {
		sala(){
			if(this.sala.finalizado){
				this.redirecionaProximoEvento()
			}
		}
	},
    computed: {
		moderadores_nomes,
		apresentadores_nomes,
		participantes_nomes,
		camera_status
    },
    methods: {
		abreSalaInterprete,
		abrirConfiguracoes,
		getDispositivos,
		jitsiId,
		getComandos,
		getInfosUsuarios,
		toggleMicrofone,
		toggleCamera,
		jitsiCmd,
		logout,
        irHome,
        iniciaCountdown,
        cancelaCountdown,
        getStatusSala,
		getStatusUsuario,
        toggleChat,
        toggleFullScreen,
        fullscreenChange,
        verificaPermissao,
        registraEntradaUsuario,
        registraSaidaUsuario,
        getAcessoSalaLength,
        showRecursos,
        showRegistros,
        getChatNaoLidas,
        uploadAvatar,
        getSala,
		redirecionaProximoEvento
    },
    beforeCreate() {
        if(!this.$store.getters.loggedIn){
            window.localStorage.setItem('redirect_sala', this.$route.params.slug)
			this.$store.dispatch('userLogout').then(() => {
                window.location.href = window.location.origin+'/login'
            })
		}else{
            window.localStorage.removeItem('redirect_sala')
        }
		if(!this.$store.state.sala) {
			window.localStorage.setItem('redirect_sala', this.$route.params.slug)
			window.location.href = window.location.origin
		}
    },
    created() {
		this.$session.clear();
        if(!this.$store.state.sala) {
            window.localStorage.setItem('redirect_sala', this.$route.params.slug)
			window.location.href = window.location.origin
        }else{
            this.moderador_is_on = this.$store.state.sala.nivel_usuario === 'moderador';
            this.registraEntradaUsuario();
            this.getStatusSala()
            this.getAcessoSalaLength(false)
        }
        this.$store.commit('updateContentActive', false);
		this.interval = setInterval(() => {
			this.getStatusSala()
			this.getStatusUsuario()
			this.getComandos()
			this.getAcessoSalaLength()
            this.getChatNaoLidas()
			this.getInfosUsuarios()
			if(!this.my_jitsi_id) this.jitsiId()
		}, 5000);
		setTimeout(function(){
            document.getElementById('carregando').style.display = 'none'
        }, 5000)
		setTimeout(()=>{
            this.tempo_15 = true
        }, 15000)
    },
    beforeDestroy() {
		this.$store.commit('updateSala', {})
        window.removeEventListener('beforeunload', this.registraSaidaUsuario)
        clearInterval(this.interval)
		this.$root.$off('show_registros')
		this.$root.$off('show_recursos')
		this.$root.$off('toggle_fullscreen')
		this.$root.$off('toggle_chat')
		this.$root.$off('abrir_configuracoes')
		this.$root.$off('atualizar_dispositivos')
		this.$root.$off('jitsi_mounted')
		this.$root.$off('logout')
    },
    destroyed() {
        this.$store.commit('updateContentActive', true);
    },
    beforeRouteLeave(to, from, next) {
        this.registraSaidaUsuario()
		if(to.name === 'login') this.$store.dispatch('userLogout')
        next()
    },
    beforeMount() {
        window.addEventListener('beforeunload', this.registraSaidaUsuario)
    },
    mounted() {
        this.em_atividade = this.iniciouHorario && this.moderador_is_on
        this.ballon_avatar = !this.$store.state.user.avatar || this.$store.state.user.avatar.length < 1
        this.is_mounted = true
		this.$root.$on('show_registros', ()=>this.showRegistros())
		this.$root.$on('show_recursos', ()=>this.showRecursos())
		this.$root.$on('toggle_fullscreen', ()=>this.toggleFullScreen())
		this.$root.$on('toggle_chat', ()=>this.toggleChat())
		this.$root.$on('abrir_configuracoes', ()=>this.abrirConfiguracoes())
		this.$root.$on('atualizar_dispositivos', ()=>this.getDispositivos())
		this.$root.$on('jitsi_mounted', ()=>this.getDispositivos())
		this.$root.$on('logout', ()=>this.logout())
    }
}
</script>

<style scoped>
#toolbar{
	position: absolute;
	z-index: 205;
	left: calc(50vw - 100px);
	bottom: 25px;
}
.video-container{
  width:100vw;
  height:100vh;
  overflow:hidden;
  position:relative;
}
.video-container iframe,{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container iframe, {
  pointer-events: none;
}
.video-container iframe{
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
}
.video-foreground{
  pointer-events:none;
}

body {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    height: 100vh !important;
}

#container{
    height: 100vh;
    width: 100vw;
    position: absolute;
}

#col_menu{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50px;
    background-color: #474747;
}

#col_menu div{
    position: absolute;
    width: 50px;
}

#col_video{
    position: absolute;
    top: 0;
    left: 50px;
    right: 0;
    bottom: 0;
}

ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
li{
    text-align: center;
    padding: 10px 5px;
    position: relative;
}
ul.horizontal li {
    display: inline;
}

#chat{
    position: absolute;
    z-index: 2000;
    background-color: rgba(10, 10, 10, .3);
    border-radius: 4px;
}

.ballon{
    position: absolute;
    right: 60px;
    bottom: 30px;
    width: 400px;
    height: 160px;
    z-index: 2001;
    border-radius: 4px;
    padding: 10px;
}

#ballon_avatar{
    background-color: rgb(255, 171, 0);
    color: #0f0f16;
    z-index: 2001;
    border-radius: 4px;
    padding: 10px;
}

#ballon_countdown{
    background-color: #dc3545;
    color: whitesmoke;
    text-align: center;
}

#ballon_interprete{
	left: 80px;
	top: calc(50vh - 27px);
	right: unset !important;
	bottom: unset !important;
	cursor: pointer;
	background-color: #0194D3;
	color: whitesmoke;
	width: 53px;
	height: 53px;
}

.slide_chat-leave-active,
.slide_chat-enter-active {
    transition: all .3s;
}

.slide_chat-enter {
    transform: translateX(-400px);
}

.slide_chat-leave-to {
    transform: translateX(calc(-100vw - 400px));
}

#carregando{
    position: absolute;
    width: 600px;
    height: 400px;
    background-color: #474747;
    left: calc(50vw - 300px);
    top: calc(50vh - 200px);
    color: whitesmoke;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style>
.slide-leave-active,
.slide-enter-active {
    transition: all .5s;
}

.slide-enter, .slide-leave-to {
    transform: translateX(500px);
}

.slide-leave-active {
    transition: all .5s;
}

.fullscreen {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

#col_chat {
    background-color: #474747;
    font-size: small;
    position: relative;
}

#col_chat_header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: 105px;
}

#col_chat_content {
    position: absolute;
    top: 105px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

#side_modal {
    position: absolute;
    top: 2px;
    right: 0;
    height: calc(100vh - 4px);
    background-color: white;
    border-radius: 10px 0 0 10px;
    z-index: 210;
    width: 500px;
}

.infos_evento {
    margin-top: 15px;
    color: whitesmoke;
    text-align: center;
}

.ilustracao {
    color: whitesmoke;
    height: 300px;
    margin: calc(50vh - 150px) auto;
    text-align: center;
}

.title_jitsi {
    position: absolute;
    top: 3px;
    left: 20px;
    color: white;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 15px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 15px;
    }
}
</style>