<template>
	<div>
		<div id="menu">
			<ul>
				<li class="p-2">
					<img id="logo-vert" src="@/assets/logoV2_semfundo.svg">
				</li>
				<li>
					<b-button style="width: 42px;"
						@click="confirmarSaida"
						title="Página Inicial" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="home"/></b-button>
				</li>
				<li class="pb-3">
					<b-button style="width: 42px;"
						@click="$root.$emit('toggle_fullscreen')"
						title="Tela Cheia" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="arrows-alt"/></b-button>
				</li>

				<li v-bind:class="{'item-ativo': active==='acessos'}">
					<b-button style="width: 42px;"
						@click="emitir('acessos')"
						title="Acessos" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="people-arrows"/></b-button>
				</li>
				<li v-if="criado_pelo_sistema_eventos" v-bind:class="{'item-ativo': active==='recursos'}">
					<b-button style="width: 42px;"
						@click="emitir('recursos')"
						title="Recursos" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="paperclip"/></b-button>
				</li>
				<li v-bind:class="{'item-ativo': active==='configuracoes'}">
					<b-button style="width: 42px;"
						@click="emitir('configuracoes')"
						title="Configurações" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="tools"/></b-button>
				</li>
				<li v-bind:class="{'item-ativo': active==='infos'}">
					<b-button style="width: 42px;"
						@click="emitir('infos')"
						title="Informações da Sala" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon class="text-black-50" icon="info"/></b-button>
				</li>
				<li v-if="false" v-bind:class="{'item-ativo': active==='mensagens'}">
					<b-button style="width: 42px;"
						@click="emitir('mensagens')"
						title="Mensagens Privadas" v-b-tooltip.hover.right
						variant="link"
					><font-awesome-icon icon="envelope" class="text-black-50"/>
					</b-button>
				</li>
				<li v-bind:class="{'item-ativo': active==='chat'}">
					<b-button style="width: 42px;"
						@click="emitir('chat')"
						title="Chat" v-b-tooltip.hover.right
						variant="link"
					>
						<font-awesome-icon icon="comments" class="text-black-50"/>
						<span class="badge" v-show="this.chat_nao_lidas > 0">{{ this.chat_nao_lidas }}</span>
					</b-button>
				</li>

				<li
					v-bind:class="{'item-ativo': active==='usuarios', 'moderador': true}"
					v-if="$store.state.sala.nivel_usuario === 'moderador'"
				>
					<b-button style="width: 42px;"
						@click="emitir('usuarios')"
						title="Apresentadores e Participantes" v-b-tooltip.hover.right
						variant="link"
					>
						<font-awesome-icon icon="users" style="color: #0f695f"/>
					</b-button>
				</li>
				<li
					v-bind:class="{'item-ativo': active==='streaming', 'moderador': true}"
					v-if="$store.state.sala.nivel_usuario === 'moderador'"
				>
					<b-button style="width: 42px;"
						@click="emitir('streaming')"
						title="Gravação e Transmissão" v-b-tooltip.hover.right
						variant="link"
					>
						<font-awesome-icon icon="play-circle" style="color: #0f695f"/>
					</b-button>
				</li>
				<li
					v-bind:class="{'item-ativo': active==='subsalas', 'moderador': true}"
					v-if="$store.state.sala.nivel_usuario === 'moderador'"
				>
					<b-button style="width: 42px;"
						@click="emitir('subsalas')"
						title="Sub-Salas" v-b-tooltip.hover.right
						variant="link"
					>
						<font-awesome-icon icon="laptop-house" style="color: #0f695f"/>
					</b-button>
				</li>
			</ul>
		</div>
		<div id="content-slide" v-show="show_content_slide">
			<div class="btn-fechar">
				<b-button pill @click="closeContenSlide" size="sm" variant="light">
					<font-awesome-icon icon="times" />
				</b-button>
			</div>
			<component v-show="!['streaming', 'chat'].includes(active)"
				style="font-size: .8rem"
				:is="component"
				:acessos="acessos"
				:dispositivos="dispositivos"
			/>
			<Streaming v-show="active === 'streaming'" />
			<Chat v-show="active === 'chat'" />
		</div>
	</div>
</template>

<script>
import Infos from "@/components/Salas/SalasMenu/Infos";
import Acessos from "@/components/Salas/SalasMenu/Acessos";
import Chat from "@/components/Salas/SalasMenu/Chat"
import Recursos from "@/components/widgets/Recursos";
import SalaDispositivos from "@/components/Salas/SalaDispositivos";
import Usuarios from "@/components/Salas/SalasMenu/Usuarios";
import Streaming from "@/components/Salas/SalasMenu/Streaming";

export default {
	name: "SalasMenuWS",
	components:{Infos, Acessos, Chat, Recursos, SalaDispositivos, Usuarios, Streaming},
	props: {
		acessos: {type: Array},
		dispositivos: {type: Object}
	},
	data() {
		return {
			active: null,
			criado_pelo_sistema_eventos: false,
			show_content_slide: false,
			component: null,
			chat_lidas: 0,
			chat_nao_lidas: 0
		}
	},
	methods: {
		confirmarSaida(){
			this.$bvModal.msgBoxConfirm('Deseja realmente sair da Sala?', {
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'success',
				okTitle: 'Sim',
				cancelTitle: 'Não',
				cancelVariant: 'dark',
				footerClass: 'p-2',
				hideHeader: true,
				centered: true,
				bodyBgVariant: "dark",
				bodyTextVariant: "light",
				footerBgVariant: "dark",
				footerTextVariant: "light",
			})
			.then(value => {
				if(value) this.$router.push({name:'home'}).catch(()=>{})
			})
		},
		emitir(funcao){
			if(this.active === funcao){
				this.closeContenSlide()
				return
			}
			this.active = funcao
			setTimeout(()=>{this.show_content_slide = true}, 201)
			this.$root.$emit('open-content-slide', 'open')
		},
		closeContenSlide(){
			this.active = null
			this.show_content_slide = false
			this.$root.$emit('open-content-slide', 'close')
		},
		recalculaChatNaoLidas(){
			if(this.active !== 'chat'){
				this.chat_nao_lidas += 1;
			}else{
				this.chat_lidas += 1;
				this.chat_nao_lidas = 0;
			}
		}
	},
	watch:{
		active(){
			if(this.active === 'infos') this.component = Infos
			else if(this.active === 'acessos') this.component = Acessos
			else if(this.active === 'chat') {
				this.component = null
				this.chat_lidas = this.chat_nao_lidas
				this.chat_nao_lidas = 0
			}
			else if(this.active === 'recursos') this.component = Recursos
			else if(this.active === 'configuracoes') this.component = SalaDispositivos
			else if(this.active === 'usuarios') this.component = Usuarios
			else if(this.active === 'streaming') this.component = null
			else this.component = null
		}
	},
	created(){
		this.chat_lidas = this.$store.state.sala.chat_mensagens.length
		// this.$eventos.get(`/atividades/?sala_meet_id=${this.$store.state.sala.id}`)
		// .then(response => {
		// 	this.criado_pelo_sistema_eventos = response.data.results !== ''
		// })
	},
	mounted() {
		this.$root.$on('receive_message_chat', ()=>this.recalculaChatNaoLidas())
	},
	beforeDestroy() {
		this.$root.$off('receive_message_chat')
	}
}
</script>

<style scoped>
	#menu{
		float: left;
	}
	#logo-vert{
		width: 34px;
	}
	#content-slide{
		position: relative;
		background-color: white;
		height: 100vh;
		width: 395px;
		float: left;
		overflow-y: auto;
		overflow-x: hidden;
	}
	#content-slide .btn-fechar{
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 9999;
	}


	ul{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	li{
		text-align: center;
		padding: 10px 5px;
		position: relative;
	}
	li:hover{
		background-color: lightgray;
	}
	li button span.badge{
		position: absolute;
		border-radius: 50%;
		background-color: #bb2f22;
		color: white;
		margin-top: .6rem;
		font-weight: normal;
		width: 1.1rem;
	}


	.item-ativo{
		background-color: silver;
		border-right: 3px solid gray;
	}
	ul li.moderador{
		margin-top: 1.5rem;
	}
	ul li.moderador ~ li.moderador{
		margin-top: 0;
	}
</style>