<template>
	<form ref="form" @submit="atualizaDadosSala">
		<div class="row">
			<div class="col">
				<b-form-group
					label="Nome"
					label-for="nome-input">
					<b-form-input
						id="nome-input"
						v-model="sala.nome"
						required>
					</b-form-input>
				</b-form-group>
			</div>
			<div class="col">
				<b-form-group label="Tipo:" label-for="tipo_sala">
					<b-form-select
						id="tipo_sala"
						v-model="sala.tipo"
						:options="tipos"
						required
					></b-form-select>
				</b-form-group>
			</div>
		</div>
		

		<b-form-group id="input-group-2">
			<b-form-textarea
					id="descricao-input"
					placeholder="Descrição da Sala"
					required
					v-model="sala.descricao"
			/>
		</b-form-group>

		<div class="row">
			<div class="col">
				<b-form-group label="Data início">
					<date-picker input-class="form-control" format="DD/MM/YYYY HH:mm" :show-second="false"  v-model="sala.data_inicio" type="datetime"></date-picker>
				</b-form-group>
				<br>
			</div>
			<div class="col">
				<b-form-group label="Data fim">
					<date-picker input-class="form-control" format="DD/MM/YYYY HH:mm" :show-second="false"  v-model="sala.data_fim" type="datetime"></date-picker>
				</b-form-group>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<b-form-group label-for="input-gravar">
					<b-form-checkbox id="input-gravar" switch v-model="sala.gravar" class="float-left">
						Gravar Evento
						<span v-b-tooltip.hover title="O evento é gravado localmente (disponibilização de arquivo) ou no Youtube." style="color: #6c757d">
							<font-awesome-icon icon="question-circle"/>
						</span>
					</b-form-checkbox>
				</b-form-group>
			</div>
			<div class="col">
				<b-form-group label-for="input-privado">
					<b-form-checkbox aria-describedby="privadoHelpInline" id="input-privado" name="check-button" switch v-model="sala.privado">
						Evento Privado
						<span v-b-tooltip.hover title="Salas privadas só permitem acesso de Participantes e Apresentadores previamente definidos."
							style="color: #6c757d">
							<font-awesome-icon icon="question-circle"/>
						</span>
					</b-form-checkbox>
				</b-form-group>
			</div>
			<div class="col">
				<b-form-group label-for="input-mic-bloqueado">
					<b-form-checkbox aria-describedby="privadoHelpInline" id="input-mic-bloqueado" name="check-button" switch v-model="sala.microfone_bloqueado">
						Microfone bloqueado
						
					</b-form-checkbox>
				</b-form-group>
			</div>
			<div class="col">
				<b-form-group label-for="input-fixada">
					<b-form-checkbox aria-describedby="privadoHelpInline" id="input-fixada" name="check-button" switch v-model="sala.fixada">
						Fixada
						
					</b-form-checkbox>
				</b-form-group>
			</div>
			<!-- <div class="col">
				<b-form-group label-for="input-participantes-ativos">
					<b-form-checkbox aria-describedby="privadoHelpInline" id="input-participantes-ativos" name="check-button" switch v-model="sala.participantes_ativos">
						Participantes ativos
						
					</b-form-checkbox>
				</b-form-group>
			</div> -->
			
		</div>
		<br>
		<div class="text-right">
			<b-button @submit="atualizaDadosSala" class="ml-2" type="submit" variant="primary">Salvar
			</b-button>
		</div>
	</form>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
export default {
	name: "SalaEditar",
	components: {DatePicker},
	data () {
		return {
		}
  },
	props: {
		sala: {
			type: Object,
			required: true
		},
		tipos: {
			type: Array,
			default: function () {
				return [
					{text: 'Selecione', value: null},
					{text: 'Aula', value: 3},
					{text: 'Conferência', value: 2},
					{text: 'Reunião', value: 1},
				]
			}
		}
	},
	methods:{
		atualizaDadosSala(e){
			let sala_id = ''
			e.preventDefault();
			if(this.$route.params.slug){
				sala_id = this.$store.state.sala.id
			}else{
				sala_id = this.sala.id
			}
			this.$http.put(`salas/${sala_id}/`, this.sala)
			.then(response => {
				console.log(response)
				this.$toast.open({
					message: 'Sala atualizada com sucesso!',
					type: 'success',
					duration: 5000,
					position: 'top-right'
				})
				this.$root.$emit('sala_atualizada')
			},
			erro=>{
				console.log(erro)
				this.$toast.open({
					message: 'Ocorreu um erro ao atualizar a sala!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
		}
	},
	created(){
		this.sala.data_inicio = new Date(this.sala.data_inicio)
		this.sala.data_fim = new Date(this.sala.data_fim)
	}
}
</script>