<template>
  <div class="body">
    <b-form class="form-signin" @submit="onSubmit">
    <div class="text-center">
      <img src="@/assets/brasao.png" verical-align="center" alt="Center image" width="100" height="100">
      <br>
      <h1 class="h3 mb-3 font-weight-normal">Acesse a sua conta</h1>
      <h3 class="h5 mb-3 font-weight-normal">Usuários Externos</h3>
  </div>
  <b-form-group
        id="input-group-1"
        label="Email"
        label-for="usuario"
      >
        <b-form-input
          id="usuario"
          v-model="user.username"
          required
          placeholder="Email"
        ></b-form-input>
      </b-form-group>

      <label for="text-password">Senha</label>
    <b-input v-model="user.password" placeholder="Senha" type="password" id="text-password" aria-describedby="password-help-block"></b-input>
  <br>
  <b-button @submit="onSubmit" class="" type="submit" block variant="primary">Entrar</b-button>
  <div class="text-center">
    <p class="mt-5 mb-3 text-muted">© 2020</p>
  </div>


</b-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: {
          username: '',
          password: '',
        }
      }
    },
    methods: {
      login(){
        this.$store.dispatch('userLogin', {
          username: this.user.username,
          password: this.user.password,
        })
        .then(() => {
          this.$http.get(`/users-busca/?username=${this.user.username}`)
          .then(response => {
            this.$store.commit('setUser', response.data);
            window.localStorage.setItem("username", response['data'][0]['username'])
            window.localStorage.setItem("nome_user", response['data'][0]['last_name'])
            window.localStorage.setItem("email_user", response['data'][0]['email'])
          })
          this.$router.push({name: 'home'})
        })
        .catch(() => {
          this.incorrectAuth = true
        })
      },
      
      onSubmit(e) {
        this.login()
        e.preventDefault()
      },
    }
  }
</script>

<style scoped>
  .body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }

  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
  }
  /* @import url('../assets/sign-in.css'); */
</style>