<template>
	<b-col id="banner_dashboard">
    <b-overlay :show="show_overlay" rounded="sm">
		<img src="@/assets/logoV2_semfundo.svg">
		<br>
		<h1>Bem-Vindo ao Meet!</h1>
		<p class="text-justify">Mantenha-se conectado com segurança e eficiência em suas Reuniões, Conferências ou Videoaulas.
			<br>Crie Salas e restrinja o acesso definindo os participantes, ou simplesmente inicie uma Sala Instantânea e compartilhe um link com quem você deseja se conectar.
			<br>A solução desenvolvida pela Defensoria Pública do Estado do Tocantins que permite a conexão de dezenas de pessoas na mesma reunião virtual, permitindo conversas por áudio e vídeo, compartilhamento de materiais multimídia e possibilitando a interação entre pessoas fisicamente distantes.</p>
			<br>
		<p class="text-center justify-content-center align-items-center">
			<b-input-group class=" justify-content-center align-items-center">
				<b-button variant="success" @click="criarSala">
					<font-awesome-icon icon="comments" />
					Criar Sala
				</b-button>
				<b-input-group class="ml-1" style="width: 400px">
					<b-form-input class="input-instantroom-name" v-model="nome_instantaneo" placeholder="Digite um nome (ou gerar aleatório)"></b-form-input>
					<b-input-group-append>
						<b-button variant="info" @click="criarSalaRapido">Sala Instantânea</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-input-group>
		</p>
    </b-overlay>
	</b-col>
</template>

<script>
export default {
name: "DashboardBanner",
	data() {
		return {
			show_overlay: false,
			nome_instantaneo: null,
		}
	},
	methods: {
		criarSala() {
			this.$root.$emit('criar_sala')
		},
		criarSalaRapido() {
			this.show_overlay = true
			this.$root.$emit('criar_sala-rapido', this.nome_instantaneo)
		}
	},
	beforeDestroy() {
		this.$root.$off('hide-overlay-dashboard')
	},
	mounted() {
		this.$root.$on('hide-overlay-dashboard', () => {
			this.show_overlay = false
		})
	}
}
</script>

<style scoped>
	#banner_dashboard{
		padding: 50px 10px;
		border-radius: 5px;
		text-align: center;
		height: 100%;
	}
	.input-instantroom-name{
		border-color: #17a2b8;
	}
	.input-instantroom-name::placeholder{
		font-size: .8rem;
	}
</style>