<template>
	<b-list-group>
		<b-list-group-item class="flex-column align-items-start border-0" v-for="u in usuarios" :key="u.id">
			<b-avatar class="mr-2"
				:src="u.avatar"
				:text="!u.avatar ? getIniciais(u.nome) : ''"
				:style="{backgroundColor: getCor(u.nome)}"
				badge :badge-variant="u.is_online ? 'success' : 'danger'"
				v-b-tooltip.hover.top :title="u.is_online ? 'On-Line' : 'Off-Line'"
			/>
			{{ u.nome }}
			<b-button-group class="float-right" size="sm">
				<b-button size="sm" variant="light" v-if="u.is_online"
					@click="muteUsuario(u)"
					:style="{color: u.microfone ? 'green' : 'red'}"
					v-b-tooltip.hover.top title="Mudo/Não Mudo"
				>
					<font-awesome-icon :icon="u.microfone ? 'microphone' : 'microphone-slash'"/>
				</b-button>
				<b-button size="sm" variant="light" v-if="u.is_online"
					@click="pinVideo(u)"
					v-b-tooltip.hover.top title="Fixar Foco de Vídeo"
				>
					<font-awesome-icon icon="video"/>
				</b-button>
				<b-button size="sm" variant="light"
					@click="removeUsuario(u)"
					v-b-tooltip.hover.top title="Remover da Sala"
				>
					<font-awesome-icon icon="trash"/>
				</b-button>
			</b-button-group>
		</b-list-group-item>
		<p class="text-center text-black-50 mt-5" v-if="usuarios.length === 0">
			Nenhum {{tipo}}
		</p>
	</b-list-group>
</template>

<script>
export default {
	name: "UsuariosList",
	props: {
		tipo: {required: true}
	},
	computed:{
		usuarios(){
			if(this.tipo === 'Apresentador'){
				return this.$store.state.sala.apresentadores
			}else if(this.tipo === 'Participante'){
				return this.$store.state.sala.participantes
			}else{
				return this.$store.state.sala.moderadores
			}
		}
	},
	methods: {
		getIniciais(nome){
			nome = nome.split(' ')
			return nome[0][0]+nome[1][0]
		},
		getCor(nome){
			let hash = 0;
			for(let i=0; i<nome.length; i++){
				hash = nome.charCodeAt(i) + ((hash << 5) - hash);
			}
			let cor = (hash & 0x00FFFFFF).toString(16).toUpperCase()
			cor = "#" + "00000".substring(0, 6 - cor.length) + cor
			return cor
		},
		muteUsuario(usuario){
			this.$http.post('usuario_configs/', {
				usuario: usuario['id'],
				microfone: !usuario['microfone'],
				sala: this.$store.state.sala.id
			})
		},
		pinVideo(usuario){
			window.jitsiRef.jitsiApi.executeCommand(
				'setLargeVideoParticipant',
				usuario.jitsi_id
			);
			window.jitsiRef.jitsiApi.pinParticipant(usuario.jitsi_id);
		},
		removeUsuario(usuario){
			if(confirm('Deseja realmente remover o usuário?')) {
				if (usuario.is_online) window.jitsiRef.jitsiApi.executeCommand('kickParticipant', usuario.jitsi_id)
				this.$http.delete(`sala/usuarios/?tipo=${this.tipo.toLowerCase()}&usuario=${usuario['id']}&sala=${this.$store.state.sala.id}`)
			}
		}
	}
}
</script>

<style scoped>

</style>