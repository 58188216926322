<template>
	<div class="p-2">
		<h3 class="pb-2 border-bottom">Usuários</h3>
		<b-row v-show="!show_form">
			<b-col class="text-center">
				<b-button size="sm" variant="outline-success" @click="show_form=true">
					<font-awesome-icon icon="user-plus" />
					Adicionar
				</b-button>
			</b-col>
		</b-row>
		<b-row v-show="show_form" class="mt-4">
			<b-col>
				<h5 class="border-bottom font-weight-lighter text-center">Adicionar</h5>
				<b-row>
					<b-col cols="3" class="pt-1">Tipo:</b-col>
					<b-col>
						<b-select size="sm" v-model="tipo">
							<b-select-option value=null disabled>Selecione...</b-select-option>
							<b-select-option value="apresentador">Apresentador</b-select-option>
							<b-select-option value="participante">Participante</b-select-option>
							<b-select-option value="moderador">Moderador</b-select-option>
						</b-select>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col cols="3" class="pt-1">Usuário:</b-col>
					<b-col>
						<Select2 v-if="mounted" id="participantes" name="participantes"
							:disabled="tipo===null"
							:settings="s2GetSettings('Digite o nome')"
							@select="s2SelectUsuario($event)"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="3"></b-col>
					<b-col>
						<CriacaoSalaParticipantes
							v-bind:participantes="selecionados"
							:tipo="tipo"
						/>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col class="text-right">
						<b-button variant="light" size="sm" @click="show_form=false">Cancelar</b-button>
						<b-button variant="success" size="sm" @click="adicionar" class="ml-1">Adicionar</b-button>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-row v-show="!show_form">
			<b-col>
				<b-tabs content-class="mt-2" class="mt-3" align="center">
					<b-tab title="Apresentadores" active>
						<UsuariosList tipo="Apresentador" />
					</b-tab>
					<b-tab title="Participantes">
						<UsuariosList tipo="Participante" />
					</b-tab>
					<b-tab title="Moderadores">
						<UsuariosList tipo="Moderador" />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>

	</div>
</template>

<script>
import UsuariosList from "@/components/Salas/SalasMenu/UsuariosList";
import Select2 from 'v-select2-component'
import CriacaoSalaParticipantes from "@/components/widgets/CriacaoSalaParticipantes";
import {addUsuarios} from "@/modulos/sala"

export default {
	components: {UsuariosList, Select2, CriacaoSalaParticipantes},
	name: "Usuarios",
	data(){
		return {
			mounted: false,
			show_form: false,
			url: this.$store.state.default_domain + '/api/participantes_busca/',
			s2_value: '',
            s2_options: [],
			selecionados: [],
			tipo: null
		}
	},
	methods:{
		addUsuarios,
		adicionar(){
			if(this.tipo && this.selecionados.length > 0){
				this.addUsuarios(this.tipo, this.selecionados)
				this.selecionados = []
				this.tipo = null
				this.show_form = false
				this.$http.get('/salas/?slug='+this.$store.state.sala.slug).then(response=>this.$store.commit('updateSala', response.data.data[0]));
			}
		},
		s2GetSettings(placeholder){
            return {
                minimumInputLength: 3,
                placeholder: placeholder,
				dropdownParent: document.getElementById('addusuariosala_content'),
				dropdownAutoWidth : true,
				width: '100%',
                language: {
                    inputTooShort() {
                        return 'Informe no mínimo 3 caracteres...'
                    },
                    noResults() {
                        return 'Nenhuma pessoa com este nome.'
                    },
                },
                ajax: {
                    url: this.url,
                    type: 'GET',
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + window.localStorage.getItem('accessToken'))
                    },
                    data(params) {
                        return {nome: params.term}
                    },
                    processResults(data) {
                        return {results: data}
                    }
                },
                templateResult: this.s2MakeOption,
                escapeMarkup(markup) {
                    return markup;
                }
            }
        },
        s2MakeOption(data) {
            if (data.loading) return "Procurando...";
            let html = "<div class='clearfix'>";
            if (data.origem === 'meet' && data.avatar !== '' && data.avatar !== null) {
                html += `<div class='float-left mr-1'>
                <img class="rounded-circle" height="22px" src="https://static.defensoria.to.def.br/meet-media/${data.avatar}"></div>`;
            }
            if (data.origem === 'email') {
                html += `<div class='float-left'><small class="text-muted">Enviar convite para:</small> ${data.email}</div></div>`;
            } else {
                html += `<div class='float-left'>${data.nome}</div></div>`;
            }
            return html
        },
        s2SelectUsuario(data) {
			this.selecionados.push(data);
        },
        deleteUsuario(pos) {
			this.selecionados.splice(pos, 1);
        },
	},
	mounted() {
		this.$root.$on('delete-usuario', args=>this.deleteUsuario(args.pos))
		this.mounted = true
	},
	beforeDestroy() {
		this.$root.$off('delete-usuario')
	}
}
</script>

<style scoped>
	ul{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	li{
		padding: 0 0 1rem 0;
	}
</style>