import Vue from 'vue'
import Router from 'vue-router'
import CriarSala from '@/components/widgets/CriacaoSala'
import EnviaToken from '@/components/widgets/EnviaToken'
import Index from '@/components/Index'
import Registros from '@/components/widgets/Registros'
import Sala from '@/components/Salas/Sala'
import Login from '@/components/Login'
import Register from '@/components/Register'
import LoginExternal from '@/components/LoginExternal'
import Logout from '@/components/Logout'
import SalaModerador from "@/components/Salas/SalaModerador";
import SalaModeradorWS from "@/components/Salas/SalaModeradorWS";
import SalaApresentador from "@/components/Salas/SalaApresentador";
import SalaEspectador from "@/components/Salas/SalaEspectador";
import SalaWS from "@/components/Salas/SalaWS";

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        {
            name: "home",
            path: "/",
            component: Index,
            meta: {requiresLogin: true}
        },
        {
            name: "criar-sala",
            path: "/criar-sala",
            component: CriarSala,
            meta: {requiresLogin: true}
        },
        {
            name: "envia-token",
            path: "/envia-token",
            component: EnviaToken,
            meta: {requiresLogin: true}
        },
        {
            name: "registros",
            path: "/registros-sala/:id",
            component: Registros,
        },
        {
            name: "sala",
            path: "/sala/:slug",
            component: Sala,
            // meta: {requiresLogin: true}
        },
        {
            name: "sala_moderador",
            path: "/sala/:slug",
            component: SalaModerador,
            // meta: {requiresLogin: true}
        },
        {
            name: "sala_moderador_WS",
            path: "/sala/:slug",
            component: SalaModeradorWS,
            // meta: {requiresLogin: true}
        },
        {
            name: "sala_WS",
            path: "/sala/:slug",
            component: SalaWS,
            // meta: {requiresLogin: true}
        },
        {
            name: "sala_espectador",
            path: "/sala/:slug",
            component: SalaEspectador,
            // meta: {requiresLogin: true}
        },
        {
            name: "sala_apresentador",
            path: "/sala/:slug",
            component: SalaApresentador,
            // meta: {requiresLogin: true}
        },
        {
            name: "login",
            path: "/login",
            component: Login,
        },
        {
            name: "register",
            path: "/register/:token?",
            component: Register,
        },
        {
            name: "login-external-user",
            path: "/loginexternaluser",
            component: LoginExternal,
        },
        {
            name: "logout",
            path: "/logout",
            component: Logout,
        },

    ]
})

export default router