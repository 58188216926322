import Vue from 'vue'
import axios from 'axios'
import Axios from 'axios'
import {Promise} from 'es6-promise'


const athenas = axios.create({
    baseURL: "https://athenas.defensoria.to.def.br/api/",
    headers: {'Authorization': "token ffa8db8b39e141f4f7c919ebcfee10a3d5c340d9"}
})

const eventos = axios.create({
    baseURL: "https://eventos.defensoria.to.def.br/core/api/",
    headers: {'Authorization': "token 56e5ccbb31ba88a9aef24150e070ba7e3ef17765"}
})

// const baseURL = 'http://localhost:8000/'
const baseURL = 'https://defmeet.defensoria.to.def.br/'

const auth = axios.create({baseURL})

const local = axios.create({
    baseURL: baseURL+'api/',
});

local.interceptors.request.use(config=>{
    const token = localStorage.getItem('accessToken')
    if(token) config.headers.Authorization = `Bearer ${token}`
    return config
}, err=>{
    return Promise.reject(err)
})

local.interceptors.response.use(
    response=>response,
    error=>{
        if(error.response.status === 401) return refreshToken()
            .then(token=>{
                window.localStorage.setItem('accessToken', token);
                error.config.headers['Authorization'] = 'Bearer '+token;
                return Axios.request(error.config).catch(error=>{
                    if(error.code === 'token_not_valid'){
                        window.location.href = window.location.origin+'/login'
                    }
                });
            })
            .catch(err=>err);
        return Promise.reject(error)
    }
)

const refreshInstance = Axios.create()
function refreshToken(){
    return refreshInstance.post(
        baseURL + 'api-token-refresh/',
        {refresh: window.localStorage.getItem('refreshToken')}
    ).then(
        response => {
            window.localStorage.setItem('accessToken', response['data']['access'])
            return Promise.resolve(response['data']['access'])
        },
        err=>{
            console.log(err)
            window.location.href = window.location.origin+'/login'
        }
    ).catch(err=>{
        console.log(err)
        window.location.href = window.location.origin+'/login'
    })
}

export {auth}
Vue.use({
    install(Vue){
        Vue.prototype.$http = local
        Vue.prototype.$athenas = athenas
        Vue.prototype.$eventos = eventos
    }
})
