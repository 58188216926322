<template class="fundo_escuro">
	<fullscreen @change="fullscreenChange" ref="fullscreen">
		<transition name="slide">
			<div id="side_modal" v-if="show_modal">
				<b-link @click="show_modal = !show_modal" variant="link">
					<font-awesome-icon class="float-left mr-2 ml-2 mt-2" icon="times-circle" size="lg"
						style="color: #0f0f16"/>
				</b-link>
				<component v-bind:is="component_modal"/>
			</div>
		</transition>
		<div id="nao_visivel_tela" class="d-md-block d-lg-none">
			<font-awesome-icon icon="low-vision" />
			Não é possível visualizar o conteúdo desta aplicação em telas pequenas.
		</div>
		<div id="container" class="d-none d-lg-block h-100 flex-column">
			<b-modal id="modal-confirm" centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				title="Confirmação"
				ok-title="Sim"
				cancel-title="Não"
				@ok="irHome"
				hide-header
			>
				<p class="my-4">Deseja realmente sair da sala?</p>
			</b-modal>
			<b-modal id="modal-confirm_finalizar" centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				title="Confirmação"
				ok-title="Sim"
				cancel-title="Não"
				@ok="finalizarEvento"
				hide-header
			>
				<p class="my-4">Deseja realmente finalizar a sala?<br>
					Esta ação desconectará todos os usuários, finalizará todas as gravações/transmissões ativas e não poderá ser desfeita!</p>
			</b-modal>
			<b-modal id="modal-confirm_finalizar_transmissao" centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				title="Confirmação"
				ok-title="Sim"
				cancel-title="Não"
				@ok="setLiveYoutube('finalizar', false)"
				hide-header
			>
				<p class="my-4">Deseja realmente encerrar a transmissão?<br>
					Esta ação ecerrará a transmissão do vídeo para o Youtube e não poderá ser desfeita!</p>
			</b-modal>
			<b-modal id="add_apresentador"
				centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				hide-header
				@ok="addUsuarios('apresentador')"
			>
				<AddUsuarioSala ref="add_usuarios_apresentador" tipo="apresentador" />
			</b-modal>
			<b-modal id="add_participante"
				centered
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-text-variant="light"
				hide-header
				@ok="addUsuarios('participante')"
			>
				<AddUsuarioSala ref="add_usuarios_participante" tipo="participante" />
			</b-modal>
			<b-modal size="md" id="modal-editar-sala" title="Editar" hide-footer>
				<SalaEditar :sala="formEditarSala" :tipos="tipos" />
			</b-modal>
			<b-modal size="md" id="modal-editar-youtube" title="Editar" hide-footer>
				<SalaYoutubeEditar :sala="formEditarSala" />
			</b-modal>
			<b-modal :size="modal_subsalas_size" id="modal-subSala" title="Sub-Salas" ok-only ok-title="Fechar">
				<SalaSubSalas :modal_subsalas_size.sync="modal_subsalas_size"/>
			</b-modal>
			<b-modal id="modal-configuracoes" centered ok-title="Fechar" ok-only size="lg">
				<template #modal-title>
					<font-awesome-icon icon="tools" /> Configurações
				</template>
				<SalaDispositivos :dispositivos="dispositivos_obj" />
            </b-modal>
			<div id="mensagens" v-if="this.mensagens_visible"></div>
			<SalasMenu :chat_visible="chat_visible" :chat_nao_lidas="chat_nao_lidas" />
			<div id="col_video" class="m-0 p-0">
				<b-row class="h-100 pl-0">
					<b-col>
						<b-row class="mt-0">
							<b-col>
								<SalaTitulo
									:moderadores="moderadores_nomes"
									:apresentadores="apresentadores_nomes"
									:participantes="participantes_nomes"
									tipo="M"
								/>
								<JitsiMeet
									tipo="moderador"
									ref="jitsiRef"
									:loading_painel.sync="loading_painel"
									:domain="$store.state.sala.servico_jitsi"
									:token_sala="token_sala"
								/>
							</b-col>
						</b-row>
						<b-row class="flex-fill d-flex justify-content-start">
							<b-col class="content-window p-3 text-center">
								<b-overlay :show="loading_painel" rounded="sm" variant="dark">
									<div ref="botao_youtube" v-if="jitsi_gravando && !youtube_stats.transmitindo" class="botao youtube" @click="setLiveYoutube()">
										<svg style="max-height: 18px" version="1.1" viewBox="0 0 156 110" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
											<metadata>
												<rdf:RDF><cc:Work rdf:about=""><dc:format>image/svg+xml</dc:format>
												<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title/></cc:Work></rdf:RDF></metadata>
												<path d="m77.922 0.40039s-48.701 0.10039-60.801 3.4004a19.6 19.6 0 0 0-13.801 13.799c-3.66 21.5-5.0784 54.26 0.10156 74.9a19.6 19.6 0 0 0 13.799 13.801c12.1 3.3 60.801 3.2988 60.801 3.2988s48.699 0.00117 60.799-3.2988a19.6 19.6 0 0 0 13.801-13.801c3.86-21.53 5.0504-54.27-0.09961-75a19.6 19.6 0 0 0-13.801-13.801c-12.1-3.3-60.799-3.2988-60.799-3.2988zm-20.121 31.199 40.398 23.4-40.398 23.4v-46.801z" fill="#fff"/>
										</svg>
										<br>
										<p>Iniciar Transmissão</p>
									</div>
									<div v-if="youtube_stats.transmitindo" class="botao youtube blinkable" @click="loading_painel ? console.log('botão desabilitado') : setLiveYoutube('finalizar')">
										<svg style="max-height: 18px" version="1.1" viewBox="0 0 156 110" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
											<metadata>
												<rdf:RDF><cc:Work rdf:about=""><dc:format>image/svg+xml</dc:format>
												<dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title/></cc:Work></rdf:RDF></metadata>
												<path d="m77.922 0.40039s-48.701 0.10039-60.801 3.4004a19.6 19.6 0 0 0-13.801 13.799c-3.66 21.5-5.0784 54.26 0.10156 74.9a19.6 19.6 0 0 0 13.799 13.801c12.1 3.3 60.801 3.2988 60.801 3.2988s48.699 0.00117 60.799-3.2988a19.6 19.6 0 0 0 13.801-13.801c3.86-21.53 5.0504-54.27-0.09961-75a19.6 19.6 0 0 0-13.801-13.801c-12.1-3.3-60.799-3.2988-60.799-3.2988zm-20.121 31.199 40.398 23.4-40.398 23.4v-46.801z" fill="#fff"/>
										</svg>
										<br>
										<p>Finalizar Transmissão</p>
									</div>
									<div v-if="sala.gravar || sala.broadcast" class="botao" v-bind:class="{botao:true, active:jitsi_gravando, blinkable:jitsi_gravando}" @click="jitsi_gravando ? pararGravacao() : iniciarGravacao()">
										<font-awesome-icon icon="video" /><br>
										<p v-if="!jitsi_gravando">Iniciar Gravação</p>
										<p v-else>Parar Gravação</p>
									</div>
									<div class="botao" @click="bloqueioMicrofones" v-if="!this.microfones_bloqueados">
										<font-awesome-icon icon="microphone-slash" /><br>
										<p>Bloquear Microfones</p>
									</div>
									<div class="botao active" @click="bloqueioMicrofones" v-if="this.microfones_bloqueados">
										<font-awesome-icon icon="microphone" /><br>
										<p>Liberar Microfones</p>
									</div>
	<!--								<div class="botao" @click="openCL">-->
	<!--									<font-awesome-icon icon="terminal" />-->
	<!--									<p>Jitsi CL</p>-->
	<!--								</div>-->
									<div class="botao" v-b-modal.modal-editar-sala @click="editarSala">
										<font-awesome-icon icon="edit" />
										<p>Editar Sala</p>
									</div>
									<!-- <div class="botao position-relative" @click="abrirSubsalas()">
										<font-awesome-icon icon="laptop-house" />
										<p>Sub-Salas</p>
										<div v-if="subsalas_ativas_segundos > 0"
											style="top: 0; margin-left: auto; margin-right: auto;"
											class="position-absolute badge badge-success"
											v-text="tempo_subsalas_ativas" />
									</div> -->
									<div class="botao" @click="jitsiCmd('toggleTileView')">
										<font-awesome-icon icon="th-large" />
										<p>Mosaico de Usuários</p>
									</div>
									<div v-if="!sala.finalizado" class="botao" @click="finalizarEventoConfirm()">
										<font-awesome-icon icon="check-circle" />
										<p>Finalizar</p>
									</div>
									<div class="botao" @click="togglePaineis()">
										<font-awesome-icon :icon="paineis ? 'chevron-down' : 'chevron-up'" />
										<p v-html="paineis ? 'Ocultar Painéis' : 'Expandir Painéis'" />
									</div>
<!--									<div v-if="$store.state.user.is_staff">-->
<!--										<div class="botao" @click="getDispositivos">-->
<!--											<font-awesome-icon icon="microchip" />-->
<!--											<p>Dispositivos</p>-->
<!--										</div>-->
<!--									</div>-->
								</b-overlay>
							</b-col>
						</b-row>
						<b-row ref="rowPaineis" class="flex-fill d-flex justify-content-start">
							<b-col class="content-window content-fill">
								<p class="titulo">
									Apresentadores
									<span
										class="float-right botao-add"
										v-b-tooltip.hover.top title="Adicionar Apresentador"
										v-b-modal.add_apresentador
									>
										<font-awesome-icon icon="plus" size="xs" />
									</span>
								</p>
								<div class="overflow-auto" style="height: calc(100% - 36px)">
									<ul>
										<li v-for="apresentador in this.$store.state.sala.apresentadores" :key="apresentador.id">
											<SalaModeradorUsuarios :usuario="apresentador" tipo="apresentadores" />
										</li>
									</ul>
								</div>
							</b-col>
							<b-col class="content-window content-fill">
								<p class="titulo">
									Participantes (Meet)
									<span
										class="float-right botao-add"
										v-b-tooltip.hover.top title="Adicionar Participante"
										v-b-modal.add_participante
									>
										<font-awesome-icon icon="plus" size="xs" />
									</span>
								</p>
								<div class="overflow-auto" style="height: calc(100% - 36px)">
									<ul>
										<li v-for="participante in this.$store.state.sala.participantes" :key="participante.id">
											<SalaModeradorUsuarios :usuario="participante" tipo="participantes" />
										</li>
									</ul>
								</div>
							</b-col>
<!--							<b-col v-if="this.$store.state.sala.criar_youtube && this.$store.state.sala.youtube_streamkey && this.$store.state.sala.broadcast"-->
							<b-col class="content-window content-fill">
								<p class="titulo">YouTube
									<b-button variant="dark" size="sm" class="float-right ml-1"
											v-show="this.$store.state.sala.broadcast"
											@click="show_overlay_youtube=true; getLiveYoutube()"
											style="font-size: .7rem; margin-top: -2px; color: darkgray">
										<font-awesome-icon icon="sync" /> Atualizar
									</b-button>
									<b-button variant="dark" size="sm" class="float-right ml-1"
											v-if="!is_alive"
											@click="force_import_youtube=!force_import_youtube"
											style="font-size: .7rem; margin-top: -2px; color: darkgray">
										<font-awesome-icon icon="key" /> Importar Chave
									</b-button>
									<b-button variant="dark" size="sm" class="float-right ml-1"
											v-show="!this.$store.state.sala.broadcast"
											@click="show_overlay_youtube=true; criaVinculoYoutube()"
											style="font-size: .7rem; margin-top: -2px; color: darkgray">
										<font-awesome-icon icon="asterisk" /> Nova Live
									</b-button>
									<b-button variant="dark" size="sm" class="float-right ml-1"
											v-show="this.$store.state.sala.broadcast"
											@click="deleteVinculoYoutube()"
											style="font-size: .7rem; margin-top: -2px; color: darkgray">
										<font-awesome-icon icon="trash" /> Deletar Live
									</b-button>
									<b-button variant="dark" size="sm" v-b-modal.modal-editar-youtube class="float-right ml-1"
											v-show="this.$store.state.sala.broadcast"
											style="font-size: .7rem; margin-top: -2px; color: darkgray">
										<font-awesome-icon icon="pen" /> Editar Live
									</b-button>
								</p>
								<div class="overflow-y-scroll" style="height: calc(100% - 36px); overflow-y: auto; overflow-x: hidden;">
									<div v-if="!this.$store.state.sala.broadcast || force_import_youtube" style="color: whitesmoke">
										<b-overlay variant="dark" :show="show_overlay_youtube" rounded="sm">
											<b-row>
												<b-col class="text-center">
													<h3>Importar Live do Youtube</h3>
													<b-form-group
														label="Informe a Chave ou URL"
														description="Exemplo: https://youtu.be/kAENJ8x9Juc"
														class="mt-4 ml-2 mr-2">
														<b-input-group>
															<b-input
																id="broadcast_manual"
																name="broadcast_manual"
																ref="broadcast_manual"
																v-model="broadcast_manual"
																autocomplete="false"
															/>
															<b-input-group-append>
																<b-button @click="enviaBroadcastManual">Importar</b-button>
															</b-input-group-append>
														</b-input-group>
													</b-form-group>
													<b-button class="mt-2" variant="dark" @click="force_import_youtube=false">Cancelar</b-button>
												</b-col>
											</b-row>
										</b-overlay>
									</div>
									<div v-if="this.$store.state.sala.broadcast && youtube_stats.broadcast && force_import_youtube === false">
										<b-overlay variant="dark" :show="show_overlay_youtube">
											<SalaYoutubeStatus :yt="youtube_stats" />
										</b-overlay>
									</div>
									<div v-else-if="this.$store.state.sala.broadcast && force_import_youtube === false">
										<b-overlay variant="dark" :show="show_overlay_youtube">
										<b-row>
											<b-col class="text-center mt-5 text-white">
												<p>O Youtube ainda não retornou os dados da transmissão, aguarde ou <a href="#"
													@click.prevent="show_overlay_youtube=true; getLiveYoutube()">refaça a requisição</a>.</p>
											</b-col>
										</b-row>
										</b-overlay>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="2" class="content-window">
						<p class="titulo">Chat Meet</p>
						<ChatModerador ref="chat_component" :moderador_is_on="moderador_is_on" :chat_visible="chat_visible" />
					</b-col>
<!--					<b-col cols="2" class="content-window">-->
<!--						<p class="titulo">Chat Youtube</p>-->
<!--					</b-col>-->
				</b-row>
			</div>
		</div>
	</fullscreen>
</template>

<script>
import JitsiMeet from "@/components/Salas/Jitsi/JitsiMeet";
import ChatModerador from "@/components/widgets/ChatModerador";
import Registros from "@/components/widgets/Registros";
import Recursos from "@/components/widgets/Recursos";
import SalaTitulo from "@/components/Salas/SalaTitulo";
import SalaModeradorUsuarios from "@/components/Salas/SalaModeradorUsuarios";
import AddUsuarioSala from "@/components/widgets/AddUsuarioSala";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import SalaYoutubeStatus from "@/components/Salas/SalaYoutubeStatus";
import SalasMenu from "@/components/Salas/SalasMenu";
import SalaEditar from "@/components/Salas/SalaEditar";
import SalaYoutubeEditar from "@/components/Salas/SalaYoutubeEditar";
import SalasSubSalaForm from "@/components/Salas/SalasSubSalaForm";
import SalaSubSalas from "@/components/Salas/SalaSubSalas";
import SalaDispositivos from "@/components/Salas/SalaDispositivos";
import {
	// DATA
	dados,
	// COMPUTED
	apresentadores_nomes,
	moderadores_nomes,
	participantes_nomes,
	token_sala,
	// MÉTODOS
	abrirConfiguracoes,
	abrirSubsalas,
	enviaBroadcastManual,
	getDispositivos,
	iniciarGravacao,
	pararGravacao,
	finalizarEventoConfirm,
	finalizarEvento,
	redirecionaProximoEvento,
	togglePaineis,
	verificarMudo,
	atualizaDadosSala,
	jitsiCmd,
	logout,
	addUsuarios,
	openCL,
	getComandos,
	editarSala,
	getInfosUsuarios,
	setLiveYoutube,
	getLiveYoutube,
	criaVinculoYoutube,
	deleteVinculoYoutube,
	bloqueioMicrofones,
	irHome,
	getStatusSala,
	toggleFullScreen,
	fullscreenChange,
	verificaPermissao,
	registraEntradaUsuario,
	registraSaidaUsuario,
	getAcessoSalaLength,
	showRecursos,
	showRegistros,
	getChatNaoLidas,
	getSala,
	iniciaLoopConsultaYoutube,
	pararLoopConsultaYoutube,
	iniciarSubsalas,
	finalizarSubsalas,
	tempo_subsalas_ativas,
} from "@/modulos/sala"

export default {
	components: {
		SalaEditar, SalaYoutubeEditar, SalaDispositivos, SalasMenu, SalaYoutubeStatus, AddUsuarioSala, SalaModeradorUsuarios, SalaTitulo, JitsiMeet, ChatModerador, Registros,
		DatePicker, Recursos, SalasSubSalaForm, SalaSubSalas},
	data() {
		return {
			formEditarSala: {
				nome: '',
				descricao: '',
				data_inicio: '',
				data_fim: '',
				tipo: '',
				gravar: '',
				privado: '',
				fixada: '',
				microfone_bloqueado: '',
			},
			broadcast_manual: null,
			is_alive:false,
			interval_youtube_status: null,
			force_import_youtube: false,
			loading_painel: false,
			jitsi_gravando: false,
			microfones_bloqueados: this.$store.state.sala.microfone_bloqueado,
			modal_subsalas_size: 'md',
			paineis: true,
			sala: this.$store.state.sala,
			sala_tipo: 'moderador',
			subsalas_ativas_segundos: 0,
			subsalas_interval: null,
			tipos: [
                {text: 'Selecione', value: null},
                {text: 'Aula', value: 3},
                {text: 'Conferência', value: 2},
                {text: 'Reunião', value: 1},
            ],
			youtube_stats: {
				stream: null,
				broadcast: null,
				infos: null,
				transmitindo: false,
				ultima_atualizacao: null
			},
			show_overlay_youtube: false,
			...dados
		}
	},
	watch: {
		sala(){
			if(this.$store.state.sala.finalizado){
				this.redirecionaProximoEvento()
			}
		},
		jitsi_gravando(){
			if(this.jitsi_gravando) this.loading_painel = false
		}
	},
	computed: {
		moderadores_nomes,
		apresentadores_nomes,
		participantes_nomes,
		tempo_subsalas_ativas,
		token_sala
	},
	methods: {
		abrirConfiguracoes,
		abrirSubsalas,
		enviaBroadcastManual,
		getDispositivos,
		iniciarGravacao,
		pararGravacao,
		finalizarEventoConfirm,
		finalizarEvento,
		redirecionaProximoEvento,
		togglePaineis,
		verificarMudo,
		atualizaDadosSala,
		jitsiCmd,
		logout,
		addUsuarios,
		openCL,
		getComandos,
		editarSala,
		getInfosUsuarios,
		setLiveYoutube,
		getLiveYoutube,
		criaVinculoYoutube,
		deleteVinculoYoutube,
		bloqueioMicrofones,
		irHome,
		getStatusSala,
		toggleFullScreen,
		fullscreenChange,
		verificaPermissao,
		registraEntradaUsuario,
		registraSaidaUsuario,
		getAcessoSalaLength,
		showRecursos,
		showRegistros,
		getChatNaoLidas,
		getSala,
		iniciaLoopConsultaYoutube,
		pararLoopConsultaYoutube,
		iniciarSubsalas,
		finalizarSubsalas,
	},
	beforeCreate() {
		if(!this.$store.getters.loggedIn){
			window.localStorage.setItem('redirect_sala', this.$route.params.slug)
			this.$store.dispatch('userLogout').then(() => {
				window.location.href = window.location.origin+'/login'
			})
		}else{
			window.localStorage.removeItem('redirect_sala')
		}
		if(!this.$store.state.sala) {
			window.localStorage.setItem('redirect_sala', this.$route.params.slug)
			window.location.href = window.location.origin
		}
	},
	created() {
		this.$session.clear();
		// this.websocketConnect()
		this.chat_visible = true
		if(!this.$store.state.sala) {
			this.getSala(() => {
				this.moderador_is_on = this.$store.state.sala.nivel_usuario === 'moderador';
				this.registraEntradaUsuario();
				this.getStatusSala()
				this.getAcessoSalaLength(false)
			})
		}else{
			this.moderador_is_on = this.$store.state.sala.nivel_usuario === 'moderador';
			this.registraEntradaUsuario();
			this.getStatusSala()
			this.getAcessoSalaLength(false)
		}
		this.$store.commit('updateContentActive', false);
		this.interval = setInterval(() => {
			this.getStatusSala()
			this.getAcessoSalaLength()
			this.getComandos()
			this.getChatNaoLidas()
			this.getInfosUsuarios()
		}, 5000);
		if(this.$store.state.sala.broadcast) {
			this.getLiveYoutube()
		}
	},
	beforeDestroy() {
		this.websocketDisconnect()
		this.$store.commit('updateSala', {})
		window.removeEventListener('beforeunload', this.registraSaidaUsuario)
		clearInterval(this.interval)
		clearInterval(this.interval_youtube_status)
		this.interval_youtube_status = null
		this.$root.$off('set_video')
		this.$root.$off('inicia_loop_consulta_youtube')
		this.$root.$off('iniciar_gravacao')
		this.$root.$off('iniciar_trasmissao')
		this.$root.$off('finalizar_trasmissao')
		this.$root.$off('fechar_sala')
		this.$root.$off('parar_gravacao')
		this.$root.$off('parar_loop_consulta_youtube')
		this.$root.$off('show_registros')
		this.$root.$off('show_recursos')
		this.$root.$off('toggle_fullscreen')
		this.$root.$off('abrir_configuracoes')
		this.$root.$off('atualizar_dispositivos')
		this.$root.$off('jitsi_mounted')
		this.$root.$off('jitsi_gravando')
		this.$root.$off('logout')
		this.$root.$off('sala_atualizada')
		this.$root.$off('iniciar_subsalas')
		this.$root.$off('finalizar_subsalas')
		this.$root.$off('get_live_youtube')
	},
	destroyed() {
		this.$store.commit('updateContentActive', true);
	},
	beforeRouteLeave(to, from, next) {
		this.registraSaidaUsuario()
		if(to.name === 'login') this.$store.dispatch('userLogout')
		next()
	},
	beforeMount() {
		window.addEventListener('beforeunload', this.registraSaidaUsuario)
	},
	mounted() {
		this.em_atividade = this.iniciouHorario && this.moderador_is_on
		this.is_mounted = true
		this.$root.$on('usuario_mudo', params=>{
			this.websocketSendMessage(
				{
					action: 'mute_usuario',
					status: params.mudo,
					usuario: params.usuario
				}
			)
		});
		this.$root.$on('set_video', jitsiId=>this.$refs.jitsiRef.setVideo(jitsiId))
		this.$root.$on('inicia_loop_consulta_youtube', ()=>this.iniciaLoopConsultaYoutube())
		this.$root.$on('iniciar_gravacao', ()=>this.$refs.jitsiRef.iniciarGravacao())
		this.$root.$on('iniciar_transmissao', ()=>this.setLiveYoutube('iniciar'))
		this.$root.$on('finalizar_transmissao', ()=>this.setLiveYoutube('finalizar'))
		this.$root.$on('fechar_sala', ()=>this.finalizarEventoConfirm())
		this.$root.$on('parar_gravacao', ()=>this.$refs.jitsiRef.pararGravacao())
		this.$root.$on('parar_loop_consulta_youtube', ()=>this.pararLoopConsultaYoutube())
		this.$root.$on('show_registros', ()=>this.showRegistros())
		this.$root.$on('show_recursos', ()=>this.showRecursos())
		this.$root.$on('toggle_fullscreen', ()=>this.toggleFullScreen())
		this.$root.$on('abrir_configuracoes', ()=>this.abrirConfiguracoes())
		this.$root.$on('atualizar_dispositivos', ()=>this.getDispositivos())
		this.$root.$on('jitsi_mounted', ()=>this.getDispositivos())
		this.$root.$on('jitsi_gravando', valor=>this.jitsi_gravando=valor)
		this.$root.$on('logout', ()=>this.logout())
		this.$root.$on('sala_atualizada', ()=>this.getSala())
		this.$root.$on('get_live_youtube', ()=>this.getLiveYoutube())
		this.$root.$on('cria_vinculo_youtube', ()=>this.criaVinculoYoutube())
		this.$root.$on('iniciar_subsalas', ()=>this.iniciarSubsalas())
		this.$root.$on('finalizar_subsalas', ()=>this.finalizarSubsalas())
		this.$root.$on('bv::modal-subSala::hide', ()=>this.modal_subsalas_size='md')
	}
}
</script>

<style scoped>
.select2-results {
    color: black;
}
#toolbar{
	position: absolute;
	z-index: 205;
	left: calc(50vw - 100px);
	bottom: 25px;
}
.blinkable{
  -webkit-animation: blinkable 1s infinite;  /* Safari 4+ */
  -moz-animation: blinkable 1s infinite;  /* Fx 5+ */
  -o-animation: blinkable 1s infinite;  /* Opera 12+ */
  animation: blinkable 1s infinite;  /* IE 10+, Fx 29+ */
}

@-webkit-keyframes blinkable {
  0%, 49% {
    background-color: #dc0000;
  }
  50%, 100% {
    background-color: #330000;
  }
}

body {
	overflow: hidden !important;
	height: 100vh !important;
	color: whitesmoke;
}

.flex-fill{
	flex: 1;
}

.content-fill{
	height: calc(100vh - 300px - 60px - 8px);
}

.content-window{
	margin: 0 2px;
	padding: 0;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, .5);
}

.content-window p.titulo{
	border-radius: 2px 2px 0 0;
	background-color: rgba(0, 0, 0, .2);
	color: whitesmoke;
	padding: 5px 10px;
	font-weight: 300;
	text-transform: capitalize;
	margin-bottom: 5px;
}

.botao-add{
	cursor: pointer;
}

#nao_visivel_tela{
	width: 100vw;
	height: 100vh;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

#container{
	height: 100vh;
	width: 100vw;
	position: absolute;
}

#col_video{
	position: absolute;
	top: 0;
	left: 50px;
	right: 0;
	bottom: 0;
}

.botao{
	cursor: pointer;
	color: silver;
	text-align: center;
	display: inline-block;
	margin: 2px 5px;
	padding: 6px;
	border-radius: 4px;
	height: 50px;
	width: 125px;
	user-select: none;
}

.botao.youtube{
	/*background-color: rgba(255, 0, 0, .6);*/
}

.botao.active{
	color: whitesmoke;
	background-color: rgba(0, 0, 0, .3);
}

.botao > p{
	font-size: 11px;
	margin: 0;
}

.botao:hover{
	color: whitesmoke;
	background-color: rgba(0, 0, 0, .3);
}

.botao.youtube.active{
	color: black;
	background-color: rgba(255, 0, 0, 1);
}

.botao.youtube:hover{
	color: whitesmoke;
	background-color: rgba(255, 0, 0, .8);
}

ul{
	margin: 0;
	padding: 0;
	list-style-type: none;
}
li{
	text-align: center;
	padding: 10px 5px;
	position: relative;
}

#chat{
	position: absolute;
	z-index: 2000;
	background-color: rgba(10, 10, 10, .3);
	border-radius: 4px;
}

.badge-danger{
	position: absolute;
	border-radius: 7px;
	font-size: 10px;
	width: 14px;
	height: 14px;
}

#carregando{
	position: absolute;
	width: 600px;
	height: 400px;
	background-color: #474747;
	left: calc(50vw - 300px);
	top: calc(50vh - 200px);
	color: whitesmoke;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.col{
	padding: 0 !important;
}

.row{
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.row > .col > .row{
	margin-top: 3px;
}
</style>
<style>
.slide-leave-active,
.slide-enter-active {
	transition: all .5s;
}

.slide-enter, .slide-leave-to {
	transform: translateX(500px);
}

.slide-leave-active {
	transition: all .5s;
}

.fullscreen {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}

#col_chat {
	background-color: #474747;
	font-size: small;
	position: relative;
}

#col_chat_header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	max-height: 105px;
}

#col_chat_content {
	position: absolute;
	top: 105px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

#side_modal {
	position: absolute;
	top: 2px;
	right: 0;
	height: calc(100vh - 4px);
	background-color: white;
	border-radius: 10px 0 0 10px;
	z-index: 210;
	width: 500px;
}

.infos_evento {
	margin-top: 15px;
	color: whitesmoke;
	text-align: center;
}

.ilustracao {
	color: whitesmoke;
	height: 300px;
	margin: calc(50vh - 150px) auto;
	text-align: center;
}

.title_jitsi {
	position: absolute;
	top: 3px;
	left: 20px;
	color: white;
}

.loading:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 900ms infinite;
	animation: ellipsis steps(4, end) 1500ms infinite;
	content: "\2026";
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 15px;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 15px;
	}
}
</style>