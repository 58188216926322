<template>
	<div class="content_body vh-100 overflow-hidden">
		<b-row>
			<b-col cols="2">
				<img src="@/assets/logoV2_semfundo.svg" width="50px">
			</b-col>
			<b-col class="text-center">
				<b-dropdown class="mt-2" right variant="light"
					menu-class="w-100 text-center"
					:text="salas_a_listar.charAt(0).toUpperCase()+salas_a_listar.slice(1)">
					<b-dropdown-item @click="salas_a_listar='fixadas'">Fixadas</b-dropdown-item>
					<b-dropdown-item @click="salas_a_listar='ativas'">Ativas</b-dropdown-item>
					<b-dropdown-item @click="salas_a_listar='programadas'">Programadas</b-dropdown-item>
					<b-dropdown-item @click="salas_a_listar='finalizadas'">Finalizadas</b-dropdown-item>
				</b-dropdown>
			</b-col>
<!--			<b-col cols="2">-->
<!--				<b-button pill size="sm" variant="outline-success mt-2">-->
<!--					<font-awesome-icon icon="plus" />-->
<!--				</b-button>-->
<!--			</b-col>-->
		</b-row>
		<b-overlay :show="loading" rounded="sm" class="w-100 vh-100">
			<b-list-group class="mt-2 border-top w-100 overflow-y-scroll" style="height: calc(100% - 150px); overflow-x: hidden" ref="lista">
				<b-list-group-item v-for="sala in salas" :key="sala.id" class="border-0 bg-transparent">
					<b-button pill variant="light" class="bg-white w-100 shadow-sm font-weight-lighter" @click="entrarSala(sala)">
						{{ sala.nome }}<br>
						<small class="text-muted">
							{{ new Date(sala.data_inicio).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }} a {{ new Date(sala.data_fim).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }}
						</small>
						<b-avatar v-if="sala.em_atividade" variant="success" class="float-right" text=" " size="1rem" style="margin-top:-.3rem;"></b-avatar>
					</b-button>
				</b-list-group-item>
				<div v-if="salas.length === 0" class="mt-5 pt-5 text-center" style="color: silver">
					<font-awesome-icon size="4x" icon="folder-open" /><br>
					Nenhuma Sala
				</div>
			</b-list-group>
			<p v-if="paginas.total > 1" class="text-center mt-2">
				<b-button-group>
					<b-button :disabled="paginas.atual === 1" variant="light" size="sm" pill
						@click="irPagina('anterior')"
					>
						<font-awesome-icon icon="chevron-left" />
					</b-button>
					<b-button v-for="pag in paginas.total" :key="pag" @click="irPagina(pag)" pill
						:disabled="paginas.atual === pag" :pressed="paginas.atual === pag"
						:variant="paginas.atual === pag ? 'outline-secondary' : 'light'" size="sm">
						{{ pag }}
					</b-button>
					<b-button :disabled="paginas.atual === paginas.total" variant="light" size="sm" pill
						@click="irPagina('proximo')"
					>
						<font-awesome-icon icon="chevron-right" />
					</b-button>
				</b-button-group>
			</p>
		</b-overlay>
		<b-modal id="modal_acessar_sala" ref="modal_acessar_sala" class="text-center"
			centered hide-footer hide-header no-close-on-esc no-close-on-backdrop>
            <SalaAcessar v-if="sala_acessar" :sala="sala_acessar" />
        </b-modal>
	</div>
</template>

<script>
import SalaAcessar from "@/components/Salas/SalaAcessar";
import {check_version} from '@/modulos/utils'
export default {
    components: {SalaAcessar},
    data() {
        return {
			salas_fixadas_head_size: 0,
			salas_ativas_head_size: 0,
			salas_programadas_head_size: 0,
			salas_finalizadas_head_size: 0,
			salas_a_listar: 'ativas',
            interval: null,
			sala_acessar: null,
			listar_todas: false,
			salas: [],
			paginas: {
				total: 1,
				atual: 1
			},
			limite: {
				min: 0,
				max: 10,
				padrao: 10,
			},
			loading: true
        }
    },
	watch:{
		listar_todas(){
			this.$root.$emit('valor_listar_todas', this.listar_todas)
			this.getSalas(this.salas_a_listar)
		},
		salas_a_listar(){
			this.loading = true
			this.getSalas(this.salas_a_listar)
			this.limite = {
				min: 0,
				max: 10,
				padrao: 10,
			}
			this.paginas = {
				total: 1,
				atual: 1
			}
		}
	},
    methods: {
		check_version,
		entrarSala(sala) {
			this.sala_acessar = sala
			this.$refs['modal_acessar_sala'].show()
        },
		irPagina(pagina){
			this.loading = true
			this.$refs.lista.scrollTop = 0;
			if(['anterior', 'proximo'].includes(pagina)){
				pagina = pagina === 'anterior' ? this.paginas.atual - 1 : this.paginas.atual + 1
			}
			this.limite.max = pagina * this.limite.padrao
			this.limite.min = this.limite.max - this.limite.padrao
			this.paginas.atual = pagina
			this.getSalas(this.salas_a_listar)
		},
        getFilterSalas(tipo){
            let agora = new Date().toISOString();
            let staff = this.listar_todas ? '&staff=true' : ''
            if(tipo === 'ativas'){
                return `data_inicio__lte=${agora}&data_fim__gte=${agora}&finalizado=false${staff}&limit=${this.limite.min},${this.limite.max}${staff}`
            }else if(tipo === 'programadas'){
                return `data_inicio__gt=${agora}${staff}&limit=${this.limite.min},${this.limite.max}${staff}`
            }else if(tipo === 'finalizadas'){
                return `data_fim__lt=${agora}&finalizado=true&limit=${this.limite.min},${this.limite.max}${staff}`
            }else if(tipo === 'fixadas'){
                return `fixadas=true${staff}&limit=${this.limite.min},${this.limite.max}${staff}`
            }
        },
        getSalas(tipo=null) {
			tipo = tipo === null ? 'ativas' : tipo
			this.$http.get('/salas/?' + this.getFilterSalas(tipo)).then(response=>{
				this.loading = false
				this.salas = response.data.data
				if(response.data.count > this.limite.padrao){
					let pags = response.data.count / this.limite.padrao
					this.paginas.total = pags === 0 ? 1 : pags % 1 === 0 ? pags : parseInt(pags, 10) + 1
				}
			})
        },
        getUserInfo() {
            this.$http.get('/current-user/').then(response=>this.participante.usuario = response['data']['id'])
        }
    },
    created() {
        let redirect = window.localStorage.getItem('redirect_sala')
        if(redirect && redirect.length > 0){
            window.localStorage.removeItem('redirect_sala')
            this.$http.get('/salas/?slug='+redirect).then(response => {
				let sala = response.data.data[0]
				this.$store.commit('updateSala', sala)
				this.sala_acessar = sala
				this.$refs['modal_acessar_sala'].show()
            });
        }
		this.$session.clear();
        this.loading = true
        this.getSalas(this.salas_a_listar);
        this.interval = setInterval(()=>this.getSalas(this.salas_a_listar), 10000);
    },
	mounted() {
		this.check_version()
		this.$root.$on('atualizar_salas', tipo=>this.getSalas(tipo))
		this.salas_a_listar = 'ativas'
	},
	beforeDestroy: function(){
		this.$root.$off('atualizar_salas')
        clearInterval(this.interval)
    }
}
</script>

<style scoped>
.content_body{
	margin: 15px;
}
</style>

<style>
h3{
    font-weight: 100;
	cursor: pointer;
}
.badge-light {
    color: #252729;
    background-color: #f8f9fa;
    font-weight: lighter;
}
</style>