<template>
	<div>
		<div class="container">
			<b-row :class="{'login_panel': !is_mobile}">
				<b-col sm="0" md="5" class="imagem" :style="style_imagem">
					<div class="absolute-bottom" v-if="!is_mobile">
						<span class="centered"><img src="@/assets/brasao.png" width="30px" height="30px"> Defensoria Pública do Estado do Tocantins</span>
					</div>
				</b-col>
				<b-col sm="12" md="7" :class="{'formulario': !is_mobile}">
					<b-form class="form-signin">
						<div class="text-center">
							<img src="@/assets/logoV2_semfundo.svg" width="160" class="ml-4">
							<br>
							<h1 class="h3 mt-4 font-weight-light">Bem-Vindo ao Meet!</h1>
							<p v-if="!texto_erro || texto_erro === ' '">Informe seu usuário e senha para acessar.</p>
							<p v-if="texto_erro" class="text-danger" v-html="texto_erro" @click="texto_erro=' '" />
						</div>
						<div class="mt-4 mb-4">
							<b-overlay :show="loading" rounded="sm">
								<b-form-input
									id="usuario"
									ref="usuario"
									v-model="payload.username"
									required
									placeholder="Usuário"
									v-on:keyup.enter="$event.target.nextElementSibling.focus()"
									@input="v => { payload.username = v.toLowerCase()}"
									style="border-radius: 3px 3px 0 0"
								/>
								<b-form-input
									id="password"
									v-model="payload.password"
									placeholder="Senha"
									required
									type="password"
									v-on:keyup.enter="clickSubmit"
									aria-describedby="password-help-block"
									style="border-radius: 0 0 3px 3px; border-top: none;"
									class="mb-2"
								/>
								<b-button id="btn_submit" @click="onSubmit($event)" type="button" block variant="dark" :disabled="loading">Entrar</b-button>
							</b-overlay>
						</div>
					</b-form>
				</b-col>
			</b-row>

			<b-row v-if="is_mobile">
				<b-col>
					<hr>
					<p class="text-center text-muted">
						<img src="@/assets/brasao.png" width="30" height="30"> Defensoria Pública do Estado do Tocantins
					</p>
				</b-col>
			</b-row>
		</div>

		<div class="absolute-bottom">
			<span>Fotos de <a href="https://unsplash.com/@krakenimages?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">krakenimages</a> e <a href="https://unsplash.com/@cwmonty?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">Chris Montgomery</a> em <a href="https://unsplash.com/" target="_blank">Unsplash</a>.</span>
		</div>
	</div>
</template>

<script>
import {auth} from "@/plugins/axios";
import { isMobile } from 'mobile-device-detect';
import {dados, check_version} from "@/modulos/utils"

export default {
	Components: {isMobile},
	data() {
		return {
			payload: {
				grant_type: 'password',
				username: '',
				password: ''
			},
			user: {
				email: '',
				first_name: '',
				last_name: '',
				username: '',
				cpf: null,
				last_login: new Date,
				date_joined: new Date,
				password: '',
        lotacoes: []
			},
			texto_erro: null,
            loading: false,
			...dados
		}
	},
	computed:{
		style_imagem(){
			return {
				backgroundImage: 'url("'+require("@/assets/meeting2.jpg")+'")',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				position: 'relative'
			}
		},
		is_mobile(){
			return isMobile
		}
	},
	methods: {
        clickSubmit(){
            document.getElementById('btn_submit').click();
        },
		login(dados) {
			this.$store.dispatch('userLogin', dados.tokens)
				.then(() => {
					let avatar = dados.usuario['avatar'];
					if(avatar){
						avatar = 'https://static.defensoria.to.def.br/meet-media'+avatar.split("media")[1];
					}
					this.$store.commit('setUser', {
						id: dados.usuario['id'],
						username: dados.usuario['username'],
						nome: dados.usuario['first_name']+' '+dados.usuario['last_name'],
						first_name: dados.usuario['first_name'],
						last_name: dados.usuario['last_name'],
						avatar: avatar,
						email: dados.usuario['email'],
						cpf: dados.usuario['cpf'],
						is_staff: dados.usuario['is_staff'],
						microfone: dados.usuario['microfone'],
						video: dados.usuario['video'],
            lotacoes: dados.usuario['lotacoes']
					});
					if(this.$router.path !== '/') this.$router.push({name: 'home'});
				})
				.catch(erro => {
					console.log(erro)
					this.texto_erro = 'Erro ao registrar o acesso na aplicação.<br>Tente novamente.'
				})
		},
		onSubmit(e) {
			e.preventDefault()
			if(!this.payload.username){
				document.getElementById('usuario').reportValidity()
				return
			}
			if(!this.payload.password){
				document.getElementById('password').reportValidity()
				return
			}

			this.texto_erro = null
            this.loading = true

			setTimeout(()=>{
				this.loading = false
				if(this.texto_erro === null){
					this.texto_erro = 'O servidor está demorando para responder. Tente novamente mais tarde.'
				}
			}, 10000)

			auth.post('/api/users/', this.payload)
				.then(resp => {
					if(resp.status === 200){
						this.login(resp.data)
					}else{
						this.texto_erro = resp.data
					}
					this.loading = false
				})
				.catch(erro => {
					this.texto_erro = erro.response.data
					console.log(erro.response)
					this.loading = false
				})
		},
		check_version
	},
    created() {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
		// this.check_version()
    },
	mounted() {
		this.$refs.usuario.$el.focus();
	}
}
</script>

<style scoped>
.body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f5f5f5;
}

.login_panel{
	padding-top: calc(50vh - 300px);
}

.login_panel .imagem{
	border-radius: 30px 0 0 30px;
	background-color: white;
	box-shadow: 0 0 6px silver;
}

.login_panel .formulario{
	border-radius: 0 30px 30px 0 ;
	background-color: white;
	box-shadow: 0 0 6px silver;
	clip-path: inset(-8px -8px -8px 0);
}

.absolute-bottom{
	position: absolute;
	bottom: 3px;
	width: 100%;
	color: gray;
	font-size: 10px;
	text-align: center;
}

.absolute-bottom .centered{
	background-color: rgba(255, 255, 255, .7);
	border-radius: 5px 5px 0 0;
	padding: 14px 15px;
	color: black;
}

.absolute-bottom span a{
	color: inherit !important;
}

.form-signin {
	width: 100%;
	max-width: 420px;
	padding: 15px;
	margin: auto;
}

</style>