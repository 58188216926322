import {auth} from "@/plugins/axios";

export var dados = {
    versao: null
}

export function check_version(){
    auth.get('/versao/').then(resp=> {
        let versao = resp.data.id
        let stored_version = this.$store.state.versao
        let cookie = this.$cookies.get('defmeet_versao')
        if(!stored_version) stored_version = cookie
        if(!stored_version || stored_version !== versao) {
            this.$store.dispatch('updateVersao', versao).then(()=>{
                if(stored_version) {
                    window.location.reload(true)
                }
            })
        }else if(!cookie) {
            this.$cookies.set('defmeet_versao', versao)
        }
    })
}