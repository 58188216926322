<template>
	<div class="row pt-2 pb-3 content_header">
		<div class="col-4">
			<div class="float-left">
				<img height="62px" class="mr-4" src="@/assets/brasao.png"/>
			</div>
			<div class="float-left h5 pl-1 pt-1" style="font-weight: lighter; border-left: 2px solid #65AC89; height: 62px; margin: 0;" v-if="!is_mobile">
				<span style="font-size: .9rem">{{ data }}</span><br>
				{{ hora }}
			</div>
		</div>
		<div class="col-8 text-right pt-3">
			<!-- MENU PARA TELAS MÉDIAS E ACIMA -->
<!--			<b-button v-if="this.$route.name !== 'home'" :to="{ name: 'home' }" class="ml-2 d-none d-md-inline-flex" variant="success">-->
<!--				Página Inicial-->
<!--			</b-button>-->
<!--			<b-button v-if="this.$route.name !== 'criar-sala'" :to="{ name: 'criar-sala' }" class="ml-2 d-none d-md-inline-flex" variant="info">-->
<!--				Criar Sala-->
<!--			</b-button>-->
			<b-button
				v-if="!is_mobile && $store.state.user.is_staff"
				variant="light" size="sm" @click="$root.$emit('atualizar_salas')">
				<font-awesome-icon icon="sync" />
				Atualizar Salas
			</b-button>
			<b-button
				v-if="!is_mobile && $store.state.user.is_staff"
				variant="light" size="sm" @click="$root.$emit('toggle_staff')">
				<font-awesome-icon :icon="this.listar_todas ? 'check-circle' : 'times-circle'" />
				Listar Todas
			</b-button>
			<b-dropdown class="ml-2 d-none d-md-inline-flex" id="dropdown-user" no-caret right
				variant="link" toggle-class="text-decoration-none m-0 p-0">
				<template v-slot:button-content>
<!--					<font-awesome-icon icon="user" size="sm"/>-->
<!--					{{ primeiro_nome }}-->
					<b-avatar variant="info" :src="user_avatar" :text="(!user_avatar) ? iniciais : null" />
				</template>
				<b-dropdown-item-button class="text-center" @click="$refs.imagem_avatar.click()">
					<input type="file" ref="imagem_avatar" id="imagem_avatar" style="display: none"
						@change="uploadAvatar($event)">
					<font-awesome-icon class="mr-2" icon="camera" size="sm"/>
					Trocar Foto
				</b-dropdown-item-button>
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item :to="{name: 'envia-token'}">
					<font-awesome-icon class="mr-2" icon="share" size="sm"/>
					Enviar Convite
				</b-dropdown-item>
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item @click="logout">
					<font-awesome-icon class="mr-2" icon="sign-out-alt" size="sm"/>
					Sair
				</b-dropdown-item>
			</b-dropdown>
			<!-- MENU PARA TELAS PEQUENAS E ABAIXO -->
			<b-button v-if="this.$route.name !== 'home'" :to="{ name: 'home' }" class="ml-2 d-sm-inline-flex d-md-none" variant="success">
				<font-awesome-icon icon="home" class="mr-2" size="sm"/>
			</b-button>
			<b-dropdown class="ml-2 d-sm-inline-flex d-md-none" id="dropdown-user" no-caret right
				variant="outline-secondary">
				<template v-slot:button-content>
					<font-awesome-icon icon="user" size="sm"/>
					{{ primeiro_nome }}
				</template>
				<b-dropdown-item v-if="this.$route.name !== 'criar-sala'" :to="{ name: 'criar-sala' }">
					<font-awesome-icon class="mr-2" icon="plus" size="sm"/>
					Criar Sala
				</b-dropdown-item>
				<b-dropdown-item v-if="$store.state.user.is_staff" @click="$root.$emit('toggle_staff')">
					<font-awesome-icon class="mr-2" :icon="this.listar_todas ? 'check-circle' : 'times-circle'" />
					Listar Todas
				</b-dropdown-item>
				<b-dropdown-item :to="{name: 'envia-token'}">
					<font-awesome-icon class="mr-2" icon="share" size="sm"/>
					Enviar Convite
				</b-dropdown-item>
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item @click="logout">
					<font-awesome-icon class="mr-2" icon="sign-out-alt" size="sm"/>
					Sair
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
	Components: {isMobile},
	data() {
		return {
			user_avatar: require('@/assets/user-avatar.png'),
			intervalo: null,
			listar_todas: false,
			interval: null,
			data: '',
			hora: ''
		}
	},
	computed:{
		primeiro_nome(){
			return this.$store.state.user.first_name
		},
		iniciais(){
			return this.$store.state.user.first_name[0] + this.$store.state.user.last_name[0]
		},
		is_mobile(){
			return isMobile
		}
	},
	methods: {
		zeroPadding(num){
			return num < 10 ? '0'+num : num
		},
		getTime(){
			let agora = new Date();
			let dia = this.zeroPadding(agora.getDate())
			let mes = agora.toLocaleString("pt-br", {month: "long"})
			let ano = agora.getFullYear()
			let semana = agora.toLocaleString("pt-br", {weekday: "long"})
			let hora = this.zeroPadding(agora.getHours())
			let	minuto = this.zeroPadding(agora.getMinutes())
			let segundos = this.zeroPadding(agora.getSeconds())
			this.data = `${semana}, ${dia} de ${mes} de ${ano}`
			this.hora = `${hora}:${minuto}:${segundos}`
		},
		logout() {
			this.$store.dispatch('userLogout')
			this.$router.push({name: "login"})
		},
		uploadAvatar(e) {
			let data = new FormData();
			data.append('avatar', e.target.files[0]);
			data.append('username', this.$store.state.user.username);
			let config = {header: {'Content-Type': 'image/png'}}
			this.$http.put(`users/${this.$store.state.user.id}/`, data, config)
				.then(
					response => {
						this.$toast.open({
							message: 'Sua foto foi atualizada com sucesso!',
							type: 'success',
							duration: 5000,
							position: 'top-right'
						});
						this.$store.commit('setUser', response.data)
						this.user_avatar = response.data['avatar']
						this.$refs.imagem_avatar.value = null;
						// setTimeout(()=>{window.location.reload();}, 6000);
					}, () => {
						this.$toast.open({
							message: 'Ocorreu um erro ao enviar sua foto!',
							type: 'error',
							duration: 5000,
							position: 'top-right'
						})
					}
				)
		},
	},
	created() {
		setTimeout(() => {
			if(!this.$store.getters.loggedIn){
				this.$store.dispatch('userLogout').then(() => this.$router.push({name:'login'}))
			}
			if (this.$store.state.user) {
				this.user_avatar = this.$store.state.user.avatar
			}
		}, 1000)
	},
	mounted() {
		this.interval = setInterval(()=>{
			this.getTime()
		}, 1000)
		this.$root.$on('valor_listar_todas', valor => this.listar_todas=valor)
	},
	beforeDestroy() {
		clearInterval(this.interval)
		this.interval = null
		this.$root.$off('valor_listar_todas')
	}
}
</script>

<style scoped>
.content_header{
	margin: 5px 15px;
}
#user_avatar {
	background-color: silver;
	height: 56px;
}
</style>