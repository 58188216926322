<template>
  <div class="body">
    <b-form class="form-signin" @submit="onSubmit">
      <div class="text-center">
      <img src="@/assets/brasao.png" verical-align="center" alt="Center image" width="100" height="100">
      <br>
      <br>
      <h1 class="h3 mb-3 font-weight-normal">Registre-se</h1>
      <h1 class="h6 mb-3 font-weight-normal">Apenas para usuários externos</h1>
    </div>
    <b-form-group
        id="input-group-1"
        label="Nome Completo"
        label-for="nome"
      >
        <b-form-input
          id="nome"
          v-model="ExternalUser.nome"
          required
          placeholder="Nome Completo"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-1"
        label="Email"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="ExternalUser.email"
          type="email"
          required
          placeholder="Email"
        ></b-form-input>
      </b-form-group>

    <b-form-group
    id="input-group-1"
    label="CPF (Apenas os números)"
    label-for="cpf"
    >
    <b-form-input
        id="cpf"
        v-model="ExternalUser.cpf"
        required
        placeholder="CPF"
    ></b-form-input>
    </b-form-group>

  <br>
  <br>
  <b-button @submit="onSubmit" class="" type="submit" block variant="primary">Registrar</b-button>
  <div class="text-center">
    <p class="mt-5 mb-3 text-muted">© 2020</p>
  </div>


</b-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ExternalUser: {
          nome: '',
          email: '',
          cpf: '',
        },

      }
    },
    methods: {
      onSubmit(e) {
          e.preventDefault();
          let cpfOnlyNumbers = this.ExternalUser.cpf.replace(/[^\d]+/g,'')
          this.ExternalUser.cpf = cpfOnlyNumbers
          this.$http.post('/external_user/', this.ExternalUser)
          .then(() => {
            this.$toast.open({
                message: 'Cadastro realizado com sucesso!, enviamos um email com suas credenciais de acesso.',
                type: 'success',
                duration: 10000,
                position: 'top-right'
            });
            this.$router.push({name: 'login'})   
            
          })
           
      },
        
    },

    created(){
      console.log(this.$route.params.token)
      if(!this.$route.params.token){
        // console.log("opa, não tenho token")
      }else{
        // console.log("opa, tenho token")
        this.$http.get(`/tokens/?numero=${this.$route.params.token}`)
        .then(response => {
          this.ExternalUser.email = response.data[0].email_convidado
        })
      }
      
    }
  }
</script>

<style scoped>
  .body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }

  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
  }
  /* @import url('../assets/sign-in.css'); */
</style>