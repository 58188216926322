<template>
	<div>
    <b-list-group v-if="participantes !== null && participantes.length > 0" class="mt-1">
        <b-list-group-item v-for="(p, k) in participantes" :key="k" class='clearfix'>
            <div class="float-left mr-1 mt-1">
                <img v-if="p.origem !== 'email'" :src="getAvatar(p)" class="rounded-circle" height="22px">
                <span v-else>
                    <small class="text-muted">Convite: </small>
                    <small style="color:black">
                        {{ p.email }}
                    </small>
                </span>
            </div>
            <div v-if="p.origem !== 'email'" class="float-left mt-1 nome_participante">{{ p.nome }}</div>
            <div class="float-right">
                <b-button variant="light" size="sm" class="btn-sm" title="Remover" @click="deleteItem(k)">
                    <font-awesome-icon icon="trash" size="sm" />
                </b-button>
            </div>
        </b-list-group-item>
    </b-list-group><br />
	</div>
</template>

<script>
export default {
    props: [
        'participantes', 'tipo'
    ],
    methods:{
        getAvatar(p){
			return p.avatar ? 'https://static.defensoria.to.def.br/meet-media/'+p.avatar : require('@/assets/user-avatar.png')
        },
        deleteItem(pos){
			this.$root.$emit('delete-usuario', {pos:pos, tipo:this.tipo})
        }
    }
}
</script>

<style scoped>
.list-group-item{
	border: none !important;
}
.nome_participante{
    width: calc(100% - 56px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	color: #0f0f16;
}
</style>
