<template>
    <b-col style="height: 100%" :class="{mobile_content: is_mobile}">
        <b-row :class="{ibox: true, ibox_mobile: is_mobile}">
            <b-col>
                <h2 class="mt-3 mb-3 border-bottom">Criar Sala</h2>
                <b-alert :key="message" dismissible fade show v-for="message in messages" variant="success">
                    {{ message }}
                </b-alert>
                <b-form @reset="onReset" @submit="onSubmit" v-if="show">
					<b-form-group id="input-group-1">
						<b-form-select
							class="col-sm-3 float-left"
							:options="tipos"
							id="input-tipo"
							required
							v-model="form.tipo"
							size="lg"
							style="border-radius: .25rem 0 0 .25rem;"
						/>
						<b-form-input
							:maxlength="maxLengthNome"
							class="col-sm-9 float-left"
							id="input-1"
							placeholder="Nome da Sala"
							required
							type="text"
							size="lg"
							v-model="form.nome"
							style="border-radius: 0 .25rem .25rem 0; border-left: none"
						/>
					</b-form-group>
					<b-form-group id="input-group-2">
						<b-form-textarea
								id="input-2"
								placeholder="Descrição da Sala"
								required
								v-model="form.descricao"
						/>
					</b-form-group>

					<b-row class="mb-3">
						<b-col sm="12" md="6">
							<label class="mr-1" style="max-width: 100px">Início do Evento </label>
							<date-picker :input-attr="{required: 'true'}" input-class="form-control" format="DD/MM/YYYY HH:mm" :show-second="false"  v-model="form.data_inicio" type="datetime"
							style="width: calc(100% - 104px)"/>
						</b-col>

						<b-col md="6" sm="12">
							<label class="mr-1" style="max-width: 120px">Término do Evento </label>
							<date-picker :input-attr="{required: 'true'}" input-class="form-control" format="DD/MM/YYYY HH:mm" :show-second="false"  v-model="form.data_fim" type="datetime"
							style="width: calc(100% - 122px)"/>
						</b-col>
					</b-row>

					<b-row>
						<b-col md="4" sm="12">
							<b-form-group label-for="input-privado">
								<b-form-checkbox aria-describedby="privadoHelpInline" id="input-privado" name="check-button" switch v-model="form.privado">
									Evento Privado
									<span v-b-tooltip.hover title="Salas privadas só permitem acesso de Participantes e Apresentadores previamente definidos."
										style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</b-form-checkbox>
							</b-form-group>
						</b-col>

						<b-col md="4" sm="6">
							<b-form-group label-for="input-gravar">
								<b-form-checkbox id="input-gravar" switch v-model="gravar_evento" class="float-left">
									Gravar Evento
									<span v-b-tooltip.hover title="O evento é gravado localmente (disponibilização de arquivo) ou no Youtube." style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</b-form-checkbox>
							</b-form-group>
						</b-col>

						<b-col md="4" sm="6">
							<b-form-group label-for="input-youtube">
								<b-form-checkbox :disabled="!form.gravar"  id="input-youtube" switch v-model="criar_youtube" class="float-left"/>
								Youtube
								<span v-b-tooltip.hover title="Gravação e Transmissão através do Youtube (necessário habilitar gravação)" style="color: #6c757d">
									<font-awesome-icon icon="question-circle"/>
								</span>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md="4" sm="12">
							<b-form-group label-for="input-redirecionar">
								<b-form-checkbox id="input-redirecionar" name="check-button" switch v-model="redirecionar">
									Redirecionar ao Finalizar
									<span v-b-tooltip.hover title="Os Apresentadores e Participantes são automaticamente redirecionados para outra sala quando a sala atual é finalizada pelo moderador."
										style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</b-form-checkbox>
							</b-form-group>
						</b-col>
						<b-col md="8" sm="12" class="position-relative">
							<Select2 id="input-redirecionar_sala" name="input-redirecionar_sala" v-if="redirecionar"
								:settings="s2GetSettings('Selecione a Sala para redirecionar', url_select2_salas)"
								@select="s2SelectSala($event)"
							/>
							<div id="sala_redirecionar" ref="sala_redirecionar">
								<b-row>
									<b-col cols="11" ref="sala_redirecionar_text" />
									<b-col cols="1"><font-awesome-icon icon="trash" @click="removeRedirecionarSala()" style="cursor:pointer;" /></b-col>
								</b-row>
							</div>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-form-group class="no-margin-bottom">
								<label class="typo__label" for="moderadores">
									Moderadores
									<span v-b-tooltip.hover title="Realizam o controle do evento. É necessário pelo menos um moderador na Sala." style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</label>
								<Select2 id="moderadores" name="moderadores" required
									:settings="s2GetSettings('Informe os Moderadores')"
									@select="s2SelectUsuario($event, 'moderadores')"
								/>
							</b-form-group>
							<div id="moderadores_list">
								<CriacaoSalaParticipantes v-bind:participantes="form.moderadores" tipo="moderadores" />
							</div>
						</b-col>
						<b-col>
							<b-form-group class="no-margin-bottom">
								<label class="typo__label" for="apresentadores">
									Apresentadores
									<span v-b-tooltip.hover title="Participam ativamente do evento. São os formadores de conteúdo." style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</label>
								<Select2 id="apresentadores" name="apresentadores"
									:settings="s2GetSettings('Informe os Apresentadores')"
									@select="s2SelectUsuario($event, 'apresentadores')"
								/>
							</b-form-group>
							<div id="apresentadores_list">
								<CriacaoSalaParticipantes v-bind:participantes="form.apresentadores" tipo="apresentadores" />
							</div>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-form-group class="no-margin-bottom">
								<label class="typo__label" for="participantes">
									Participantes
									<span v-b-tooltip.hover title="Participam ativamente do evento. São os formadores de conteúdo." style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</label>
								<Select2 id="participantes" name="participantes"
									:settings="s2GetSettings('Informe os Participantes')"
									@select="s2SelectUsuario($event, 'participantes')"
								/>
							</b-form-group>
							<div id="participantes_list">
								<CriacaoSalaParticipantes v-bind:participantes="form.participantes" tipo="participantes" />
							</div>
						</b-col>
						<b-col>
							<b-form-group>
								<label class="typo__label" for="participantes_ativos">
									Modo de Participação
									<span v-b-tooltip.hover title="O modo de participação Ativa insere os Participantes como integrantes da Sala,
									permitindo-lhes ativar Microfone e Câmera, enquanto o modo de participação Espectador insere os Participantes na Sala
									somente como espectadores, impossibilitando a utilização de Microfone e Câmera, permitindo interação apenas através do
									Chat." style="color: #6c757d">
										<font-awesome-icon icon="question-circle"/>
									</span>
								</label>
								<b-form-select id="participantes_ativos" name="participantes_ativos" ref="participantes_ativos" :disabled="participantes_ativos_disabled"
									v-model="form.participantes_ativos" size="sm">
									<b-form-select-option value=true>Ativa</b-form-select-option>
									<b-form-select-option value=false>Espectador</b-form-select-option>
								</b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mb-4">
<!--						<b-col md="6" sm="12">-->
<!--							<label class="typo__label" for="input-interprete">-->
<!--								Intérprete-->
<!--								<b-badge variant="info" v-b-tooltip.hover.top title="Recurso em fase de testes.">Beta</b-badge>&nbsp;-->
<!--								<span v-b-tooltip.hover title="Informe o usuário que fará a tradução com linguagem de sinais." style="color: #6c757d">-->
<!--									<font-awesome-icon icon="question-circle"/>-->
<!--								</span>-->
<!--							</label>-->
<!--							<div class="position-relative">-->
<!--								<Select2 id="input-interprete" name="input-interprete"-->
<!--									:settings="s2GetSettings('Informe a Intérprete (opcional)', url_select2_participantes)"-->
<!--									@select="s2SelectInterprete($event)"-->
<!--								/>-->
<!--								<div id="interprete" ref="interprete" v-show="form.interprete !== null">-->
<!--									<b-row>-->
<!--										<b-col cols="11" ref="interprete_text" />-->
<!--										<b-col cols="1"><font-awesome-icon icon="trash" @click="removeInterprete()" style="cursor:pointer;" /></b-col>-->
<!--									</b-row>-->
<!--								</div>-->
<!--							</div>-->
<!--						</b-col>-->
						<b-col v-if="$store.state.user.is_superuser || $store.state.user.is_staff" md="6" sm="12">
							<label class="typo__label" for="input-instancia_jitsi">
								Instância do Jitsi
							</label>
							<b-select id="input-instancia_jitsi" :disabled="is_evento_cgdp" v-model="form.servico_jitsi" :options="options_instancia_jitsi" size="sm" />
						</b-col>
						<b-col v-if="$store.state.user.lotacoes.includes('GABCORREG') || $store.state.user.lotacoes.includes('COORG') || $store.state.user.lotacoes.includes('ASSJURCOR') || $store.state.user.is_staff" md="3" sm="12">
							<b-form-group label-for="evento-cgdp">
								<b-form-checkbox  id="evento-cgdp" switch v-model="is_evento_cgdp" class="float-left"/>
								É evento da Corregedoria
								<span v-b-tooltip.hover title="Habilite caso seja um evento fechado da Corregedoria Geral de Defensores Públicos" style="color: #6c757d">
									<font-awesome-icon icon="question-circle"/>
								</span>
							</b-form-group>
						</b-col>
						<b-col v-if="$store.state.user.is_superuser || $store.state.user.is_staff" md="3" sm="12">
							<label class="typo__label" for="input-data_transfer">
								Transferência de Dados
							</label>
							<b-select id="input-data_transfer" v-model="form.data_transfer" size="sm">
								<b-select-option value="XHR" aria-selected="true">XHR</b-select-option>
								<b-select-option value="WS">WebSocket</b-select-option>
							</b-select>
						</b-col>

					</b-row>

                    <b-row class="border-top mt-1">
                        <b-col class="text-right mt-1">
                            <b-button @click="is_mobile ? $router.push({name: 'home'}) : cancelar()" type="reset" variant="outline-secondary">Cancelar</b-button>
                            <b-button @submit="onSubmit" class="ml-2" type="submit" style="width: 60px" variant="primary" v-if="!loading">
                                Criar</b-button>
                            <b-button type="button" class="ml-2" v-if="loading" style="width: 60px">
                                <b-spinner small variant="light"></b-spinner>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
        </b-row>
    </b-col>

</template>

<script>
import Select2 from 'v-select2-component'
import CriacaoSalaParticipantes from "@/components/widgets/CriacaoSalaParticipantes";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import { isMobile } from 'mobile-device-detect';

export default {
	components: {CriacaoSalaParticipantes, Select2, DatePicker},
    Components: {isMobile},
    data() {
        return {
			maxLengthNome: 40,
            options: {
                roomName: '',
			},
			
			sala: '',
			user_remetente: '',
			list_objects_origem_email: [],
			list_objects_origem_eventos: [],
			options_instancia_jitsi:[
				{value: 'newmeet.defensoria.to.def.br', text: 'NewMeet Defensoria', selected: true},
				{value: 'meet.defensoria.to.def.br', text: 'Meet Defensoria'},
				{value: 'jitsi.defensoria.to.def.br', text: 'Jitsi Defensoria (Dockerizado)'},
				{value: 'meet.jit.si', text: 'Versão pública do Jitsi'},
				{value: 'cgmeet.defensoria.to.def.br', text: 'Meet Corregedoria'},
			],
            messages: [],
            form: {
                user: this.$store.state.user.id,
                nome: '',
                token: '',
                descricao: '',
                tipo: null,
                data_inicio: '',
                data_fim: '',
                privado: false,
                gravar: false,
                moderadores: [],
                apresentadores: [],
				participantes: [],
				participantes_ativos: true,
				criar_youtube: false,
				proximo_evento: null,
				interprete: null,
				servico_jitsi: 'newmeet.defensoria.to.def.br',
				data_transfer: 'XHR'
            },
			is_evento_cgdp: false,
			gravar_evento: false,
			criar_youtube: false,
			participantes_ativos_disabled: true,
			redirecionar: false,
            tipos: [
                {text: 'Tipo de Evento', value: null},
                {text: 'Aula', value: 3},
                {text: 'Conferência', value: 2},
                {text: 'Reunião', value: 1},
            ],
            show: true,
            selectedParticipantes: [],
            selectedParticipantesAthenas: [],
            selectedParticipantesTodos: [],
            s2_value: '',
            s2_options: [],
            isLoading: false,
            user: {
                email: '',
                username: '',
                is_staff: false
            },
            participante: {
                usuario: '',
                email: '',
            },
            token: {
                user_remetente: this.$store.state.user.id,
                sala: this.$store.state.sala.id,
                email_convidado: '',
            },
            loading:false,
            interval: null,
		}
    },
    watch: {
		gravar_evento(){
			this.form.gravar = this.gravar_evento
			if(!this.gravar_evento) this.criar_youtube = false
		},
        criar_youtube(){
			this.form.participantes_ativos = !this.criar_youtube
			this.participantes_ativos_disabled = !this.criar_youtube
			this.form.criar_youtube = this.criar_youtube
		},
        redirecionar(){
			if(this.redirecionar === false) this.removeRedirecionarSala()
		},
		is_evento_cgdp(){
			if(this.is_evento_cgdp) this.form.servico_jitsi = 'cgmeet.defensoria.to.def.br'
		}
    },
	computed:{
		is_mobile(){
			return !!isMobile
		},
		url_select2_salas(){
			return this.$store.state.default_domain + '/api/salas_buscar/?data_fim__gt=' + new Date().toISOString();
		},
		url_select2_participantes(){
			return this.$store.state.default_domain + '/api/participantes_busca/'
		}
	},
    methods: {
        s2GetSettings(placeholder, endpoint=this.url_select2_participantes){
            return {
                minimumInputLength: 3,
                placeholder: placeholder,
				width: 'resolve',
                language: {
                    inputTooShort() {
                        return 'Informe no mínimo 3 caracteres...'
                    },
                    noResults() {
                        return this.url_select2_participantes === endpoint ? 'Nenhuma pessoa com este nome. Informe um e-mail para enviar um convite.' : 'Nenhuma Sala com este nome.'
                    },
                },
                ajax: {
                    url: endpoint,
                    type: 'GET',
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + window.localStorage.getItem('accessToken'))
                    },
                    data(params) {
                        return {nome: params.term}
                    },
                    processResults(data) {
                        return {results: data}
                    }
                },
                templateResult: this.url_select2_participantes === endpoint ? this.s2MakeOptionParticipante : this.s2MakeOptionSala,
                escapeMarkup(markup){
					return markup;
                }
            }
        },
        s2MakeOptionParticipante(data) {
            if (data.loading) return "Procurando...";
            let html = "<div class='clearfix'>";
            if (data.origem === 'meet' && data.avatar !== '' && data.avatar !== null) {
                html += `<div class='float-left mr-1'>
                <img class="rounded-circle" height="22px" src="https://static.defensoria.to.def.br/meet-media/${data.avatar}"></div>`;
            }
            if (data.origem === 'email') {
                html += `<div class='float-left'><small class="text-muted">Enviar convite para:</small> ${data.email}</div></div>`;
            } else {
                html += `<div class='float-left'>${data.nome}</div></div>`;
            }
            return html
        },
        s2MakeOptionSala(data) {
            if (data.loading) return "Procurando...";
            return `<div class='clearfix'>
						<div class='float-left'>
							${data.nome}<br>
							<small>${data.data_inicio} a ${data.data_fim}</small>
						</div>
					</div>`;
        },
        s2SelectUsuario(data, tipo) {
			if(tipo === 'participantes') {
				this.form.participantes.push(data);
			}else if(tipo === 'apresentadores'){
				this.form.apresentadores.push(data);
			}else if(tipo === 'moderadores'){
				this.form.moderadores.push(data);
			}
        },
		s2SelectSala(data){
			this.form.proximo_evento = data.id
			this.$refs.sala_redirecionar.style.display = 'block'
			this.$refs.sala_redirecionar_text.innerHTML = data.nome
		},
		s2SelectInterprete(data){
			this.$refs.interprete.style.display = 'block'
			this.$refs.interprete_text.innerHTML = data.nome
			this.form.interprete = data.id
		},
		removeRedirecionarSala(){
			this.form.proximo_evento = null
			this.$refs.sala_redirecionar.style.display = 'none'
		},
		removeInterprete(){
			this.form.interprete = null
			this.$refs.interprete.style.display = 'none'
		},
        deleteUsuario(pos, tipo) {
			if(tipo === 'participantes') {
				this.form.participantes.splice(pos, 1)
			}else if(tipo === 'apresentadores'){
				this.form.apresentadores.splice(pos, 1)
			}else if(tipo === 'moderadores') {
				this.form.moderadores.splice(pos, 1)
			}
		},

		enviaEmail(sala){
			let listObjects = this.form.apresentadores.concat(this.form.participantes)
			console.log(listObjects)

			let listObjectsEmail = listObjects.filter(o => o.origem === 'email')
			console.log(listObjectsEmail)

			let listObjectsEventos = listObjects.filter(o => o.origem === 'eventos')
			console.log(listObjectsEventos)
			

			if(listObjectsEmail.length > 0 || listObjectsEventos.length > 0){

				this.$http.post('/tokens/', {
					'sala': sala,
					'user_remetente': this.$store.state.user.id,
					'list_objects_origem_email': listObjectsEmail,
					'list_objects_origem_eventos': listObjectsEventos,
				})
				.then(response => {
					console.log(response)
					this.$toast.open({
						message: 'Email enviado com sucesso!',
						type: 'success',
						duration: 5000,
						position: 'top-right'
					});
				})
			}
		},

        onSubmit(evt) {
            this.loading = true
            evt.preventDefault()
            if (this.form.moderadores.length < 1) {
                this.$toast.open({
                    message: 'Você precisa informar pelo menos um moderador!',
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                });
                evt.preventDefault();
                this.loading = false;
                return
            }
            this.$http.post('/salas/', this.form)
                .then(
					resp => {
						console.log(resp)
						this.$toast.open({
							message: 'Sala criada com sucesso!',
							type: 'success',
							duration: 5000,
							position: 'top-right'
						});
                        this.loading = false
						this.$root.$emit('exibir_banner')
						this.$root.$emit('atualizar_salas')
						evt.preventDefault()
						this.enviaEmail(resp.data['id'])
					},
					err => {
						console.log(err.response.data)
						this.$toast.open({
							message: err.response.data,
							type: 'error',
							duration: 5000,
							position: 'top-right'
						});
                        this.loading = false
						this.$root.$emit('atualizar_salas')
					}
                )
			.catch(
				err => {
					console.log(err)
					this.$toast.open({
						message: 'Ocorreu um erro na criação da Sala!',
						type: 'error',
						duration: 5000,
						position: 'top-right'
					});
					this.$root.$emit('atualizar_salas')
					this.loading = false
				}
			)
        },
        onReset(evt) {
            evt.preventDefault()
            this.form.nome = ''
            this.form.descricao = ''
            this.form.tipo = null
            this.form.privado = false
            this.form.data_inicio = ''
            this.form.data_fim = ''
            this.form.hora_inicio = ''
            this.form.hora_fim = ''
            this.form.gravar = false
            this.form.moderadores = []
            this.form.apresentadores = []
            this.form.participantes = []
			this.form.servico_jitsi = 'newmeet.defensoria.to.def.br'
            this.show = false
            this.$nextTick(() => this.show = true)
        },
		cancelar(){
			this.$root.$emit('exibir_banner')
		}
    },
	created() {
		this.$session.clear();
	},
	mounted() {
		this.$root.$on('delete-usuario', args=>this.deleteUsuario(args.pos, args.tipo))
	},
	beforeDestroy() {
		this.$root.$off('delete-usuario')
	}
}
</script>

<style>
.ibox {
    border-radius: 10px;
    padding: 20px;
	height: 100%;
	height: calc(100vh - 110px);
	overflow-y: auto;
}
</style>
<style scoped>
.mobile_content{
	height: 100vh;
}
.ibox_mobile {
	height: 100vh;
}
.content_form{
	margin: 0 50px;
}
#participantes_list {
    max-height: 420px;
    overflow-y: auto;
}

#sala_redirecionar{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
    padding: 4px 13px 3px 22px;
	display: none;
}

#interprete{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
    padding: 4px 13px 3px 22px;
	display: none;
	background-color: white;
}

#show_selected_moderador {
    position: absolute;
    z-index: 20;
    background-color: white;
    width: 100%;
    height: 100%;
}

.no-margin-bottom{
	margin-bottom: 0 !important;
}
</style>
