<template>
    <div>
        <Header v-if="!is_mobile" />
        <Content/>
    </div>
</template>

<script>
import Header from './Header'
import Content from './Content'
import { isMobile } from 'mobile-device-detect';

export default {
    components: {Header, Content},
	computed:{
		is_mobile(){
			return isMobile
		}
	},
	mounted() {
		this.$store.commit('setTransition', 'slide-right')
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

body {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    background-color: #F9F9FC;
    font-family: 'Roboto', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(127, 127, 127, .5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>