var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('h3',{staticClass:"pb-2 border-bottom"},[_vm._v("Configurações")]),_c('b-tabs',{attrs:{"content-class":"mt-3","align":"center"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"microphone-alt"}}),_vm._v(" Microfone ")]},proxy:true}])},[(_vm.dispositivos.disponiveis.microfones.length > 0)?_c('b-list-group',_vm._l((_vm.dispositivos.disponiveis.microfones),function(dispositivo){return _c('b-list-group-item',{key:dispositivo.deviceId,attrs:{"button":""},on:{"click":function($event){return _vm.emitir('definir_dispositivo', {
						type: 'microfone',
						deviceLabel: dispositivo.label,
						deviceId: dispositivo.deviceId
					})}}},[_vm._v(" "+_vm._s(dispositivo.label)+" "),(_vm.dispositivos.ativos.microfones_list.includes(dispositivo.deviceId))?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Ativo")]):_vm._e(),_c('br')],1)}),1):_c('div',{staticClass:"sem_registros"},[_c('font-awesome-icon',{attrs:{"icon":"microphone-alt-slash","size":"3x"}}),_c('br'),_vm._v(" Sem Microfones disponíveis! ")],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"volume-up"}}),_vm._v(" Alto-Falante ")]},proxy:true}])},[(_vm.dispositivos.disponiveis.altofalantes.length > 0)?_c('b-list-group',_vm._l((_vm.dispositivos.disponiveis.altofalantes),function(dispositivo){return _c('b-list-group-item',{key:dispositivo.deviceId,attrs:{"button":""},on:{"click":function($event){return _vm.emitir('definir_dispositivo', {
						type: 'altofalante',
						deviceLabel: dispositivo.label,
						deviceId: dispositivo.deviceId
					})}}},[_vm._v(" "+_vm._s(dispositivo.label)+" "),(_vm.dispositivos.ativos.altofalantes_list.includes(dispositivo.deviceId))?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Ativo")]):_vm._e(),_c('br')],1)}),1):_c('div',{staticClass:"sem_registros"},[_c('font-awesome-icon',{attrs:{"icon":"volume-mute","size":"3x"}}),_c('br'),_vm._v(" Sem Alto-falantes disponíveis! ")],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"video"}}),_vm._v(" Câmera ")]},proxy:true}])},[(_vm.dispositivos.disponiveis.cameras.length > 0)?_c('b-list-group',_vm._l((_vm.dispositivos.disponiveis.cameras),function(dispositivo){return _c('b-list-group-item',{key:dispositivo.deviceId,attrs:{"button":""},on:{"click":function($event){return _vm.emitir('definir_dispositivo', {
						type: 'camera',
						deviceLabel: dispositivo.label,
						deviceId: dispositivo.deviceId
					})}}},[_vm._v(" "+_vm._s(dispositivo.label)+" "),(_vm.dispositivos.ativos.cameras_list.includes(dispositivo.deviceId))?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Ativo")]):_vm._e(),_c('br')],1)}),1):_c('div',{staticClass:"sem_registros"},[_c('font-awesome-icon',{attrs:{"icon":"video-slash","size":"3x"}}),_c('br'),_vm._v(" Sem Câmeras disponíveis! ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }