<template>
	<div id="chat" class="p-2 pr-0">
		<h3 class="pb-2 border-bottom">Chat</h3>
		<div ref="msgs" id="mensagens">
			<p
				v-for="m in $store.state.sala.chat_mensagens"
				:key="m.id"
				:class="{
					'message': true,
					'message-out': m.autor_id === $store.state.user.id,
					'message-in': m.autor_id !== $store.state.user.id
				}"
			>
				{{m.autor}} - {{ new Date(m.data).toLocaleTimeString() }}<br>
				<span>{{m.mensagem}}</span>
			</p>
		</div>
		<div id="box">
			<picker
				v-show="showEmojiPicker"
				@select="addEmoji"
				:showPreview="false"
				:set="'google'"
				:i18n="{
					search: 'Pesquisar',
					notfound: 'Não Encontrado',
					categories:
					{
						search: 'Resultados da Pesquisa',
						recent: 'Recentes',
						people: 'Expressões e Pessoas',
						nature: 'Natureza',
						foods: 'Alimentos',
						activity: 'Atividades',
						places: 'Lugares',
						objects: 'Objetos',
						symbols: 'Símbolos',
						flags: 'Bandeiras'
					}
				}"
			/>
			<span
				class="emoji-trigger"
				:class="{ 'triggered': showEmojiPicker }"
				@mousedown.prevent="toggleEmojiPicker"
			>
				<font-awesome-icon icon="smile" />
			</span>
			<b-form-input
				class="texto"
				ref="texto"
				v-model="texto"
				placeholder="Enviar Mensagem para todos"
				@keypress.enter="sendMessage"
			/>
			<span class="send-button" @click="sendMessage">
				<font-awesome-icon icon="paper-plane" />
			</span>
		</div>
	</div>
</template>

<script>
import { Picker } from 'emoji-mart-vue'

export default {
	name: "Chat",
	components: { Picker },
	data(){
		return{
			texto: null,
			showEmojiPicker: false,
		}
	},
	methods:{
		toggleEmojiPicker(){
			this.showEmojiPicker = !this.showEmojiPicker
		},
		addEmoji(emoji) {
			this.texto = this.texto ? this.texto + emoji.native : emoji.native
			this.showEmojiPicker = false
			this.$refs.texto.focus()
		},
		sendMessage(){
			if(this.texto !== null && this.texto.length > 0) {
				this.$root.$emit('send_message_chat', this.texto)
				this.texto = null
			}
		},
		receiveMessage(){
			// this.mensagens.push(msg)
			setTimeout(()=>{this.$refs.msgs.scrollTop = this.$refs.msgs.scrollHeight;}, 200)
		}
	},
	mounted() {
		this.$refs.texto.focus()
		this.$root.$on('receive_message_chat', ()=>this.receiveMessage())
		setTimeout(()=>{
			let msgs = document.getElementById('mensagens')
			msgs.scrollTop = msgs.scrollHeight;
		}, 1000)
	}
}
</script>

<style scoped>
	#chat{
		height: 100vh;
		width: 100%;
		position: relative;
	}
	#mensagens{
		height: calc(100vh - 3.25rem - 10px - 1.5em - .75rem - 2px - 1rem);
		overflow-x: hidden;
		overflow-y: auto;
		scroll-behavior: smooth;
	}
	#box{
		position: absolute;
		bottom: 10px;
		left: 10px;
		right: 10px;
	}
	.texto{
		padding-left: 30px;
		padding-right: 30px;
	}
	.emoji-mart {
		position: absolute;
		top: -420px;
		right: 10px;
	}
	.send-button {
		position: absolute;
		top: 9px;
		right: 10px;
		cursor: pointer;
		height: 20px;
		color: gray;
	}
	.emoji-trigger {
		position: absolute;
		top: 9px;
		left: 10px;
		cursor: pointer;
		height: 20px;
		color: gray;
	}
	.emoji-trigger path {
		transition: 0.1s all;
	}
	.emoji-trigger:hover path {
		fill: #000000;
	}
	.emoji-trigger.triggered path {
		fill: darken(#FEC84A, 15%);
	}
	.message {
		width: 95%;
		border-radius: 6px;
		padding: .5em;
		/*   margin-bottom: .5em; */
		font-size: .8em;
	}
	.message span{
		font-size: 1.2em;
	}
	.message-out {
		background: #407FFF;
		color: white;
		margin-left: 5%;
	}
	.message-in {
		background: #F1F0F0;
		color: black;
	}
</style>