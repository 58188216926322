<template>
    <div id="container-conversas">
        <div id="conversas" v-html="mensagens"></div>
        <div id="mensagem">
            <div class="input-group">
                <b-form-textarea
                    id="nova_mensagem" ref="nova_mensagem"
                    v-model="texto"
                    v-on:keypress.enter="enviaMensagem($event)"
                    rows="3"
                    max-rows="3"
                    no-resize
                    placeholder="Digite sua mensagem."
                ></b-form-textarea>
                <div class="input-group-append">
                    <b-button id="nova_mensagem-botao" @click="enviaMensagem($event)">
                        <font-awesome-icon icon="paper-plane" size="lg" class="text-white-50"/>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['moderador_is_on', 'chat_visible'],
    data(){
        return {
            texto: '',
            sala: this.$store.state.sala.id,
            autor: this.$store.state.user.id,
            mensagens_list: [],
            mensagens_lidas: 0,
            mensagens_length: 0,
            mensagens: '',
            interval: null,
            is_mounted: false,
            data_msg_printed: null
        }
    },
    computed:{
        mensagens_novas(){
            if(!this.is_mounted) return;
            return this.mensagens_list.length - this.mensagens_lidas
        }
    },
    methods: {
        enviaMensagem(e){
            if(this.moderador_is_on) {
            if (!e.shiftKey) {
                e.preventDefault();
                this.$http.post('/mensagens/', {
                    tipo: 1,
                    sala: this.sala,
                    autor: this.autor,
                    mensagem: this.texto.trim()
                }).then(() => {
                    this.texto = '';
                    this.carregarMensagens();
                })
            }
            this.$refs.nova_mensagem.focus();
            }else{
                this.$toast.open({
                    message: 'Só é permitido envio de mensagens com a presença do Moderador!',
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                });
            }
        },
        verificarMensagens(){
            this.$http.head(`/mensagens/?sala=${this.sala}`).then(response => {
                if(!response.headers['content-length'] || this.mensagens_length !== response.headers['content-length']){
                    this.carregarMensagens();
                }
            })
        },
        carregarMensagens(){
            let url = `/mensagens/?sala=${this.sala}`
            if(this.mensagens_list.length > 0) url += `&ultima=${this.mensagens_list[this.mensagens_list.length - 1]}`
            this.$http.get(url).then(response => {
                this.mensagens_length = response.headers['content-length'];
                response.data.forEach(element => {
                    if(!this.mensagens_list.includes(element.id)) {
                        this.mensagens_list.push(element.id)
                        if(this.chat_visible){
                            this.mensagens_lidas = this.mensagens_list.length
                        }
                        let d = new Date(element.data);
                        let data_msg = `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
                        if(data_msg !== this.data_msg_printed){
                            this.data_msg_printed = data_msg;
                            this.mensagens += `<div class="msg_data">${data_msg}</div>`
                        }
                        this.mensagens += '<div class="msg '
                        if(element.autor === parseInt(this.autor)){
                            this.mensagens += `msg_propria"><small class="text-muted">`
                        }else{
                            this.mensagens += `msg_terceiros"><small class="text-muted">${element.autor_nome} `
                        }
                        let lead_zeros = vlr=>{return vlr < 10 ? '0'+vlr : vlr}
                        this.mensagens += `${lead_zeros(d.getHours())}:${lead_zeros(d.getMinutes())}</small><br>${element.mensagem}</div>`
                        this.scrollBottom();
                    }
                });
            });
        },
        scrollBottom(){
            setTimeout(()=>{
                let conversas = document.getElementById("conversas");
                conversas.scrollTop = conversas.scrollHeight;
            }, 500);
        }
    },
    created() {
        this.interval = setInterval(()=>this.verificarMensagens(), 1000);
        this.scrollBottom();
    },
    beforeDestroy: function(){
        clearInterval(this.interval)
    },
    mounted() {
        this.is_mounted = true
    }
}
</script>

<style scoped>
#conversas{
    position: absolute;
	top: 0;
    left: 0;
    right: 0;
	height: calc(100% - 102px);
    overflow-y: auto;
    overflow-x: hidden;
	font-size: small;
}
#mensagem{
    position: absolute;
    top: calc(100% - 100px);
    left: 0;
    right: 0;
}
#nova_mensagem{
    background-color: rgb(0, 0, 0);
    color: whitesmoke;
    border-color: rgba(1, 1, 1, .5);
    font-size: small;
    height: 62px;
}
#nova_mensagem-botao{
    border-color: rgb(1, 1, 1);
}
#nova_mensagem-botao:hover{
    color: #0f0f16;
}
#container-conversas{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}
</style>

<style>
.msg{
    width: calc(100% - 10px);
    color: white;
    padding: 5px 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: rgba(0, 0, 0, .6);
}
.msg_propria{
    border-radius: 5px 0 5px 5px;
    margin: 5px 0 5px 10px;
    text-align: right;
}
.msg_terceiros{
    border-radius: 0 5px 5px 5px;
    margin: 5px 10px 5px 0;
}
.msg_data{
    width: 100px;
    text-align: center;
    padding: 5px 10px;
    margin: 10px auto 5px;
    background-color: rgba(100, 100, 100, .5);
    border-radius: 3px;
    color: whitesmoke;
}
</style>