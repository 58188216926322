<template>
	<b-row>
		<b-col>
			<b-row>
				<b-col>
					<b-button variant="outline-primary" size="sm" @click="toggleFormulario">Criar</b-button>
					<b-button v-if="!$store.state.sala.subsalas_ativo" variant="success" size="sm" @click="iniciarSubsalas" class="ml-3">
<!--					<b-button variant="success" size="sm" @click="iniciarSubsalas" class="ml-3">-->
						<font-awesome-icon icon="play" /> Iniciar
					</b-button>
					<b-button v-else variant="danger" size="sm" @click="finalizarSubsalas" class="ml-3">
<!--					<b-button variant="danger" size="sm" @click="finalizarSubsalas" class="ml-3">-->
						<font-awesome-icon icon="stop" /> Finalizar
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-table-simple
						v-if="$store.state.sala.subsalas.length > 0"
						responsive striped hover
						class="mt-2"
					>
						<b-tbody>
							<template v-for="subsala in $store.state.sala.subsalas">
								<b-tr
									@click="toggleSubsala(subsala.id)"
									:class="{opened: opened.includes(subsala.id)}"
									style="cursor: pointer"
									:key="subsala.id"
								>
									<b-td v-html="subsala.nome" />
									<b-td class="text-right p-1 pr-3" style="padding-top: .4rem !important;">
										<b-button-group size="sm">
											<b-button variant="light" @click="editarSubsala($event, subsala)">
												<font-awesome-icon icon="pencil-alt" />
											</b-button>
											<b-button variant="light">
												<font-awesome-icon icon="trash" @click="confirmDelete($event, subsala.id)" />
											</b-button>
										</b-button-group>
									</b-td>
								</b-tr>
								<template
									v-if="opened.includes(subsala.id)"
								>
									<b-tr
										v-for="participante in subsala.participantes"
										:key="participante.id"
										:class="{opened_childs: opened.includes(subsala.id)}"
										:id="`subsala_list_${subsala.id}`"
									>
										<b-td class="pl-4" v-text="participante.nome" colspan="2" />
									</b-tr>
								</template>
							</template>
						</b-tbody>
					</b-table-simple>
					<div v-else class="p-4">
						<h6 class="text-center text-black-50">
							<font-awesome-icon icon="folder-open" size="2x" /><br>Nenhuma Sub-Sala
						</h6>
					</div>
				</b-col>
			</b-row>
		</b-col>
		<b-col v-show="show_formulario" class="border-left">
			<SalasSubSalaForm v-if="show_formulario" :subsala="subsala_editar" />
		</b-col>
	</b-row>
</template>

<script>
import SalasSubSalaForm from "@/components/Salas/SalasSubSalaForm";
export default {
	name: "SalaSubSalas",
	components: {SalasSubSalaForm},
	props: {
		modal_subsalas_size: {type: String, default: 'md'},
		subsala_editar: null
	},
	data(){
		return{
			show_formulario: false,
			opened: []
		}
	},
	methods:{
		editarSubsala(e, subsala){
			e.stopPropagation()
			this.subsala_editar = subsala
			this.$emit('update:modal_subsalas_size', 'xl')
			this.show_formulario = true
		},
		toggleSubsala(subsala){
			let index = this.opened.indexOf(subsala)
			if(index > -1){
				this.opened.splice(index, 1)
			}else{
				this.opened.push(subsala)
			}
		},
		toggleFormulario(){
			if(!this.show_formulario){
				this.$emit('update:modal_subsalas_size', 'xl')
				this.show_formulario = true
			}else{
				this.$emit('update:modal_subsalas_size', 'md')
				this.subsala_editar = null
				this.show_formulario = false
			}
		},
		confirmDelete(e, id){
			e.stopPropagation()
			if(confirm('Tem certeza que deseja deletar a Sub-Sala?')){
				this.$http.delete(
					`/sub_salas/${id}/`
				).then(response=>{
					this.$toast.open({
						'message': response.status === 202 ? 'Sub-Sala removida com sucesso.' : 'Ocorreu um erro ao remover a Sub-Sala.',
						'type': response.status === 202 ? 'success' : 'error',
						'duration': 5000,
						'position': 'top-right'
					});
					this.$root.$emit('sala_atualizada')
					this.$root.$emit('recarregar_subsalas')
				})
			}
		},
		iniciarSubsalas(){
			this.$root.$emit('iniciar_subsalas')
		},
		finalizarSubsalas(){
			this.$root.$emit('finalizar_subsalas')
		}
	}
}
</script>

<style scoped>
.opened{
	font-weight: bold;
	border: 1px solid #c0c2c4 !important;
	border-bottom-width: 0 !important;
}
.opened td{
	background-color: #d6d6d6;
}
.opened_childs{
	border: 1px solid #c0c2c4 !important;
	border-top-width: 0 !important;
}
</style>