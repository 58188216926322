<template>
    <div>
        <b-tabs content-class="mt-3">
            <b-tab active title="Acessos">
                <div v-if="registros.length > 0" class="registros-container">
                    <div class="row border-bottom pt-1 pb-2 registro-item" :key="id" v-for="(registro, id) in registros">
                        <div class="col-2 pt-1">
                            <b-avatar v-if="registro.tipo === 1" variant="success" v-b-tooltip.hover title="Entrada">
                                <font-awesome-icon icon="door-open"/>
                            </b-avatar>
                            <b-avatar v-if="registro.tipo === 2" variant="warning" v-b-tooltip.hover title="Saida">
                                <font-awesome-icon icon="door-closed"/>
                            </b-avatar>
                        </div>
                        <div class="col-10">
                            <small>{{ registro.nome }}</small><br/>
                            <small v-if="registro.tipo == 1">
                                {{ registro.horario | moment("DD/MM/YYYY - HH:mm") }}
                            </small>
                            <small v-if="registro.tipo == 2">
                                {{ registro.horario | moment("DD/MM/YYYY - HH:mm") }}
                            </small>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Tempo total">
                <ul v-if="temposTotais.length > 0">
                    <li :key="id" v-for="(tempoTotal, id) in temposTotais">
                        {{ tempoTotal.nome }}<br/>
                        <small v-if="tempoTotal.horario_contabilizado">
                            <font-awesome-icon icon="clock" size="sm" title="Entrada"/>
                            {{ tempoTotal.horario_contabilizado }}
                        </small>
                    </li>
                </ul>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
export default {
    name: "RegistrosList",
    props: {
        registros: Array,
        temposTotais: Array,
    }
}
</script>

<style scoped>
.registros-container{
    height: calc(100vh - 150px);
    overflow-y: auto;
}
.registro-item{
    width: 100%;
    margin-right: 15px;
}
</style>
