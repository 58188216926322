<template>
	<form ref="form" @submit="atualizaDadosYoutube">
		<b-form-group id="input-group-2" label="Nome Live Youtube" label-for="input-2">
            <b-form-input
            id="input-2"
            v-model="youtube.salaNome"
            required
            placeholder="Digite o nome da Live do Youtube"
            ></b-form-input>
        </b-form-group>

		<!-- <b-form-group id="input-group-3" label="Thumbnail (em fase de teste)" label-for="file">
			<b-form-file 
			id="file"
			accept="image/*"
			v-model="youtube.thumbnail"
			:state="Boolean(youtube.thumbnail)"
			placeholder="Escolha um arquivo"
			drop-placeholder="Drop file here..."
			></b-form-file>
        </b-form-group> -->
			
		<div class="text-right">
			<b-button @submit="atualizaDadosYoutube" class="ml-2" type="submit" variant="primary">Salvar
			</b-button>
		</div>
	</form>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
// import 'vue2-datepicker/locale/pt-br';
export default {
	name: "SalaYoutubeEditar",
	// components: {DatePicker},
	data() {
        return {
            youtube: {
                slug: this.$store.state.sala.slug,
                salaNome: this.$store.state.sala.youtube.broadcast.snippet.title,
                dataInicio: this.$store.state.sala.youtube.broadcast.snippet.scheduledStartTime,
				thumbnail: ""
            }
		}
    },
	methods:{
		atualizaDadosYoutube(e){
            e.preventDefault();
			let formData = new FormData();
			formData.append('slug', this.youtube.slug)
			formData.append('salaNome', this.youtube.salaNome)
			formData.append('dataInicio', this.youtube.dataInicio)
			formData.append('thumbnail', this.youtube.thumbnail)
			this.$http.put(`/cria_vinculo_youtube/${this.$store.state.sala.slug}/`, formData)
			.then(response => {
				console.log(response)
				this.$toast.open({
					message: 'Sala atualizada com sucesso!',
					type: 'success',
					duration: 5000,
					position: 'top-right'
				})
				this.$root.$emit('sala_atualizada')
			},
			erro=>{
				console.log(erro.response.data)
				this.$toast.open({
					message: erro.response.data,
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
			})
		}
	},
	created(){
		// this.youtube.dataInicio = new Date(this.youtube.dataInicio)
		// console.log(this.$store.state.sala.youtube.broadcast.snippet)
	}
}
</script>

<style scoped>

</style>