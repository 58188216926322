<template>
    <b-row v-if="salaCriadaEventos" class="recursos-container">
        <b-col>
            <h3 class="border-bottom">Recursos</h3>
            <b-form @submit="onSubmit">
                <b-form-group id="input-group-2" label="Nome material" label-for="input-2">
                    <b-form-input
                    id="input-2"
                    v-model="materialDidaticoEvento.apresentacao_material"
                    required
                    placeholder="Digite o nome do arquivo"
                    ></b-form-input>
                </b-form-group>

                <!-- Styled -->
                <b-form-group id="input-group-3" label="Arquivo" label-for="file">
                    <b-form-file 
                    id="file"
                    accept="image/*"
                    v-model="materialDidaticoEvento.arquivo"
                    :state="Boolean(materialDidaticoEvento.arquivo)"
                    placeholder="Escolha um arquivo"
                    drop-placeholder="Drop file here..."
                    ></b-form-file>
                </b-form-group>
                
                <br>
                <b-button @submit="onSubmit" type="submit" variant="success">Enviar</b-button>
            </b-form>

            <div v-if="materiaisDidaticosListEvento != '' || materiaisDidaticosListAtividade != ''">
                <b-alert show>Arquivos anexados</b-alert>

            </div>
            <div class="p-3">
                <b-list-group>
                    <b-list-group-item
                        v-for="(material, pk) in materiaisDidaticosListEvento" :key="pk">
                        <a v-html="material.apresentacao_material" target="_blank" v-bind:href="material.arquivo"></a>
                    </b-list-group-item>
                </b-list-group>
            </div>

            <div class="p-3">
                <b-list-group>
                    <b-list-group-item
                        v-for="(material, pk) in materiaisDidaticosListAtividade" :key="pk">
                        <a v-html="material.apresentacao_material" target="_blank" v-bind:href="material.arquivo"></a>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <br>

        </b-col>
        
    </b-row>
    <span v-else><small></small></span>
</template>

<script>

export default {
    data() {
        return {
            materiaisDidaticosListEvento: "",
            materiaisDidaticosListAtividade: "",
            salaCriadaEventos: true,
            salaMeetId: this.$store.state.sala.id,
            materialDidaticoEvento: {
                arquivo: null,
                apresentacao_material: "",
                evento: "",
                // atividade: "",
            },
            materialDidaticoAtividade: {
                arquivo: null,
                apresentacao_material: "",
                // evento: "",
                atividade: "",
            }
            
        }
    },
    methods:{
        onSubmit(e){
            e.preventDefault()
            // let config = {header: {'Content-Type': 'multipart/form-data'}}
            let formData = new FormData();
            formData.append('arquivo', this.materialDidatico.arquivo);
            formData.append('apresentacao_material', this.materialDidatico.apresentacao_material);
            formData.append('evento', this.materialDidatico.evento);
            this.$eventos.post('/material-didatico/', formData)
            .then(response=>{
                console.log(response)
                this.$toast.open({
                    message: 'Arquivo submetido com sucesso!',
                    type: 'success',
                    duration: 5000,
                    position: 'top-right'
                });
            })
        }
    },

    created(){ 
        let sala_meet_id = this.$store.state.sala.id
        this.$eventos.get(`/atividades/?sala_meet_id=${sala_meet_id}`)
        .then(response => {
            if(response.data.results != ''){
                // console.log("opa, eu tenho resultados, sala criada pelo sistema de eventos")
                // console.log(response)
                this.$eventos.get(`/eventos/${response.data.results[0].evento}/`)
                .then(response => {
                    // console.log(response)
                    console.log(response.data.materiais_didaticos)
                    console.log(this.materiaisDidaticosListEvento)
                    this.materiaisDidaticosListEvento = response.data.materiais_didaticos
                    this.materialDidaticoEvento.evento = response.data.id
                    
                })
                this.$eventos.get(`/atividades/${response.data.results[0].id}/`)
                .then(response => {
                    console.log(response.data.materiais_didaticos)
                    console.log(this.materiaisDidaticosListAtividade)
                    this.materiaisDidaticosListAtividade = response.data.materiais_didaticos
                    this.materialDidaticoAtividade.atividade = response.data.id
                })
            }else{
                // console.log("opa, eu não tenho resultados, sala não criada pelo sistema de eventos")
                // console.log("opa, sem sala pelo sistema de eventos")
                this.salaCriadaEventos = false
            }
            
        })
    }
}
</script>

<style>
.recursos-container{
    height: calc(100vh - 150px);
    overflow-y: auto;
}

</style>