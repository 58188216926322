<template>
	<div class="container">
		<b-row class="text-center"
			v-if="streaming.status.streamStatus === 'active' && ['testing', 'live'].includes(this.broadcast.status.lifeCycleStatus)">
			<b-col v-html="yt.infos.broadcast.contentDetails.monitorStream.embedHtml" />
		</b-row>
		<b-row>
			<b-col>
				<b-button-group size="sm" class="info_transmissao">
					<b-button class="text-center"
						:variant="stream_status.variant"
						v-b-tooltip.hover.top :title="stream_status.desc">
						<b><font-awesome-icon icon="link" /> STREAMING</b><br>
						{{stream_status.title}}

					</b-button>
					<b-button class="text-center"
						:variant="broadcast_status.variant"
						v-b-tooltip.hover.top :title="broadcast_status.desc">
						<b><font-awesome-icon icon="video" /> BROADCAST</b><br>
						{{broadcast_status.title}}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<b-overlay :show="overlay_passos" variant="dark" rounded="sm">
		<b-row class="text-center mt-2 mb-2" style="font-size: small">
			<b-col cols="2" style="padding: 2px; position:relative;" class="pl-2">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_1"
						:variant="passo_atual === 1 ? 'primary' : !passo_atual ? 'dark' : passo_atual > 1 ? 'success' : 'dark'"
						@click="acaoPasso(1)"
						block>.
					<div class="button-icone">
						<font-awesome-icon icon="door-open" />
					</div>
				</b-button>
					Sala
			</b-col>
			<b-col cols="2" style="padding: 2px; position:relative;">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_2"
						:variant="passo_atual === 2 ? 'primary' : passo_atual === 1 ? 'dark' : passo_atual > 2 ? 'success' : 'dark'"
						@click="acaoPasso(2)"
						block>.
					<div class="button-icone">
						<font-awesome-icon icon="video" />
					</div>
				</b-button>
					Gravar
			</b-col>
			<b-col cols="2" style="padding: 2px; position:relative;">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_3"
						:variant="passo_atual === 3 ? 'primary' : passo_atual === 2 ? 'dark' : passo_atual > 3 ? 'success' : 'dark'"
						@click="acaoPasso(3)"
						block>.
					<div class="button-icone">
						<font-awesome-icon icon="tasks" />
					</div>
				</b-button>
					Testar
			</b-col>
			<b-col cols="2" style="padding: 2px; position:relative;">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_4"
						:variant="passo_atual === 4 ? 'primary' : passo_atual === 3 ? 'dark' : passo_atual > 4 ? 'success' : 'dark'"
						@click="acaoPasso(4)"
						block>.
					<div class="button-icone">
						<font-awesome-icon icon="play" />
					</div>
				</b-button>
					Transmitir
			</b-col>
			<b-col cols="2" style="padding: 2px; position:relative;">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_5"
						:variant="passo_atual === 5 ? 'primary' : passo_atual === 4 ? 'dark' : passo_atual > 5 ? 'success' : 'dark'"
						@click="acaoPasso(5)"
						block>.
					<div class="button-icone">
						<font-awesome-icon icon="check" />
					</div>
				</b-button>
					Encerrar
			</b-col>
			<b-col cols="2" style="padding: 2px; position:relative;" class=" pr-4">
				<b-button size="sm" class="btn-arrow-right botao-transmissao" id="botao_transmissao_6"
						:variant="passo_atual === 6 ? 'primary' : passo_atual === 5 ? 'dark' : passo_atual > 6 ? 'success' : 'dark'"
						@click="acaoPasso(6)"
						block>.
					<div class="button-icone last">
						<font-awesome-icon icon="door-closed" />
					</div>
				</b-button>
					Finalizar
			</b-col>
		</b-row>
		</b-overlay>
		<b-row>
			<b-col>
				<p class="text-center">
					<b-button-group>
						<a :href="url_youtube"
							target="_blank"
							class="btn btn-secondary btn-sm"
						>
							{{ url_youtube }}
						</a>
						<b-button variant="secondary" size="sm" @click="copiarLink" title="Copiar URL">
							<font-awesome-icon icon="copy" />
						</b-button>
					</b-button-group>
					<b-button-group class="ml-1">
						<input class="form-control" size="sm" type="text" variant="secondary" readonly
						:value="$store.state.sala.youtube_streamkey">
						<b-button variant="secondary" size="sm" @click="copiarChave" title="Copiar Chave">
							<font-awesome-icon icon="copy" />
						</b-button>
					</b-button-group>
					<input type="hidden" id="url_youtube_field" :value="url_youtube">
					<a :href="url_studio" class="btn btn-secondary btn-sm ml-1" target="_blank">
						<font-awesome-icon icon="external-link-alt" /> Studio
					</a>
				</p>
			</b-col>
		</b-row>
		<b-row class="text-center">
			<b-col>
				<b>Conexão:</b><br>
				<b-badge
					:variant="recording_status.variant"
					v-html="recording_status.title"
					v-b-tooltip.hover.top :title="recording_status.desc" />
			</b-col>
<!--					({{stream_status.status.healthStatus.status}})-->
<!--					<br>-->
<!--			<b-col>-->
<!--				<b>Chave:</b><br>-->
<!--				{{ $store.state.sala.youtube_streamkey }}-->
<!--			</b-col>-->
<!--			<b-col>-->
<!--				<b>Última Atualização:</b><br>-->
<!--				{{ this.yt.ultima_atualizacao.toLocaleTimeString() }}-->
<!--			</b-col>-->
		</b-row>
	</div>

</template>

<script>
export default {
name: "SalaYoutubeStatus",
	props:{
		yt: {
			type: Object,
			required: true
		}
	},
	data(){
		return{
			overlay_passos: false
		}
	},
	watch:{
		overlay_passos(novo, antigo){
			if(novo === true && antigo === false){
				setTimeout(()=>{
					this.overlay_passos = false
				}, 30000)
			}
		}
	},
	computed:{
		passo_atual(){
			let stream = this.streaming.status.streamStatus
			let broadcast = this.broadcast.status.lifeCycleStatus
			if(this.$store.state.sala.finalizado) return 6
			if(broadcast === 'complete') return 5
			if(broadcast === 'live') return 4
			if(broadcast === 'testing') {
				if(stream === 'active') {
					return 3
				}else{
					return 1
				}
			}
			if(broadcast === 'ready' && stream === 'active') return 2
			if(broadcast === 'ready' && stream !== 'active') return 1
			return 1
		},
		url_embed_monitor(){
			let url = this.yt.infos.broadcast.contentDetails.monitorStream.embedHtml.split('src="')[1]
			url = url.split('" ')[0]
			return url
		},
		broadcast(){
			return ('items' in this.yt.broadcast) ? this.yt.broadcast.items[0] : this.yt.broadcast
		},
		streaming(){
			return ('items' in this.yt.stream) ? this.yt.stream.items[0]: this.yt.stream
		},
		monitor_style(){
			return {
				pointerEvents: this.broadcast_status.variant !== 'light' ? 'unset' : 'none'
			}
		},
		url_youtube(){
			return 'https://youtu.be/'+this.$store.state.sala.broadcast
		},
		url_studio(){
			return 'https://studio.youtube.com/video/'+this.$store.state.sala.broadcast+'/livestreaming'
		},
		transmitindo(){
			return this.broadcast.length > 0 && (this.broadcast.status.lifeCycleStatus === 'testing' || this.broadcast.status.lifeCycleStatus === 'live')
		},
		pode_testar(){
			return this.streaming.status.streamStatus === 'active' && this.broadcast.length > 0 && this.broadcast.status.lifeCycleStatus === 'ready' && this.yt.infos.broadcast.contentDetails.monitorStream.enableMonitorStream
		},
		stream_status(){
			if(this.streaming.status.streamStatus === 'active'){
				return {
					variant: 'success',
					title: 'Ativo',
					desc: 'O YouTube está recebendo dados.'
				}
			}else if(this.streaming.status.streamStatus === 'created'){
				return {
					variant: 'primary',
					title: 'Criado',
					desc: 'Criado, mas possui configurações inválidas.'
				}
			}else if(this.streaming.status.streamStatus === 'error'){
				return {
					variant: 'danger',
					title: 'Erro',
					desc: 'Existe uma condição de erro no stream.'
				}
			}else if(this.streaming.status.streamStatus === 'inactive'){
				return {
					variant: 'warning',
					title: 'Inativo',
					desc: 'O YouTube não está recebendo dados.'
				}
			}else if(this.streaming.status.streamStatus === 'ready'){
				return {
					variant: 'info',
					title: 'Pronto',
					desc: 'Configurações válidas. Pronto para iniciar.'
				}
			}else{
				return {
					variant: 'dark',
					title: 'Desconhecido',
					desc: 'O YouTube não retornou dados a respeito.'
				}
			}
		},
		broadcast_status(){
			if(this.broadcast.length === 0){
				return {
					variant: 'dark',
					title: 'Desconhecido',
					desc: 'O YouTube não retornou dados a respeito.'
				}
			}
			if(this.broadcast.status.lifeCycleStatus === 'complete'){
				return {
					variant: 'primary',
					title: 'Concluído',
					desc: 'A transmissão foi finalizada.'
				}
			}else if(this.broadcast.status.lifeCycleStatus === 'created'){
				return {
					variant: 'warning',
					title: 'Criado',
					desc: 'Configurações incompletas. Não está pronta para iniciar ou testar a transmissão, mas foi criada e é válida.'
				}
			}else if(this.broadcast.status.lifeCycleStatus === 'live'){
				return {
					variant: 'success',
					title: 'Live',
					desc: 'A transmissão está ativa.'
				}
			}else if(this.broadcast.status.lifeCycleStatus === 'ready'){
				return {
					variant: 'info',
					title: 'Pronto',
					desc: 'Configurações estão concluídas e pode iniciar ou testar a transmissão.'
				}
			}else if(this.broadcast.status.lifeCycleStatus === 'revoked'){
				return {
					variant: 'danger',
					title: 'Removida',
					desc: 'Foi removido pelo administrador.'
				}
			}else if(this.broadcast.status.lifeCycleStatus === 'testing'){
				return {
					variant: 'light',
					title: 'Testando',
					desc: 'Transmissão em teste. Visível apenas para o transmissor.'
				}
			}else{
				return {
					variant: 'dark',
					title: 'Desconhecido',
					desc: 'O YouTube não retornou dados a respeito.'
				}
			}
		},
		recording_status(){
			if(this.broadcast.length === 0){
				return {
					variant: 'dark',
					title: 'Desconhecido',
					desc: 'O YouTube não retornou dados a respeito.'
				}
			}
			if(this.broadcast.status.recordingStatus === 'notRecording' && this.streaming.status.streamStatus !== 'active'){
				return {
					variant: 'danger',
					title: 'Off-Line',
					desc: 'O Meet não está conectado ao YouTube.'
				}
			}else if(this.broadcast.status.recordingStatus === 'recorded'){
				return {
					variant: 'primary',
					title: 'Gravado',
					desc: 'Já existe vídeo gravado.'
				}
			}else if(this.broadcast.status.recordingStatus === 'recording' || this.streaming.status.streamStatus === 'active'){
				return {
					variant: 'success',
					title: 'On-Line',
					desc: 'O Meet está conectado ao YouTube.'
				}
			}else{
				return {
					variant: 'dark',
					title: 'Desconhecido',
					desc: 'O YouTube não retornou dados a respeito.'
				}
			}
		}
	},
	methods:{
		copiarLink(){
			let confs = {
				duration: 5000,
				position: 'top-right',
			}

			let copiar = document.querySelector('#url_youtube_field')
			copiar.setAttribute('type', 'text')
			copiar.select()

			try {
				let successful = document.execCommand('copy');
				if(successful) {
					confs.type = 'info'
					confs.message = 'Copiado com sucesso!'
				}else{
					confs.type = 'error'
					confs.message = 'Erro co copiar!'
				}
			} catch (err) {
				confs.type = 'error'
				confs.message = 'Erro co copiar!'
			}
			this.$toast.open(confs);

			copiar.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()
		},
		acaoPasso(passo){
			this.overlay_passos = true
			if(passo === 1) {
				if([2, 3].includes(this.passo_atual)) {
					this.$root.$emit('parar_gravacao')
					return true
				}
			}
			if(passo === 2) {
				if(this.passo_atual === 1) {
					this.$root.$emit('iniciar_gravacao')
					return true
				}
			}
			if(passo === 3) {
				if(this.passo_atual === 1) {
					this.$root.$emit('iniciar_gravacao')
					return true
				}
				if([1, 2].includes(this.passo_atual)) {
					this.testarTransmissao()
					return true
				}
			}
			if(passo === 4) {
				if(this.passo_atual === 1) {
					this.$root.$emit('iniciar_gravacao')
					return true
				}
				if([2, 3].includes(this.passo_atual)) {
					this.$root.$emit('iniciar_transmissao')
					return true
				}
			}
			if(passo === 5) {
				if(this.passo_atual === 4){
					this.$root.$emit('finalizar_transmissao')
					return true
				}
			}
			if(passo === 6) {
				if(this.passo_atual === 5){
					this.$root.$emit('fechar_sala')
					return true
				}
			}
			this.overlay_passos = false
			return true
		},
		iniciarGravacaoMeet(){
			this.$root.$emit('iniciar_gravacao')
		},
		testarTransmissao(){
			if(this.broadcast.status.lifeCycleStatus === 'ready'){
				this.$http.post('/youtube_live/', {
					slug: this.$store.state.sala.slug,
					tipo: 'testar'
				}).then(resp=>{
					if(resp.status === 200){
						setTimeout(()=>{this.$root.$emit('get_live_youtube')}, 5000)
						this.$toast.open({
							message: 'Teste iniciado! O Monitor aparecerá em breve!',
							type: 'success',
							duration: 5000,
							position: 'top-right'
						});
					}else{
						console.log(resp)
						this.$toast.open({
							message: 'O Youtube não iniciou o teste!',
							type: 'error',
							duration: 5000,
							position: 'top-right'
						});
					}
				},
				erro=>{
					console.log(erro)
					this.$toast.open({
						message: 'O Youtube não iniciou o teste!',
						type: 'error',
						duration: 5000,
						position: 'top-right'
					});
				})
			}else{
				this.$toast.open({
					message: 'Impossível testar agora!',
					type: 'error',
					duration: 5000,
					position: 'top-right'
				});
			}
		},
		focusBotaoTransmissao(e){
			console.log(e.type, e)
			e.stopPropagation()
			// let event = new Event('mouseover');
			// let element = document.getElementById('botao_transmissao_'+id_botao)
			// console.log('disparou over', element)
			// element.dispatchEvent(event)
		},
		blurBotaoTransmissao(e){
			console.log(e.type, e)
			e.stopPropagation()
			// let event = new Event('mouseout');
			// let element = document.getElementById('botao_transmissao_'+id_botao)
			// console.log('disparou out', element)
			// element.dispatchEvent(event)
		}
	},
	mounted() {
		this.$root.$on('youtube_respondeu', ()=>this.overlay_passos=false)
	},
	beforeDestroy() {
		this.$root.$off('youtube_respondeu')
	}
}
</script>

<style scoped>
	.info_transmissao{
		display: flex;
		flex: 1;
		margin-bottom: 5px;
		font-size: .6rem;
	}
	.container{
		color: whitesmoke;
		padding: 0 10px;
		font-size: 14px;
	}
	.accordion-item{
		font-size: 12px;
		color: lightgray;
	}
	.collapse_header{
		cursor: pointer;
	}
	h5{
		margin-top: 7px
	}

	/*ARROW BUTTONS*/
	.btn-arrow-right,
	.btn-arrow-left {
		position: relative;
		padding-left: 18px;
		padding-right: 18px;
	}

	.btn-arrow-right {
		padding-left: 36px;
	}

	.btn-arrow-left {
		padding-right: 36px;
	}

	.btn-arrow-right:before,
	.btn-arrow-right:after,
	.btn-arrow-left:before,
	.btn-arrow-left:after {
		/* make two squares (before and after), looking similar to the button */
		content: "";
		position: absolute;
		top: 3px;
		/* move it down because of rounded corners */
		width: 22px;
		/* same as height */
		height: 22px;
		/* button_outer_height / sqrt(2) */
		background: inherit;
		/* use parent background */
		border: inherit;
		/* use parent border */
		border-left-color: transparent;
		/* hide left border */
		border-bottom-color: transparent;
		/* hide bottom border */
		border-radius: 0px 4px 0px 0px;
		/* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
		-webkit-border-radius: 0px 4px 0px 0px;
		-moz-border-radius: 0px 4px 0px 0px;
	}

	.btn-arrow-right:before,
	.btn-arrow-right:after {
		transform: rotate(45deg);
		/* rotate right arrow squares 45 deg to point right */
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
	}

	.btn-arrow-left:before,
	.btn-arrow-left:after {
		transform: rotate(225deg);
		/* rotate left arrow squares 225 deg to point left */
		-webkit-transform: rotate(225deg);
		-moz-transform: rotate(225deg);
		-o-transform: rotate(225deg);
		-ms-transform: rotate(225deg);
	}

	.btn-arrow-right:before,
	.btn-arrow-left:before {
		/* align the "before" square to the left */
		left: -11px;
	}

	.btn-arrow-right:after,
	.btn-arrow-left:after {
		/* align the "after" square to the right */
		right: -11px;
	}

	.btn-arrow-right:after,
	.btn-arrow-left:before {
		/* bring arrow pointers to front */
		z-index: 1;
	}

	.btn-arrow-right:before,
	.btn-arrow-left:after {
		/* hide arrow tails background */
		background-color: #232323;
	}

	.botao-transmissao{
		position: relative;
	}

	.button-icone{
		position: absolute;
		cursor: pointer;
		top: .35rem;
		left: 19px;
		right: 0;
		text-align: center
	}

	.button_icone.last{
		left: 0 !important;
	}
</style>