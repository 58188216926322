<template>
	<div class="p-2">
		<h3 class="pb-2 border-bottom">Informações da Sala</h3>
		<ul>
			<li>
				<strong>Nome:</strong><br>
				{{ $store.state.sala.nome }}
			</li>
			<li>
				<strong>Descrição:</strong><br>
				<p class="text-justify">{{ $store.state.sala.descricao }}</p>
			</li>
			<li class="row">
				<b-col>
					<strong>Início:</strong><br>
					{{ $store.state.sala.data_inicio }}
				</b-col>
				<b-col>
					<strong>Fim:</strong><br>
					{{ $store.state.sala.data_fim }}
				</b-col>
			</li>
			<li class="row">
				<b-col>
					<strong>Privado:</strong><br>
					{{ $store.state.sala.privado ? 'Sim' : 'Não' }}
				</b-col>
				<b-col>
					<strong>Gravado:</strong><br>
					{{ $store.state.sala.gravar ? 'Sim' : 'Não' }}
				</b-col>
				<b-col>
					<strong>YouTube:</strong><br>
					{{ $store.state.sala.youtube ? 'Sim' : 'Não' }}
				</b-col>
			</li>
			<li>
				<strong>Moderadores:</strong><br>
				<ul><li v-for="mod in $store.state.sala.moderadores" :key="mod.id">{{mod.nome}}</li></ul>
			</li>
			<li v-if="$store.state.sala.apresentadores_list && $store.state.sala.apresentadores_list.length > 0">
				<strong>Apresentadores:</strong><br>
				<ul><li v-for="apr in $store.state.sala.apresentadores" :key="apr.id">{{apr.nome}}</li></ul>
			</li>
			<li v-if="$store.state.sala.participantes_list && $store.state.sala.participantes_list.length > 0">
				<strong>Participantes:</strong><br>
				<ul><li v-for="part in $store.state.sala.participantes" :key="part.id">{{part.nome}}</li></ul>
			</li>
			<li v-if="!$store.state.sala.privado">
				<strong>URL da Sala:</strong><br>
				<a :href="url_sala" target="_blank">{{url_sala}}</a><br>
				<qrcode :value="url_sala" :options="{ width: 100 }" />
			</li>
			<li v-if="$store.state.user.is_staff">
				<strong>URL direto do Serviço:</strong><br>
				<a :href="url_externo_sala" target="_blank">{{url_externo_sala}}</a><br>
				<qrcode :value="url_externo_sala" :options="{ width: 100 }" />
			</li>

		</ul>
	</div>
</template>

<script>
export default {
	name: "Infos",
	computed:{
		url_sala(){
			return window.location.origin + '/sala/' + this.$store.state.sala.slug
		},
        url_externo_sala(){
          return 'https://'+this.$store.state.sala.servico_jitsi+'/'+this.$store.state.sala.token
        },
	}
}
</script>

<style scoped>
	ul{
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	li{
		padding: 0 0 1rem 0;
	}
</style>