<template>
	<div ref="parentselect2" id="addusuariosala_content">
		<h3 class="text-center">Adicionar {{tipo.charAt(0).toUpperCase() + tipo.slice(1)}}</h3>
		<b-form-group class="no-margin-bottom">
			<Select2 v-if="mounted" id="participantes" name="participantes"
				:settings="s2GetSettings('Digite o nome')"
				@select="s2SelectUsuario($event)"
			/>
			<div id="participantes_list">
				<CriacaoSalaParticipantes
					v-bind:participantes="selecionados"
					:tipo="tipo"
				/>
			</div>
		</b-form-group>
	</div>
</template>

<script>
import Select2 from 'v-select2-component'
import CriacaoSalaParticipantes from "@/components/widgets/CriacaoSalaParticipantes";
export default {
	components: {Select2, CriacaoSalaParticipantes},
	name: "AddUsuarioSala",
	props:{
		tipo: {type: String}
	},
	data(){
		return {
			url: this.$store.state.default_domain + '/api/participantes_busca/',
			selecionados: [],
            s2_value: '',
            s2_options: [],
			mounted: false
		}
	},
	methods:{
		s2GetSettings(placeholder){
            return {
                minimumInputLength: 3,
                placeholder: placeholder,
				dropdownParent: document.getElementById('addusuariosala_content'),
				dropdownAutoWidth : true,
				width: '100%',
                language: {
                    inputTooShort() {
                        return 'Informe no mínimo 3 caracteres...'
                    },
                    noResults() {
                        return 'Nenhuma pessoa com este nome.'
                    },
                },
                ajax: {
                    url: this.url,
                    type: 'GET',
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + window.localStorage.getItem('accessToken'))
                    },
                    data(params) {
                        return {nome: params.term}
                    },
                    processResults(data) {
                        return {results: data}
                    }
                },
                templateResult: this.s2MakeOption,
                escapeMarkup(markup) {
                    return markup;
                }
            }
        },
        s2MakeOption(data) {
            if (data.loading) return "Procurando...";
            let html = "<div class='clearfix' style='color:black'>";
            if (data.origem === 'meet' && data.avatar !== '' && data.avatar !== null) {
                html += `<div class='float-left mr-1'>
                <img class="rounded-circle" height="22px" src="https://static.defensoria.to.def.br/meet-media/${data.avatar}"></div>`;
            }
            if (data.origem === 'email') {
                html += `<div class='float-left'><small class="text-muted">Enviar convite para:</small> ${data.email}</div></div>`;
            } else {
                html += `<div class='float-left'>${data.nome}</div></div>`;
            }
            return html
        },
        s2SelectUsuario(data) {
			this.selecionados.push(data);
        },
        deleteUsuario(pos, tipo=null) {
			console.log(tipo)
			this.selecionados.splice(pos, 1);
        },
	},
	mounted() {
		this.$root.$on('delete-usuario', (pos, tipo)=>this.deleteUsuario(pos, tipo))
		this.mounted = true
	},
	beforeDestroy() {
		this.$root.$off('delete-usuario')
	}
}
</script>

<style scoped>

</style>