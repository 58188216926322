<template>
    <div>
        <Header/>
        <b-alert variant="success" v-for="message in messages" :key="message" show dismissible fade>
                    {{ message }}
        </b-alert>

        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-tags v-model="form.emails" no-outer-focus class="mb-2">
        <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
            <b-input-group class="mb-2">
            <b-form-input
                v-bind="inputAttrs"
                v-on="inputHandlers"
                placeholder="Digite o email e tecle Enter"
                class="form-control"
            ></b-form-input>
            <b-input-group-append>
                <b-button @click="addTag()" variant="primary">Adicionar</b-button>
            </b-input-group-append>
            </b-input-group>
            <div class="d-inline-block" style="font-size: 1.4rem;">
            <b-form-tag @submit="onSubmit"
                v-for="tag in tags"
                @remove="removeTag(tag)"
                :key="tag"
                :title="tag"
                :variant="tagVariant"
                class="mr-1"
            >{{ tag }}</b-form-tag>
            </div>
            <!-- <p class="mt-2">Value: {{ form.emails }}</p> -->
        </template>
        
        </b-form-tags>
        <b-button @submit="onSubmit" type="submit" variant="outline-primary">Enviar convite</b-button>
    </b-form>

  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
    components: {Header},
    data() {
        return {
            messages: [],
            form: {
                emails: [],
                user_remetente: this.$store.state.user.id,
            },
            show: true
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault()
            // console.log(this.form.emails)
            this.$http.post("/tokens/", this.form)
                .then(() => {
                    this.messages.push(`Convites enviados com sucesso!`)
                })
        },
        onReset(evt) {
            evt.preventDefault()
            this.form.email = ''
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    }
}
</script>