<template>
    <div id="sala_titulo_container" :style="background">
		<div id="icon_interprete" v-if="$store.state.sala.interprete"
			v-b-tooltip.hover.bottom title="Sala com Intérprete de Linguagem de Sinais"
		>
			<font-awesome-icon icon="american-sign-language-interpreting" />
		</div>
        <b-row>
            <b-col>
                <h6 style="margin-top: .5rem; cursor: pointer" @click="toggleDetalhes">
                    <font-awesome-icon icon="crown" size="xs" style="color: goldenrod" v-if="this.$store.state.sala.nivel_usuario === 'moderador'" />
                    {{ this.titulo }}
					<span
						v-if="$store.state.sala.subsalas_ativo && $store.state.user.subsala"
						class="badge badge-pill badge-light"
						v-text="$store.state.user.subsala.nome"
					/>
                </h6>
            </b-col>
        </b-row>
        <transition name="fade"><div v-if="show_detalhes">
            <p class="small" v-html="this.$store.state.sala.descricao" />

            <small v-if="this.$store.state.sala.nivel_usuario === 'moderador'">
                <a :href="url_externo_sala" target="_blank">{{ url_externo_sala }}
                </a>
            </small><br>

            <b-row class="border-top pt-3 pb-2 text-muted" v-if="$store.state.sala.tipo !== 4">
                <b-col>
                    <font-awesome-icon :icon="icone_sala" size="sm" /><br>
                    <p style="font-size: small; margin: 0;" v-html="this.$store.state.sala.tipo_sala" />
                </b-col>
                <b-col>
                    <b>Início:</b> {{ new Date(Date.parse(this.$store.state.sala.data_inicio)).toLocaleString() }}<br>
                    <span v-if="this.$store.state.sala.data_fim">
                        <b>Fim:</b> {{ new Date(Date.parse(this.$store.state.sala.data_fim)).toLocaleString() }}
                    </span>
                </b-col>
                <b-col>
                    <b>Criado por:</b> {{ this.$store.state.sala.criador }}<br>
                    <b>Em:</b> {{ new Date(Date.parse(this.$store.state.sala.data_criacao)).toLocaleString() }}
                </b-col>
                <b-col v-if="this.$store.state.sala.privado || this.$store.state.sala.gravar">
                    <span v-if="this.$store.state.sala.privado">
                        <font-awesome-icon icon="lock" /><br>Sala Privada
                    </span>
                    <span v-if="this.$store.state.sala.gravar">
                        <font-awesome-icon icon="sd-card" /><br>Evento Gravado
                    </span>
                </b-col>
            </b-row>
            <b-row class="border-top pt-3 text-muted" v-if="$store.state.sala.tipo !== 4">
                <b-col>
                    <strong>Moderadores:</strong>
                    <ul v-for="moderador in moderadores" :key="moderador">
                        <li>{{ moderador }}</li>
                    </ul>
                </b-col>
                <b-col>
                    <strong>Apresentadores:</strong>
                    <ul v-for="apresentador in apresentadores" :key="apresentador">
                        <li>{{ apresentador }}</li>
                    </ul>
                </b-col>
                <b-col>
                    <strong>Participantes:</strong>
                    <ul v-for="participante in participantes" :key="participante">
                        <li>{{ participante }}</li>
                    </ul>
                </b-col>
            </b-row>
			<b-row>
				<b-col class="border-top text-center mt-2 mb-2 p-2">
					<span v-if="!$store.state.sala.privado"><b>URL da Sala: </b>{{ url_sala }}<br></span>
                    <qrcode :value="url_sala" :options="{ width: 100 }" />
				</b-col>
			</b-row>
			<b-row v-if="$store.state.user.is_staff">
				<b-col class="border-top border-bottom text-center mt-2 mb-2 p-2">
					<b>Servidor Jitsi: </b>{{ $store.state.sala.servico_jitsi }}<br>
					<b>URL Externa da Sala: </b><a :href="url_externo_sala" target="_blank">
					{{ url_externo_sala }}</a><br>
                    <qrcode :value="url_externo_sala" :options="{ width: 100 }" />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="text-right text-muted pt-1" style="font-size: xx-small">
					{{my_jitsi_id}}/{{tipo}}
				</b-col>
			</b-row>
        </div></transition>
    </div>
</template>

<script>
export default {
    name: 'SalaTitulo',
    props: ['moderadores', 'apresentadores', 'participantes', 'my_jitsi_id', 'tipo'],
    data(){
        return {
            show_detalhes: false,
            transparencia: .3,
        }
    },
    computed:{
		url_sala(){
			return window.location.origin + '/sala/' + this.$store.state.sala.slug
		},
        url_externo_sala(){
          return 'https://'+this.$store.state.sala.servico_jitsi+'/'+this.$store.state.sala.token
        },
        background(){
            return {backgroundColor: `rgba(0, 0, 0, ${this.transparencia.toFixed(1)})`}
        },
        icone_sala(){
            let tipo = this.$store.state.sala.tipo
            return tipo === 1 ? 'user-tie' : tipo === 2 ? 'microphone' : 'graduation-cap'
        },
        titulo(){
          if(this.$store.state.sala.tipo === 4){
            return 'Sala Instantânea'
          }else{
            return this.$store.state.sala.nome
          }
        }
    },
    methods: {
        removeTransparencia(){
            this.transparencia += .1;
            if(this.transparencia<.9){
                setTimeout(()=>this.removeTransparencia(), 100)
            }else{
                this.transparencia = .9
            }
            // document.getElementById('sala_titulo_container').style.backgroundColor=`rgba(0, 0, 0, ${this.transparencia});`
        },
        retornaTransparencia(){
            this.transparencia -= .1;
            if(this.transparencia>.3){
                setTimeout(()=>this.retornaTransparencia(), 100)
            }else{
                this.transparencia = .3
            }
            // document.getElementById('sala_titulo_container').style.backgroundColor=`rgba(0, 0, 0, ${this.transparencia});`
        },
        toggleDetalhes(){
            this.show_detalhes = !this.show_detalhes
            if(this.show_detalhes){
                this.removeTransparencia()
                this.$refs.botao.classList.add('girado_180')
                this.$refs.botao.classList.remove('girado_180_rev')
            }else{
                this.retornaTransparencia()
                this.$refs.botao.classList.add('girado_180_rev')
                this.$refs.botao.classList.remove('girado_180')
            }
        }
    }
}
</script>

<style>
@keyframes rotate180 {
    from{
        transform: rotateZ(0deg);
    }to{
        transform: rotateZ(180deg);
    }
}
@keyframes rotate180rev {
    from{
        transform: rotateZ(180deg);
    }to{
        transform: rotateZ(0deg);
    }
}

.girado_180{
    -webkit-animation: rotate180 .5s;
    -moz-animation:    rotate180 .5s;
    -o-animation:      rotate180 .5s;
    animation:         rotate180 .5s;
    transform: rotateZ(180deg);
}
.girado_180_rev{
    -webkit-animation: rotate180rev .5s;
    -moz-animation:    rotate180rev .5s;
    -o-animation:      rotate180rev .5s;
    animation:         rotate180rev .5s;
    transform: rotateZ(0deg);
}
</style>

<style scoped>
    h6{
        font-weight: lighter;
    }
    #sala_titulo_container{
        position: absolute;
        width: 900px;
        top: 0;
        left: calc(50% - 450px);
        border-radius: 0 0 5px 5px;
        right: 180px;
        color: whitesmoke;
        background-color: rgba(0, 0, 0, .3);
        padding: 3px 15px;
        text-align: center;
		z-index: 200;
    }

	#icon_interprete{
		position: absolute;
		right: 10px;
		top: 8px;
		width: 22px;
		height: 22px;
		background-color: #0194D3;
		color: whitesmoke;
		border-radius: 11px;
	}

    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    ul.horizontal li {
        display: inline-block;
        height: inherit;
        vertical-align: middle;
    }
</style>

<style>
</style>