<template>
    <b-row>
        <b-col>
            <h3 class="border-bottom">Acessos à Sala</h3>
            <RegistrosList :temposTotais="tempos_totais" v-bind:registros="registrosSala"/>
        </b-col>
    </b-row>
</template>

<script>
import RegistrosList from "@/components/widgets/RegistrosList";

export default {
    components: {RegistrosList},
    data() {
        return {
            registrosSala: [],
            registros_length: 0,
            sala: this.$store.state.sala,
            tempos_totais: [],
            interval: null
        }
    },
    watch: {
        registros_length() {
            this.$http.get("/acessos_salas/?sala=" + this.sala.id)
                .then(response => {
                    this.registrosSala = response.data
                    let registros = {}
                    let usuarios_obj = this.registrosSala.filter((el) => registros[el.usuario]? false: registros[el.usuario] = true);
                    let usuarios = new FormData();
                    usuarios.append('usuarios', JSON.stringify(usuarios_obj));
                    this.$http.post('/contabiliza_acessos_usuario/', usuarios)
                    .then(response => {
                        this.tempos_totais = response.data
                    })
                })
        }
    },
    methods: {
        getRegistrosLength() {
            this.$http.head('/acessos_salas/?sala=' + this.sala.id)
                .then(response => {
                    if (!response.headers['content-length'] || this.registros_length !== response.headers['content-length'])
                        this.registros_length = response.headers['content-length'];
                })
        }
    },
    created() {
        this.getRegistrosLength();
        this.interval = setInterval(() => this.getRegistrosLength(), 5000);
    },
    beforeDestroy: function () {
        clearInterval(this.interval)
    }
}
</script>
