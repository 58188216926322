<template>
	<div :class="mobile ? 'card_mobile' : 'card'">
		<b-modal id="modal_confirm" ref="modal_confirm" class="text-center"
				centered hide-header
				title="Confirmação" ok-title="Sim" cancel-title="Não" @ok="deletar()"
		>
            Deseja realmente deletar esta sala?
		</b-modal>
		<b-modal ref="modal_editar" title="Editar" hide-footer>
			<SalaEditar :sala="sala_editar" />
		</b-modal>
		<b-modal id="add_apresentador"
			ref="modal_add_apresentador"
			centered
			body-bg-variant="dark"
			body-text-variant="light"
			footer-bg-variant="dark"
			footer-text-variant="light"
			hide-header
			@ok="addUsuarios('apresentador', usuarios=null, $store.state.salaIdOnList)"
		>
			<AddUsuarioSala ref="add_usuarios_apresentador" tipo="apresentador" />
		</b-modal>
		<b-modal id="add_participante"
			ref="modal_add_participante"
			centered
			body-bg-variant="dark"
			body-text-variant="light"
			footer-bg-variant="dark"
			footer-text-variant="light"
			hide-header
			@ok="addUsuarios('participante', usuarios=null, $store.state.salaIdOnList)"
		>
			<AddUsuarioSala ref="add_usuarios_participante" tipo="participante" />
		</b-modal>
		<b-modal ref="modal_compartilhar" size="lg" title="Compartilhar Link" hide-footer>
			<div class="text-center">
				<p>Copie o Link abaixo e envie aos usuários que deseja compartilhar o acesso à Sala:</p><br>
				<h4 id="texto_link">{{ link_compartilhar }}</h4><br>
				<input type="hidden" id="link_compartilhar_field" :value="link_compartilhar">
				<b-button variant="outline-dark" class="mb-3" @click="copiarLink()">
					<font-awesome-icon icon="copy" /> Copiar
				</b-button>
			</div>
		</b-modal>
        <div v-if="salas.length > 0" class="position-relative" ref="lista">
			<b-list-group>
				<b-list-group-item v-for="sala in salas" :key="sala.id" @click="entrarSala(sala)" button class="flex-column align-items-start position-relative"
					@mouseover="botoes=sala.id" @mouseout="botoes=0"
				>
					<b-row class="w-100">
						<b-col cols="1">
							<b-avatar rounded
								v-if="!mobile"
								:src="sala.youtube ? sala.youtube.broadcast.snippet.thumbnails.default.url : ''"
								badge :badge-variant="sala.em_atividade ? 'success' : 'dark'"
								v-b-tooltip.hover.right :title="sala.em_atividade ? `${sala.tipo_sala} Aberta` : `${sala.tipo_sala} Fechada`"
								:variant="sala.tipo === 1 ? 'warning' : sala.tipo === 2 ? 'info' : 'primary'"
							>
								<font-awesome-icon v-if="!sala.youtube" :icon="sala.tipo === 1 ? 'user-tie' : sala.tipo === 2 ? 'microphone' : 'graduation-cap'"/>
							</b-avatar>
						</b-col>
						<b-col>
							<div class="position-absolute small" style="right: 0; top:0;">
								{{ new Date(sala.data_inicio).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }} a {{ new Date(sala.data_fim).toLocaleString('pt-br', {day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'}) }}
							</div>
							<div class="position-absolute" style="right: 0; top: 1rem;" v-show="botoes === sala.id || mobile">
								<b-button-group>
									<b-button variant="light" size="sm" v-b-tooltip.hover.top :title="sala.fixada ? 'Desafixar' : 'Fixar'" @click="fixar($event, sala, !sala.fixada)">
										<font-awesome-icon icon="thumbtack" :style="{'transform': sala.fixada ? 'rotate(180deg)' : ''}" />
									</b-button>
									<b-button v-if="($store.state.user.is_staff && !sala.finalizado) || sala.moderadores_list.includes($store.state.user.id) && !sala.finalizado " variant="light" size="sm"
											v-b-tooltip.hover.top title="Editar" @click="form_editar($event, sala)">
										<font-awesome-icon icon="pencil-alt" />
									</b-button>

									<b-button v-if="$store.state.user.is_staff" variant="light" size="sm"
										class="float-right botao-add"
										v-b-tooltip.hover.top title="Adicionar Apresentador"
										@click="abrirModal($event, 'apresentador', sala)"
									>
										<font-awesome-icon icon="user-tie" size="sm" />
									</b-button>
									
									<b-button v-if="$store.state.user.is_staff" variant="light" size="sm"
										class="float-right"
										v-b-tooltip.hover.top title="Adicionar Participante"
										@click="abrirModal($event, 'participante', sala)"
									>
										<font-awesome-icon icon="user" size="sm" />
									</b-button>

									<b-button v-if="!sala.privado" variant="light" size="sm"
											v-b-tooltip.hover.top title="Compartilhar Link"
											@click="compartilharLink($event, sala.slug)"
									>
										<font-awesome-icon icon="share-alt" />
									</b-button>
									<b-button v-if="sala.criador === $store.state.user.username && tipo !== 'finalizadas'" variant="light" size="sm"
											@click="confirm_deletar($event, sala.id)"
											v-b-tooltip.hover.top title="Deletar">
										<font-awesome-icon icon="trash" />
									</b-button>
								</b-button-group>
							</div>
							<p class="font-weight-bold mb-0">{{ sala.nome }}</p>
							<p class="text-justify mb-1 small" v-show="!mobile">{{ sala.descricao }}</p>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
			<p v-if="paginas.total > 1" class="text-center mt-2">
				<b-button-group>
					<b-button :disabled="paginas.atual === 1" variant="light" size="sm" pill
						@click="irPagina('anterior')"
					>
						<font-awesome-icon icon="chevron-left" />
					</b-button>
					<b-button v-for="pag in paginas.total" :key="pag" @click="irPagina(pag)" pill
						:disabled="paginas.atual === pag" :pressed="paginas.atual === pag"
						:variant="paginas.atual === pag ? 'outline-secondary' : 'light'" size="sm">
						{{ pag }}
					</b-button>
					<b-button :disabled="paginas.atual === paginas.total" variant="light" size="sm" pill
						@click="irPagina('proximo')"
					>
						<font-awesome-icon icon="chevron-right" />
					</b-button>
				</b-button-group>
			</p>
		</div>
		<div v-else class="sem_registros">
            <font-awesome-icon icon="folder-open" size="3x"/>
            <br>
            Sem Registros!
        </div>
        <b-modal id="modal_acessar_sala" ref="modal_acessar_sala" class="text-center"
			centered hide-footer hide-header no-close-on-esc no-close-on-backdrop>
            <SalaAcessar v-if="sala_acessar" :sala="sala_acessar" />
        </b-modal>
	</div>
</template>

<script>
import SalaAcessar from "@/components/Salas/SalaAcessar";
import SalaEditar from "@/components/Salas/SalaEditar";
import AddUsuarioSala from "@/components/widgets/AddUsuarioSala";
import {addUsuarios} from "@/modulos/sala";
export default {
	name: "SalaList",
	components: {SalaAcessar, SalaEditar, AddUsuarioSala},
	props: {
        salas: Array,
		tipo: {
			type: String,
			default: null
		},
		mobile: {
			type: Boolean,
			default: false
		},
		paginas: {
			type: Object,
			required: true,
		},
		limite: {
			type: Object,
			required: true
		},
		loading: {
			type: Boolean,
			default: true
		},
		listar_todas:{
			type: Boolean,
			default: false
		}
    },
	data() {
        return {
            options: {roomName: ''},
            postAcessoSala: {
                usuario: '',
                sala: '',
                tipo: '',
            },
			sala_acessar: null,
			sala_deletar: null,
			sala_editar: {},
			sala_compartilhar: null,
			botoes: 0
        }
    },
	computed:{
		mostra_em_atividade(){
			return this.tipo === 'ativas'
		},
		link_compartilhar(){
			return this.$store.state.default_domain + '/sala/' + this.sala_compartilhar
		}
	},
    methods: {
		addUsuarios,
        entrarSala(sala) {
			this.sala_acessar = sala
			this.$refs['modal_acessar_sala'].show()
        },
		toggleHiddenText(e){
			console.log(e)
			e.preventDefault()
			e.stopPropagation()
			e.target.style.overflow = e.target.style.overflow === 'hidden' ? 'visible' : 'hidden'
		},
		confirm_deletar(e, sala){
			e.preventDefault();
			e.stopPropagation();
			this.sala_deletar = sala
			this.$refs.modal_confirm.show()
		},
		deletar(){
			let opcoes = {
				duration: 5000,
				position: 'top-right',
				type: 'error',
				message: 'Ocorreu um erro ao excluir a sala.'
			}
			this.$http.delete(`salas/${this.sala_deletar}/`).then(resp=>{
				if(resp.status === 200) {
					this.$root.$emit('atualizar_salas')
					opcoes.message = 'Sala deletada!'
					opcoes.type = 'success'
				}else{
					opcoes.message = resp.status === 401 ? 'Você não pode deletar esta sala!' : resp.status === 403 ? 'A sala informada é inválida!' : 'Ocorreu um erro ao excluir a sala.'
				}
				this.$toast.open(opcoes);
				this.sala_deletar = null
			}, erro=>{
				console.log(erro)
				this.$toast.open(opcoes);
			})
		},
		fixar(e, sala, fixar){
			e.preventDefault();
			e.stopPropagation();
			let opcoes = {
				duration: 5000,
				position: 'top-right',
				type: 'error',
				message: 'Ocorreu um erro ao atualizar a sala.'
			}
			sala.fixada = fixar
			this.$http.put(`salas/${sala.id}/`, sala).then(resp=>{
				if(resp.status === 200) {
					this.$root.$emit('atualizar_salas')
					opcoes.message = fixar ? 'Sala fixada com sucesso!' : 'Sala desfixada com sucesso!'
					opcoes.type = 'success'
				}else{
					opcoes.message = resp.status === 401 ? 'Você não pode fixar esta sala!' : resp.status === 403 ? 'A sala informada é inválida!' : 'Ocorreu um erro ao atualizar a sala.'
				}
				this.$toast.open(opcoes);
				this.sala_deletar = null
			}, erro=>{
				console.log(erro)
				this.$toast.open(opcoes);
			})
		},
		abrirModal(e, tipo, sala){
			e.preventDefault();
			e.stopPropagation();
			this.$store.state.salaIdOnList = sala.id
			if(tipo === 'apresentador'){
				this.$refs.modal_add_apresentador.show()
			}
			else if(tipo === 'participante'){
				this.$refs.modal_add_participante.show()
			}
		},
		form_editar(e, sala){
			e.preventDefault();
			e.stopPropagation();
			this.sala_editar = sala
			this.$refs.modal_editar.show()
		},
		compartilharLink(e, slug){
			e.preventDefault();
			e.stopPropagation();
			this.sala_compartilhar = slug
			this.$refs.modal_compartilhar.show()
		},
		copiarLink(){
			console.log(this.link_compartilhar)
			let confs = {
				duration: 5000,
				position: 'top-right',
			}

			let copiar = document.querySelector('#link_compartilhar_field')
			copiar.setAttribute('type', 'text')
			copiar.select()

			try {
				let successful = document.execCommand('copy');
				if(successful) {
					confs.type = 'info'
					confs.message = 'Copiado com sucesso!'
					this.$refs.modal_compartilhar.hide()
				}else{
					confs.type = 'error'
					confs.message = 'Erro co copiar!'
				}
			} catch (err) {
				confs.type = 'error'
				confs.message = 'Erro co copiar!'
			}
			this.$toast.open(confs);

			copiar.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()
		},
		irPagina(pagina){
			this.$refs.lista.scrollTop = 0;
			if(['anterior', 'proximo'].includes(pagina)){
				pagina = pagina === 'anterior' ? this.paginas.atual - 1 : this.paginas.atual + 1
			}
			let padrao = this.limite.padrao
			let limite_max = pagina * padrao
			let limite_min = limite_max - padrao
			this.$root.$emit('ir_pagina', {
				tipo: this.tipo,
				limite_min: limite_min,
				limite_max: limite_max,
				pagina: pagina
			})
		}
    },
    created() {
		this.$store.commit('updateSala', {})
	}
}
</script>

<style scoped>
.sem_registros {
    color: silver;
    margin: 30px auto;
    font-size: small;
    text-align: center;
}
</style>