<template>
	<div class="text-center">
		<p v-if="erro"><font-awesome-icon icon="exclamation-triangle" style="color: darkred" size="2x" /></p>
		<p v-if="erro" v-html="erro" />
		<b-button v-if="erro" @click="$bvModal.hide('modal_acessar_sala')">Ok</b-button>

		<p v-if="!erro" v-html="texto_acao" />
		<b-progress v-if="!erro" variant="info" :value="valor_barra" animated></b-progress>
		<p v-if="exibir_cancelar" class="mt-2 mb-0">
			<b-button @click="cancelar">Cancelar</b-button>
		</p>
	</div>
</template>

<script>
export default {
name: "SalaAcessar",
	props: {sala: {type: Object, required: true}},
	data(){
		return{
			texto_acao: 'Solicitando informações da Sala',
			valor_barra: 0,
			erro: false,
			interval: null,
			tipo_sala: null,
			pipeline: [],
			pipeline_iniciado: false,
			exibir_cancelar: false
		}
	},
	methods:{
		addAoPipeline(valor, texto, tempo=0) {
			this.pipeline.push({valor:valor, texto:texto, tempo:(tempo > 0) ? tempo : valor * 50})
				setTimeout(()=> {
					if(this.pipeline_iniciado === false) {
						this.executarPipeline()
					}
				}, 300)
		},
		executaPipe(total){
			if(this.valor_barra >= 100){
				clearInterval(this.interval)
				this.$store.commit('updateSala', this.sala)
				this.$router.push({
					name: this.tipo_sala,
					params: {slug: this.sala.slug}
				})
			}else if(this.valor_barra === total){
				clearInterval(this.interval)
				this.executarPipeline()
			}else{
				this.valor_barra += 1
			}
		},
		executarPipeline(){
			this.pipeline_iniciado = true
			let pipe = this.pipeline.shift()
			let tempo = pipe.tempo / pipe.valor
			let total = this.valor_barra + pipe.valor
			this.texto_acao = pipe.texto
			this.interval = setInterval(()=>this.executaPipe(total), tempo)
		},
		acessarSala_passo1(){
			this.addAoPipeline(10, 'Verificando se já está acessível.', 1000)
			if(!this.sala.em_atividade && this.sala.nivel_usuario !== 'moderador' && this.sala.tipo !== 4){
				this.erro = 'A Sala não está aberta.<br>Aguarde um moderador.'
				clearInterval(this.interval)
				return false
			}else{
				this.acessarSala_passo2()
			}
		},
		acessarSala_passo2(){
			this.addAoPipeline(5, 'Verificando acessos anteriores.', 500)
			this.$http.get(`acessos_salas/?username=${this.$store.state.user.username}`)
            .then(response => {
                if(response.data.length > 0) {
                    if(response.data[0].tipo === 1){
						this.$http.post('/acessos_salas/', {
							usuario: this.$store.state.user.id,
							sala: this.sala.id,
							tipo: 2
						}).then(
							()=>{
								this.addAoPipeline(30, 'Desvinculando de sala anterior.', 9000)
								this.acessarSala_passo3()
							},
							()=>{
								this.erro = 'Você está vinculado a uma sala anteriormente acessada e ocorreu um erro ao tentar desvinculá-lo.<br>Tente novamente ou entre em contato com a equipe de suporte.'
								clearInterval(this.interval)
								return false
							}
						)
                    }else{
						this.addAoPipeline(25, 'Vinculando usuário à Sala.', 2000)
                        this.acessarSala_passo3()
                    }
                }else{
					this.addAoPipeline(30, 'Vinculando usuário à Sala.', 2000)
					this.acessarSala_passo3()
                }
            })
		},
		acessarSala_passo3(){
			this.$store.commit('updateSala', this.sala)
			this.addAoPipeline(20, 'Reunindo dados dos Participantes.', 1000)
			let integrantes = this.sala.moderadores_list
			.concat(this.sala.apresentadores_list)
			.concat(this.sala.participantes_list)
			this.addAoPipeline(10, 'Verificando permissão de acesso.', 600)
			if(this.sala.privado && !(integrantes.includes(this.$store.state.user.id)) && !this.$store.state.user.is_staff)
			{
				this.erro = 'Esta é uma sala privada e você não possui permissão de acesso!'
				clearInterval(this.interval)
				return false
			}else{
				this.addAoPipeline(10, 'Definindo tipo de Acesso.', 600)
				if (this.sala.nivel_usuario === 'moderador') {
					this.tipo_sala = this.sala.data_transfer === 'WS' ? 'sala_WS' : 'sala_moderador'
				} else if (this.sala.nivel_usuario === 'participante') {
					this.tipo_sala = this.sala.data_transfer === 'WS' ? 'sala_WS' : 'sala'
					if (this.sala.broadcast && this.sala.participantes_ativos === false) {
						this.tipo_sala = this.sala.data_transfer === 'WS' ? 'sala_espectador' : 'sala_espectador'
					}
				} else if (this.sala.nivel_usuario === 'espectador') {
					this.tipo_sala = this.sala.data_transfer === 'WS' ? 'sala_espectador' : 'sala_espectador'
				} else {
					this.tipo_sala = this.sala.data_transfer === 'WS' ? 'sala_WS' : 'sala'
				}
				this.addAoPipeline(20, 'Redirecionando...', 800)
			}
		},
		cancelar(){
			clearInterval(this.interval)
			this.$root.$emit('bv::hide::modal', 'modal_acessar_sala', '')
		}
	},
	mounted() {
		this.$store.commit('updateSala', this.sala)
		this.acessarSala_passo1()
		setTimeout(()=> {
			if (!this.erro) {
				this.exibir_cancelar = true
			}
		}, 15000)
	}
}
</script>
