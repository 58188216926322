<template>
	<b-row class="content_body" :style="bg_banner">
		<b-col cols="4" style="overflow-y: auto; height: calc(100vh - 120px)">
			<DashboardBanner />
		</b-col>
		<b-col cols="8">
			<transition name="zoom">
				<b-tabs fill pills
					v-if="component_content === 'salas'"
					:active-nav-item-class="{'btn-info': true, 'text-white-forced': true, 'nav-link': false}"
				>
					<b-tab v-if="salas.fixadas.length > 0" active>
						<template #title>
							<span>FIXADAS </span><div class="badge badge-light" v-html="quantidade.fixadas" />
						</template>
						<SalaList v-bind:salas="salas.fixadas" :loading="loading.fixadas"
								:limite="limite" :paginas="paginas.fixadas" tipo="fixadas"
								:listar_todas="listar_todas"
						/>
					</b-tab>
					<b-tab>
						<template #title>
							<span>ATIVAS </span><div class="badge badge-light" v-html="quantidade.ativas" />
						</template>
						<SalaList v-bind:salas="salas.ativas" :loading="loading.ativas"
								:limite="limite" :paginas="paginas.ativas" tipo="ativas"
								:listar_todas="listar_todas"
						/>
					</b-tab>
					<b-tab>
						<template #title>
							<span>PROGRAMADAS </span><div class="badge badge-light" v-html="quantidade.programadas" />
						</template>
						<SalaList v-bind:salas="salas.programadas" :loading="loading.programadas"
								:limite="limite" :paginas="paginas.programadas" tipo="programadas"
								:listar_todas="listar_todas"
						/>
					</b-tab>
					<b-tab>
						<template #title>
							<span>FINALIZADAS </span><div class="badge badge-light" v-html="quantidade.finalizadas" />
						</template>
						<SalaList v-bind:salas="salas.finalizadas" :loading="loading.finalizadas"
								:limite="limite" :paginas="paginas.finalizadas" tipo="finalizadas"
								:listar_todas="listar_todas"
						/>
					</b-tab>
					<b-tab>
						<template #title>
							<span><font-awesome-icon icon="search" /></span>
						</template>
						<b-form-input placeholder="Procurar Sala" class="mt-2 mb-n2" v-model="salas_pesquisa_text" />
						<SalaList v-bind:salas="salas_pesquisa"
								:limite="limite" tipo="finalizadas"
								:listar_todas="listar_todas"
								:paginas="{atual: 1, total: 1}"
								:key="JSON.stringify(salas_pesquisa)"
						/>
					</b-tab>
				</b-tabs>
				<CriacaoSala v-else />
			</transition>
		</b-col>
		<b-modal id="modal_acessar_sala" ref="modal_acessar_sala" class="text-center"
			centered hide-footer hide-header no-close-on-esc no-close-on-backdrop>
            <SalaAcessar v-if="sala_acessar" :sala="sala_acessar" />
        </b-modal>
	</b-row>
</template>

<script>
import SalaList from "@/components/Salas/SalaList";
import SalaAcessar from "@/components/Salas/SalaAcessar";
import DashboardBanner from "@/components/Dashboard/Banner";
import CriacaoSala from "@/components/widgets/CriacaoSala";
import {pararLoopConsultaYoutube} from "@/modulos/sala";

export default {
    components: {SalaList, SalaAcessar, DashboardBanner, CriacaoSala},
    data() {
        return {
			component_content: 'salas',
			salas_fixadas_head_size: 0,
			salas_ativas_head_size: 0,
			salas_programadas_head_size: 0,
			salas_finalizadas_head_size: 0,
			salas: {
				ativas: [],
				programadas: [],
				finalizadas: [],
				fixadas: []
			},
			salas_pesquisa_text: null,
			salas_pesquisa: [],
			listar_todas: false,
			limite: {
				padrao: 10,
				ativas: {min: 0, max:10},
				programadas: {min: 0, max:10},
				finalizadas: {min: 0, max:10},
				fixadas: {min: 0, max:10},
			},
			paginas: {
				ativas: {atual: 1, total: 1},
				programadas: {atual: 1, total: 1},
				finalizadas: {atual: 1, total: 1},
				fixadas: {atual: 1, total: 1}
			},
			quantidade: {
				ativas: 0,
				programadas: 0,
				finalizadas: 0,
				fixadas: 0
			},
			loading: {
				ativas: true,
				programadas: true,
				finalizadas: true,
				fixadas: true
			},
            participante: {
                data_confirmacao: '',
                usuario: '',
            },
            id_participante_temp: '',
            acesso: {
                participante: '',
                sala: '',
                entrada: '',
            },
            interval: null,
			sala_acessar: null,
			component_banner: 'DashboardBanner'
        }
    },
	computed:{
		bg_banner(){
			return 'background-image: ' +
				'linear-gradient(to right, rgba(245, 246, 252, .9) 0%, rgb(255, 255, 255) 65%),' +
				'url("'+require("@/assets/meeting-fliph.png")+'");' +
				'background-position: center; ' +
				'background-size: cover; ' +
				'background-repeat: no-repeat; ' +
				'max-height: calc(100vh - 96px)'
		},
	// 	quant_ativas(){
	// 		if(this.salas_head_size === undefined || this.salas_head_size > 0) {
	// 			return this.salas.ativas.length
	// 		}else{
	// 			return '<div class="spinner-border spinner-border-sm" role="status" />'
	// 		}
	// 	},
	// 	quant_programadas(){
	// 		if(this.salas_head_size === undefined || this.salas_head_size > 0) {
	// 			return this.salas.programadas.length
	// 		}else{
	// 			return '<div class="spinner-border spinner-border-sm" role="status" />'
	// 		}
	// 	},
	// 	quant_finalizadas(){
	// 		if(this.salas_head_size === undefined || this.salas_head_size > 0) {
	// 			return this.salas.finalizadas.length
	// 		}else{
	// 			return '<div class="spinner-border spinner-border-sm" role="status" />'
	// 		}
	// 	},
	// 	quant_fixadas(){
	// 		if(this.salas_head_size === undefined || this.salas_head_size > 0) {
	// 			return this.salas.fixadas.length
	// 		}else{
	// 			return '<div class="spinner-border spinner-border-sm" role="status" />'
	// 		}
	// 	}
	},
	watch:{
		listar_todas(){
			this.$root.$emit('valor_listar_todas', this.listar_todas)
			this.updateSalas()
		},
		salas_pesquisa_text(){
			if(this.salas_pesquisa_text.length > 3){
				this.$http.get('salas/?nome='+this.salas_pesquisa_text).then(salas=>{
					this.salas_pesquisa = salas.data.data
				})
			}else{
				this.salas_pesquisa = {}
			}
		}
	},
    methods: {
		pararLoopConsultaYoutube,
        updateSalas(){
			// ATIVAS
			if(this.quant_ativas === 0){
				this.getSalas('ativas');
			}else {
				this.$http.head('/salas/?' + this.getFilterSalas('ativas')).then(response => {
					if (!response.headers['content-length'] || response.headers['content-length'] !== this.salas_ativas_head_size || this.quant_ativas === 0) {
						this.salas_ativas_head_size = response.headers['content-length']
						this.getSalas('ativas');
					}
				})
			}
			// FIXADAS
			if(this.quant_fixadas === 0){
				this.getSalas('fixadas');
			}else {
				this.$http.head('/salas/?' + this.getFilterSalas('fixadas')).then(response => {
					if (!response.headers['content-length'] || response.headers['content-length'] !== this.salas_fixadas_head_size) {
						this.salas_fixadas_head_size = response.headers['content-length']
						this.getSalas('fixadas');
					}
				})
			}
			// PROGRAMADAS
			if(this.quant_programadas === 0){
				this.getSalas('programadas');
			}else {
				this.$http.head('/salas/?' + this.getFilterSalas('programadas')).then(response => {
					if (!response.headers['content-length'] || response.headers['content-length'] !== this.salas_programadas_head_size) {
						this.salas_programadas_head_size = response.headers['content-length']
						this.getSalas('programadas');
					}
				})
			}
			// FINALIZADAS
			if(this.quant_finalizadas === 0){
				this.getSalas('finalizadas');
			}else {
				this.$http.head('/salas/?' + this.getFilterSalas('finalizadas')).then(response => {
					if (!response.headers['content-length'] || response.headers['content-length'] !== this.salas_finalizadas_head_size) {
						this.salas_finalizadas_head_size = response.headers['content-length']
						this.getSalas('finalizadas');
					}
				})
			}
        },
        getFilterSalas(tipo){
            let agora = new Date().toISOString();
            let staff = this.listar_todas ? '&staff=true' : ''
            if(tipo === 'ativas'){
                return `data_inicio__lte=${agora}&data_fim__gte=${agora}&finalizado=false${staff}`
            }else if(tipo === 'programadas'){
                return `data_inicio__gt=${agora}${staff}`
            }else if(tipo === 'finalizadas'){
                return `data_fim__lt=${agora}&finalizado=true&limit=${this.limite.finalizadas.min},${this.limite.finalizadas.max}${staff}`
            }else if(tipo === 'fixadas'){
                return `fixadas=true${staff}`
            }
        },
        getSalas(tipo=null) {
			let tipos = (tipo === null) ? ['ativas', 'programadas', 'finalizadas', 'fixadas'] : [tipo]
			for(let tipo of tipos){
				this.loading[tipo] = true
				this.$http.get('/salas/?' + this.getFilterSalas(tipo)).then(response=> {
					this.salas[tipo] = response.data.data
					if(response.data.count > this.limite.padrao){
						let pags = response.data.count / this.limite.padrao
						this.paginas[tipo].total = pags === 0 ? 1 : pags % 1 === 0 ? pags : parseInt(pags, 10) + 1
					}
					this.quantidade[tipo] = response.data.count
					this.loading[tipo] = false
				})
			}
        },
        getUserInfo() {
            this.$http.get('/current-user/').then(response => this.participante.usuario = response['data']['id'])
        },
		toggleFormulario() {
			this.component_content = this.component_content === 'salas' ? 'formulario' : 'salas'
		},
		irPagina(pagina, tipo, limite_min, limite_max) {
			this.limite[tipo].min = limite_min
			this.limite[tipo].max = limite_max
			this.paginas[tipo].atual = pagina
			this.getSalas(tipo)
		},
		criarSalaRapido(nome=null) {
			this.$http.post('salas/', {rapido: true, nome: nome}).then(
				resp => {
					if (resp.status === 201) {
						let sala = resp.data
						this.$store.commit('updateSala', sala)
						this.sala_acessar = sala
						this.$refs['modal_acessar_sala'].show()
					}else{
            this.$toast.open({
              message: 'Erro ao criar Sala!',
              type: 'error',
              duration: 5000,
              position: 'top-right'
            });
            this.$root.$emit('hide-overlay-dashboard')
          }
				}
			)
		}
    },
    created() {
        let redirect = window.localStorage.getItem('redirect_sala')
        if(redirect && redirect.length > 0){
            window.localStorage.removeItem('redirect_sala')
			this.$http.get('/salas/?slug=' + redirect).then(response => {
				let sala = response.data.data[0]
				this.$store.commit('updateSala', sala)
				this.sala_acessar = sala
				this.$refs['modal_acessar_sala'].show()
			});
        }
		this.$session.clear();
        this.interval = setInterval(()=>this.updateSalas(), 10000);
        this.pararLoopConsultaYoutube()
    },
	mounted() {
		this.getSalas()
		this.$root.$on('criar_sala', ()=>this.toggleFormulario())
		this.$root.$on('criar_sala-rapido', nome=>this.criarSalaRapido(nome))
		this.$root.$on('exibir_banner', ()=>this.toggleFormulario())
		this.$root.$on('atualizar_salas', ()=>this.getSalas())
		this.$root.$on('ir_pagina', params=>this.irPagina(
			params.pagina, params.tipo, params.limite_min, params.limite_max
		))
		this.$root.$on('sala_atualizada', ()=>this.updateSalas())
		this.$root.$on('toggle_staff', ()=>this.listar_todas=!this.listar_todas)
		this.$root.$emit('valor_listar_todas', this.listar_todas)
	},
	beforeDestroy: function(){
        clearInterval(this.interval)
		this.$root.$off('criar_sala')
		this.$root.$off('criar_sala-rapido')
		this.$root.$off('exibir_banner')
		this.$root.$off('atualizar_salas')
		this.$root.$off('ir_pagina')
		this.$root.$off('sala_atualizada')
		this.$root.$off('toggle_staff')
    }
}
</script>

<style scoped>
.content_body{
	margin: 0 15px;
}

@keyframes zoom-1 {
	0% {
		transform: scale(0.7);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes zoom-2 {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.7);
		opacity: 0;
	}
}

.zoom-leave-active,
.zoom-enter-active {
	transition: .1s;
}
.zoom-enter {
	animation: zoom-1 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.zoom-leave-to {
	animation: zoom-2 0.1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


.card{
	/*background-color: white;*/
	background-color: transparent !important;
    border-radius: 0 0 5px 5px !important;
    padding: 10px 0;
	height: calc(100vh - 160px);
	overflow-y: auto;
	/*border-top: none !important;*/
	border: none !important;
}

div.badge{
	text-align: center;
	margin-left: 4px;
}

a.active span{
	color: white !important;
	font-weight: bold;
}

</style>

<!--<style>-->
/*h3{*/
/*    font-weight: 100;*/
/*	cursor: pointer;*/
/*}*/
/*.badge-light {*/
/*    !*color: #252729;*!*/
/*    !*background-color: #f8f9fa;*!*/
/*    font-weight: lighter;*/
/*}*/
<!--</style>-->