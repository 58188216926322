var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 pr-0",attrs:{"id":"chat"}},[_c('h3',{staticClass:"pb-2 border-bottom"},[_vm._v("Chat")]),_c('div',{ref:"msgs",attrs:{"id":"mensagens"}},_vm._l((_vm.$store.state.sala.chat_mensagens),function(m){return _c('p',{key:m.id,class:{
				'message': true,
				'message-out': m.autor_id === _vm.$store.state.user.id,
				'message-in': m.autor_id !== _vm.$store.state.user.id
			}},[_vm._v(" "+_vm._s(m.autor)+" - "+_vm._s(new Date(m.data).toLocaleTimeString())),_c('br'),_c('span',[_vm._v(_vm._s(m.mensagem))])])}),0),_c('div',{attrs:{"id":"box"}},[_c('picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmojiPicker),expression:"showEmojiPicker"}],attrs:{"showPreview":false,"set":'google',"i18n":{
				search: 'Pesquisar',
				notfound: 'Não Encontrado',
				categories:
				{
					search: 'Resultados da Pesquisa',
					recent: 'Recentes',
					people: 'Expressões e Pessoas',
					nature: 'Natureza',
					foods: 'Alimentos',
					activity: 'Atividades',
					places: 'Lugares',
					objects: 'Objetos',
					symbols: 'Símbolos',
					flags: 'Bandeiras'
				}
			}},on:{"select":_vm.addEmoji}}),_c('span',{staticClass:"emoji-trigger",class:{ 'triggered': _vm.showEmojiPicker },on:{"mousedown":function($event){$event.preventDefault();return _vm.toggleEmojiPicker.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"smile"}})],1),_c('b-form-input',{ref:"texto",staticClass:"texto",attrs:{"placeholder":"Enviar Mensagem para todos"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.texto),callback:function ($$v) {_vm.texto=$$v},expression:"texto"}}),_c('span',{staticClass:"send-button",on:{"click":_vm.sendMessage}},[_c('font-awesome-icon',{attrs:{"icon":"paper-plane"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }