<template class="fundo_escuro">
	<fullscreen @change="fullscreenChange" ref="fullscreen">
		<div ref="screen" id="screen" :class="{screen: !is_mobile, screen_mobile: is_mobile && screen_orientation === 'portrait', screen_full: is_mobile && screen_orientation === 'landscape'}">
			<div v-if="$store.state.sala.nivel_usuario === 'moderador' || ($store.state.sala.moderador_is_on && $store.state.sala.em_atividade)">
				<JitsiMeetWS ref="jitsiRef" :domain="$store.state.sala.servico_jitsi" :screen="{mobile: is_mobile, orientation: screen_orientation}" />
<!--				<JitsiMeetLibWS ref="jitsiRef" :domain="$store.state.sala.servico_jitsi"/>-->
			</div>
			<div v-else>
				<div class="ilustracao">
					<font-awesome-icon icon="chalkboard-teacher" size="6x"/>
					<br>
					<strong class="loading">Aguardando o Moderador</strong><br>
					<small>O evento iniciará quando o moderador acessar a sala.</small>
				</div>
			</div>
			<div class="botoes carregando"
				v-if="carregando && !($store.state.sala.nivel_usuario === 'moderador' || ($store.state.sala.moderador_is_on && $store.state.sala.em_atividade))">
				<span class="loading">Carregando complementos</span>
			</div>
			<div class="botoes_container" v-else-if="!is_mobile && $store.state.sala.nivel_usuario === 'moderador'">
				<div class="botoes">
					<b-button-group>
						<b-button
							variant="light"
							:class="{'text-danger': $store.state.sala.microfone_bloqueado}"
							@click="toggleBloqueioMicrofones"
							v-b-tooltip.hover.top :title="$store.state.sala.microfone_bloqueado ? 'Microfones Bloqueados' : 'Microfones Liberados'"
						>
							<font-awesome-icon :icon="$store.state.sala.microfone_bloqueado ? 'microphone-slash' : 'microphone'" />
						</b-button>
						<b-button
							variant="light"
							@click="$refs.jitsiRef.jitsiApi.executeCommand('toggleTileView')"
							v-b-tooltip.hover.top title="Mosaico de Usuários"
						>
							<font-awesome-icon icon="th-large" />
						</b-button>
					</b-button-group>
				</div>
			</div>
		</div>
		<div ref="menu" id="menu" class="menu" v-if="!is_mobile">
			<SalasMenuWS
				ref="salaMenu"
				:acessos="acessos"
				:dispositivos="dispositivos_obj"
			/>
		</div>
		<div v-else-if="screen_orientation === 'portrait'" ref="menu_mobile" id="menu_mobile" class="menu_mobile">
			<SalasMenuWSMobile />
		</div>
	</fullscreen>
</template>

<script>
import {dados, fullscreenChange, toggleFullScreen} from "@/modulos/sala";
import SalasMenuWS from "@/components/Salas/SalasMenuWS";
import JitsiMeetWS from "@/components/Salas/Jitsi/JitsiMeetWS";
import {isMobile} from 'mobile-device-detect';
import { MobileOrientation } from 'mobile-orientation'
// import JitsiMeetLibWS from "@/components/Salas/Jitsi/JitsiMeetLibWS";
import {
	dadosWS,
	atualizaDados,
	chatEnviaMensagem,
	emitMudanca,
	eventsOn,
	eventsOff,
	getDispositivos,
	muteUsuario,
	processaMudanca,
	receiveMessage,
	sendMessage,
	connect,
	disconnect,
	toggleBloqueioMicrofones,
	trataBotaoMicrofone,
	updateStoreChatMessages
} from "@/modulos/salaWS"
import SalasMenuWSMobile from "@/components/Salas/SalasMenuWSMobile";

export default {
	name: "SalaWS",
	// components: {SalasMenuWS},
	components: {SalasMenuWSMobile, SalasMenuWS, JitsiMeetWS},
	// components: {SalasMenuWS, JitsiMeetLibWS},
	computed:{
		is_mobile(){
			return isMobile
		},
		screen_orientation(){
			return this.orientation.isPortrait ? 'portrait' : 'landscape'
		}
	},
	data(){
		return{
			acessos: [],
			sala: this.$store.state.sala,
			sala_tipo: 'normal',
			my_jitsi_id: null,
			carregando: true,
			mic_mudo: true,
			cam_mudo: true,
			orientation: new MobileOrientation(),
			...dados,
			...dadosWS,
		}
	},
	methods:{
		atualizaDados,
		chatEnviaMensagem,
		emitMudanca,
		eventsOn,
		eventsOff,
		getDispositivos,
		muteUsuario,
		processaMudanca,
		connect,
		disconnect,
		fullscreenChange,
		toggleFullScreen,
		receiveMessage,
		sendMessage,
		toggleBloqueioMicrofones,
		trataBotaoMicrofone,
		updateStoreChatMessages,
		abrirContentSlide(state){
			let menuEl = document.getElementById('menu')
			let screenEl = document.getElementById('screen')
			if(state === 'open'){
				menuEl.style.width = "450px"
				screenEl.style.width = "calc(100vw - 450px)"
			}else{
				menuEl.style.width = "50px"
				screenEl.style.width = "calc(100vw - 50px)"
			}
		},
	},
	created() {
		this.connect()
		this.$store.commit('setTransition', 'slide-left')
	},
	mounted() {
		this.eventsOn()
		this.$root.$on('toggle_fullscreen', ()=>this.toggleFullScreen())
		this.$root.$on('open-content-slide', state=>this.abrirContentSlide(state))
		this.$root.$on('jitsi_mounted', ()=> {
			// LISTENERS
			this.$refs.jitsiRef.jitsiApi.addListener('audioMuteStatusChanged', evnt=>{
				console.log('AUDIO MUTE STATUS CHANGE: ', evnt['muted'])
				if(this.$store.state.ignoreEvent !== 'audioMuteStatusChanged') {
					this.$http.post('usuario_configs/',
						{
							sala: this.$store.state.sala.id,
							usuario: this.$store.state.user.id,
							microfone: !evnt['muted']
						})
				}else{
					this.$store.commit('ignoreEvent', null)
				}
				this.mic_mudo = evnt['muted']
			})
			this.$refs.jitsiRef.jitsiApi.addListener('videoMuteStatusChanged', evnt=>{
				console.log('VIDEO MUTE STATUS CHANGE: ', evnt['muted'])
				if(this.$store.state.ignoreEvent !== 'videoMuteStatusChanged') {
					this.$http.post('usuario_configs/',
						{
							sala: this.$store.state.sala.id,
							usuario: this.$store.state.user.id,
							video: !evnt['muted']
						})
				}else{
					this.$store.commit('ignoreEvent', null)
				}
				this.cam_mudo = evnt['muted']
			})
			this.$refs.jitsiRef.jitsiApi.addListener('readyToClose', ()=>{
				this.disconnect()
				// this.$router.push({name: 'home'}).catch(()=>{})
			})
			this.$refs.jitsiRef.jitsiApi.addListener('videoConferenceJoined', ()=>{
				this.carregando = false;
			})

			this.carregando = false;
			// ENVIA AO BACKEND O ID DO PARTICIPANTE DO JITSI
			let parts = this.$refs.jitsiRef.jitsiApi.getParticipantsInfo()
			parts.forEach(part => {
				if (this.$store.state.user.nome === part['displayName']) {
					this.my_jitsi_id = part['participantId']
					this.$http.post('usuario_configs/', {
						'usuario': this.$store.state.user.id,
						'sala': this.$store.state.sala.id,
						'jitsi_id': part['participantId']
					})
				}
			})

			// ATUALIZA LISTA DE DISPOSITIVOS
			this.getDispositivos()

			// CRIA UMA VARIÁVEL GLOBAL PARA REFERÊNCIA À INSTÂNCIA DO JITSI
			window.jitsiRef = this.$refs.jitsiRef
		})
	},
	beforeDestroy() {
		this.disconnect()
		this.eventsOff()
		// this.$refs.jitsiRef.jitsiApi.executeCommand('hangup')
		this.$root.$off('toggle_fullscreen')
		this.$root.$off('open-content-slide')
		this.$root.$off('jitsi_mounted')
	},
}
</script>

<style scoped>
	.screen{
		width: calc(100vw - 50px);
		height: 100vh;
		float: left;
		transition: all .2s linear;
		position: relative;
	}
	.screen_mobile{
		width: 100vw;
		height: 40vh;
	}
	.screen_full{
		width: 100vw;
		height: 100vh;
	}
	.botoes{
		position: absolute;
		bottom: 15px;
		right: 100px;
		opacity: .35;
	}
	.botoes:hover{
		opacity: 1;
	}
	.botoes.carregando{
		width: 250px;
		height: 38px;
		text-align: center;
		background-color: #666;
		color: white;
		border-radius: 4px;
		padding-top: .5rem;
		opacity: 1;
	}
	.menu{
		width: 50px;
		height: 100vh;
		float: left;
		background-color: whitesmoke;
		transition: all .2s linear;
	}
	.menu_mobile{
		width: 100vw;
		background-color: white;
		height: 60vh;
	}
</style>