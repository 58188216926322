export var dados = {
    acesso_sala: [],
    acesso_sala_count: 0,
    acesso_sala_length: 0,
    ballon_avatar: true,
    chat_visible: false,
    component_modal: "Registros",
    chat_nao_lidas: 0,
    chat_options:{
        icon: 'comments',
        position:{top: (document.documentElement.clientHeight - 510)+'px', left: '65px'},
        width: '300px',
        height: '500px'
    },
    comandos_length: 0,
    comandos_executados: [],
    countdown: null,
    countdown_ativo: false,
    countdown_tempo: null,
    dispositivos_obj: {
        disponiveis: {
            microfones: {},
            altofalantes: {},
            cameras: {}
        },
        ativos: {
            microfones: {},
            microfones_list: [],
            altofalantes: {},
            altofalantes_list: [],
            cameras: {},
            cameras_list: []
        }
    },
    em_atividade: null,
    fullscreen: false,
    interval: null,
    is_mounted: false,
    moderador_is_on: null,
    mensagens_options:{},
    mensagens_visible: false,
    microfone_bloqueado: false,
    mudo: true,
    my_jitsi_id: null,
    participante: {
        usuario: '',
        data_confirmacao: new Date
    },
    show_modal: false,
    tempo_15: false,
    total_height: document.documentElement.clientHeight,
    total_width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) - 50,
    usuario_length: 0
}

// COMPUTED
export function apresentadores_nomes(){
    if(this.$store.state.sala &&
        !('apresentadores' in this.$store.state.sala) ||
        !this.$store.state.sala.apresentadores.length === 0) return null
    let itens = []
    this.$store.state.sala.apresentadores.forEach(item=>itens.push(item['nome']))
    return itens
}
export function apresentadores_users(){
    if(this.$store.state.sala &&
        !('apresentadores' in this.$store.state.sala) ||
        !this.$store.state.sala.apresentadores.length === 0) return null
    let itens = []
    this.$store.state.sala.apresentadores.forEach(item=>itens.push(item['username']))
    return itens
}
export function camera_status(){
    return this.$store.state.user.video
}
export function finalizouHorario() {
    let inicio = new Date(this.$store.state.sala.data_inicio)
    let fim = this.$store.state.sala.data_fim !== null ? new Date(this.$store.state.sala.data_fim) : new Date(
        inicio.getFullYear(), inicio.getMonth() + 1, inicio.getDate(), 23, 59, 59
    )
    return new Date() > fim && !this.moderador_is_on
}
export function iniciouHorario() {
    // return new Date() > new Date(this.$store.state.sala.data_inicio)
    return true
}
export function moderadores_nomes(){
    if(this.$store.state.sala &&
        !('moderadores' in this.$store.state.sala) ||
        !this.$store.state.sala.moderadores.length === 0) return null
    let itens = []
    this.$store.state.sala.moderadores.forEach(item=>itens.push(item['nome']))
    return itens
}
export function moderadores_users(){
    if(this.$store.state.sala &&
        !('moderadores' in this.$store.state.sala) ||
        !this.$store.state.sala.moderadores.length === 0) return null
    let itens = []
    this.$store.state.sala.moderadores.forEach(item=>itens.push(item['username']))
    return itens
}
export function participantes_nomes(){
    if(this.$store.state.sala &&
        !('participantes' in this.$store.state.sala) ||
        !this.$store.state.sala.participantes.length === 0) return null
    let itens = []
    this.$store.state.sala.participantes.forEach(item=>itens.push(item['nome']))
    return itens
}
export function participantes_users(){
    if(this.$store.state.sala &&
        !('participantes' in this.$store.state.sala) ||
        !this.$store.state.sala.participantes.length === 0) return null
    let itens = []
    this.$store.state.sala.participantes.forEach(item=>itens.push(item['username']))
    return itens
}
export function tipoPlugin() {
    let sala = this.$store.state.sala
    if(!sala.participantes_ativos && !['moderador', 'apresentador'].includes(sala.nivel_usuario)){  // EVENTO É LIVE, MAS O USUÁRIO É APENAS ESPECTADOR
            return 'youtube'
    }
    return 'jitsi'
}
export function token_sala(){
    if(this.$store.state.sala.interprete !== null && this.$store.state.sala.nivel_usuario === 'apresentador'){
        return this.$store.state.sala.token_complementar
    }else if(this.$store.state.sala.nivel_usuario === 'moderador'){
        return this.$store.state.sala.token
    }else {
        return this.$store.state.sala.subsalas_ativo && this.$store.state.user.subsala.token ? this.$store.state.user.subsala.token : this.$store.state.sala.token
    }
}
export function tempo_subsalas_ativas(){
    let horas = Math.floor(this.subsalas_ativas_segundos / 3600)
    let minutos = Math.floor((this.subsalas_ativas_segundos - (horas * 3600)) / 60)
    let segundos = this.subsalas_ativas_segundos - (horas * 3600) - (minutos * 60)
    horas = horas < 10 ? '0'+horas : horas
    minutos = minutos < 10 ? '0'+minutos : minutos
    segundos = segundos < 10 ? '0'+segundos : segundos
    return `${horas}:${minutos}:${segundos}`
}

// MÉTODOS
export function abreSalaInterprete(){
    let params = [
        'height='+screen.height,
        'width='+screen.width,
    ].join(',');
    let popup = window.open('https://newmeet.defensoria.to.def.br/' + this.$store.state.sala.token_complementar, 'popup_window', params);
    popup.moveTo(0,0);
}
export function abrirConfiguracoes(){
    this.getDispositivos(()=>this.$bvModal.show('modal-configuracoes'))
}
export function abrirSubsalas(){
    this.modal_subsalas_size = 'md'
    this.$bvModal.show('modal-subSala')
}

export function addUsuarios(tipo, usuarios=null, sala=this.$store.state.sala.id){
    if(usuarios === null) {
        usuarios = this.$refs[`add_usuarios_${tipo}`].selecionados
    }
    if(usuarios.length > 0){
        this.$http.post('/sala/usuarios/', {
            sala: sala,
            usuarios: usuarios,
            tipo: tipo
        }).then(
            resp=>{
                let listObjectsEmail = usuarios.filter(o => o.origem === 'email')
                let listObjectsEventos = usuarios.filter(o => o.origem === 'eventos')
                if(listObjectsEmail.length > 0 || listObjectsEventos.length > 0){
                    this.$http.post('/tokens/', {
                        'sala': sala,
                        'user_remetente': this.$store.state.user.id,
                        'list_objects_origem_email': listObjectsEmail,
                        'list_objects_origem_eventos': listObjectsEventos, 
                    })
                    .then(() => {
                        this.$toast.open({
                            message: 'Email enviado com sucesso!',
                            type: 'success',
                            duration: 5000,
                            position: 'top-right'
                        })
                    })
                }
                console.log(resp)
                this.$toast.open({
                    message: 'Usuários adicionados com sucesso!',
                    type: 'success',
                    duration: 5000,
                    position: 'top-right'
                })
            },
            erro=>{
                console.log(erro)
                this.$toast.open({
                    message: 'Ocorreu um erro ao tentar adicionar usuários à Sala!',
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                })
            }
        )
    }
}
export function atualizaDadosSala(e){
    e.preventDefault()
    this.$http.put(`salas/${this.$store.state.sala.id}/`, this.formEditarSala)
    .then(response => {
        console.log(response)
        this.$toast.open({
            message: 'Sala atualizada com sucesso!',
            type: 'success',
            duration: 5000,
            position: 'top-right'
        })
    })
    this.$bvModal.hide('modal-editar-sala')
}
export function bloqueioMicrofones(){
    if(this.microfones_bloqueados){
        this.$refs.jitsiRef.mudo(false);
        this.microfones_bloqueados = false
    }else{
        this.$refs.jitsiRef.mudo(true);
        this.microfones_bloqueados = true
    }
}
export function cancelaCountdown(){
    this.moderador_is_on = true
    clearInterval(this.countdown)
    this.countdown = null
    this.countdown_ativo = false
    this.countdown_tempo = '5:00'
}
export function editarSala(){
    let slug = this.$route.params.slug
    this.$http.get(`/salas/?slug=${slug}`)
    .then(response => {
        this.formEditarSala.nome = response.data.data[0].nome
        this.formEditarSala.descricao = response.data.data[0].descricao
        this.formEditarSala.tipo = response.data.data[0].tipo
        this.formEditarSala.gravar = response.data.data[0].gravar
        this.formEditarSala.privado = response.data.data[0].privado
        this.formEditarSala.data_inicio = new Date(response.data.data[0].data_inicio)
        this.formEditarSala.data_fim = new Date(response.data.data[0].data_fim)
    })
}
export function enviaBroadcastManual(){
    this.show_overlay_youtube = true
    this.$http.post('youtube_live/', {
        slug: this.$store.state.sala.slug,
        tipo: 'definir',
        broadcast: this.broadcast_manual
    }).then(resp=>{
        console.log(resp)
        this.$root.$emit('sala_atualizada')
        this.youtube_stats = {
            broadcast: resp.data.broadcast,
            stream: resp.data.stream,
            infos: resp.data.infos,
            transmitindo: resp.data.broadcast.status.lifeCycleStatus === 'live'
        }
        this.show_overlay_youtube = false
    }, err=> {
        this.$toast.open({
            message: 'Ocorreu um erro ao informar o Broadcast.',
            type: 'error',
            duration: 5000,
            position: 'top-right'
        });
        console.log(err)
        this.show_overlay_youtube = false
    })
}
export function finalizarEvento(){
    this.$http.post(`status_sala/${this.$store.state.sala.id}/`, {
        sala: this.$store.state.sala.id,
        finalizar: true
    }).then(resp=>{
        if(resp.status === 200){
            this.$toast.open({
                message: 'Sala finalizada!',
                type: 'success',
                duration: 5000,
                position: 'top-right'
            });
            if(this.is_alive) this.setLiveYoutube('finalizar')
            if(this.jitsi_gravando) this.pararGravacao()
            this.$refs.jitsiRef.executeCommand('hangup')
            if(this.$store.state.sala.proximo_evento){
                this.redirecionaProximoEvento()
            }
        }
    })
}
export function finalizarEventoConfirm(){
    this.$bvModal.show('modal-confirm_finalizar')
}
export function finalizarSubsalas(){
    this.$bvModal.hide('modal-subSala')
    let sala = this.$store.state.sala
    sala.subsalas_ativo = false
    this.$http({
        method: 'put',
        url: `/salas/${sala.id}/`,
        data: sala
    }).then(response=>{
        if(response.status === 200){
            this.$toast.open({
                message: 'Sub-Salas finalizadas com sucesso!',
                type: 'success',
                duration: 5000,
                position: 'top-right'
            });
            clearInterval(this.subsalas_interval)
            this.subsalas_interval = null
            this.subsalas_ativas_segundos = 0
        }else{
            this.$toast.open({
                message: 'Erro ao finalizar Sub-Salas.',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            });
        }
    })
}
export function fullscreenChange(fullscreen) {
    this.fullscreen = fullscreen;
}
export function getAcessoSalaLength(exibir=true){
    this.$http.head(`/acessos_salas/?sala=${this.$store.state.sala.id}`).then(resp=>{
        if(!resp.headers['content-length'] || this.acesso_sala_length !== resp.headers['content-length']){
            this.$http.get(`/acessos_salas/?sala=${this.$store.state.sala.id}`).then(r=>{
                if(exibir){
                    for(let i=0; i < r.data.length-this.acesso_sala_count; i++){
                        let tipo = r.data[i].tipo === 1 ? 'entrou na' : 'saiu da';
                        this.$toast.open({
                            message: `${r.data[i].nome} ${tipo} sala.`,
                            type: 'info',
                            duration: 3000,
                            position:'top-right'
                        });
                    }
                }
                this.acesso_sala_count = r.data.length;
            })
        }
        this.acesso_sala_length = resp.headers['content-length'];
    })
}
export function getChatNaoLidas(){
    this.chat_nao_lidas = this.$refs.chat_component.mensagens_novas
}
export function getComandos(){
    if(this.sala_tipo === 'moderador' || (this.moderador_is_on && this.em_atividade)) {
        this.$http.head(`sala/comandos/?sala=${this.$store.state.sala.id}`).then(
            r => {
                if (!r.headers['content-length'] || this.comandos_length !== r.headers['content-length']) {
                    this.$http.get(`sala/comandos/?sala=${this.$store.state.sala.id}`).then(
                        resp => {
                            let comandos = resp['data']
                            comandos.forEach(comando => {
                                if (!this.comandos_executados.includes(comando.id)) {
                                    if (comando.tipo === 1 && this.$refs.jitsiRef.is_mounted) {
                                        // let cmd = comando.tipo === 1 ? `jitsiApi.${comando.comando}(${comando.parametros})` : comando.comando
                                        // console.log(cmd)
                                        this.comandos_length = resp.headers['content-length'];
                                        this.$refs.jitsiRef.executeCL(`jitsiApi.${comando.comando}(${comando.parametros})`)
                                        this.comandos_executados.push(comando.id)
                                    } else if (comando.tipo === 3) {
                                        this.comandos_length = resp.headers['content-length'];
                                        eval(comando.comando)
                                        this.comandos_executados.push(comando.id)
                                    }
                                }
                            })
                        }
                    )
                }
            }
        )
    }
}
export function getDispositivos(callback=null){
    this.$refs.jitsiRef.jitsiApi.isDeviceListAvailable().then(isAvailable=>{
        if(isAvailable){
            this.$refs.jitsiRef.jitsiApi.getAvailableDevices().then(devices=>{
                this.dispositivos_obj.disponiveis.microfones = devices.audioInput;
                this.dispositivos_obj.disponiveis.altofalantes = devices.audioOutput;
                this.dispositivos_obj.disponiveis.cameras = devices.videoInput;
                this.$refs.jitsiRef.jitsiApi.getCurrentDevices().then(devices=>{
                    this.dispositivos_obj.ativos.microfones = devices.audioInput;
                    if(devices.audioInput && 'deviceId' in devices.audioInput){
                        this.dispositivos_obj.ativos.microfones_list = [devices.audioInput.deviceId]
                    }else if(devices.audioInput){
                        for(let disp of devices.audioInput){
                            this.dispositivos_obj.ativos.microfones_list.push(disp.deviceId)
                        }
                    }
                    this.dispositivos_obj.ativos.altofalantes = devices.audioOutput;
                    if(devices.audioOutput && 'deviceId' in devices.audioOutput){
                        this.dispositivos_obj.ativos.altofalantes_list = [devices.audioOutput.deviceId]
                    }else if(devices.audioOutput){
                        for(let disp of devices.audioOutput){
                            this.dispositivos_obj.ativos.altofalantes_list.push(disp.deviceId)
                        }
                    }
                    this.dispositivos_obj.ativos.cameras = devices.videoInput;
                    if(devices.videoInput && 'deviceId' in devices.videoInput){
                        this.dispositivos_obj.ativos.cameras_list = [devices.videoInput.deviceId]
                    }else if(devices.videoInput){
                        for(let disp of devices.videoInput){
                            this.dispositivos_obj.ativos.cameras_list.push(disp.deviceId)
                        }
                    }
                    if(callback) callback()
                })
            })
        }else{
            this.$toast.open({
                message: 'Não é possível consultar a lista de dispositivos instalados na máquina!',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            })
        }
    });
}
export function getInfosUsuarios(){
    if(this.moderador_is_on) {
        let usuarios = this.$refs.jitsiRef.getUsuariosInfos()
        if(usuarios && usuarios.length > 0) {
            usuarios.forEach(usuario => {
                let encontrado = false
                this.$store.state.sala.apresentadores.forEach(apresentador => {
                    if (apresentador.nome === usuario['displayName']) {
                        encontrado = true
                        if (!('jitsiId' in apresentador)) {
                            this.$store.commit('setUsuarioJitsiId', {
                                jitsiId: usuario['participantId'],
                                tipo: 'apresentadores',
                                id: apresentador.id
                            })
                        }
                    }
                })
                if (!encontrado) {
                    this.$store.state.sala.moderadores.forEach(moderador => {
                        if (moderador.nome === usuario['displayName']) {
                            encontrado = true
                            if (!('jitsi_id' in moderador)) {
                                this.$store.commit('setUsuarioJitsiId', {
                                    jitsiId: usuario['participantId'],
                                    tipo: 'moderadores',
                                    id: moderador.id
                                })
                            }
                        }
                    })
                }
                if (!encontrado) {
                    this.$store.state.sala.participantes.forEach(participante => {
                        if (participante.nome === usuario['displayName']) {
                            encontrado = true
                            if (!('jitsi_id' in participante)) {
                                this.$store.commit('setUsuarioJitsiId', {
                                    jitsiId: usuario['participantId'],
                                    tipo: 'participantes',
                                    id: participante.id
                                })
                            }
                        }
                    })
                }
            })
        }
    }
}

export function deleteVinculoYoutube(){
    if(confirm('Tem certeza que deseja deletar esta live?')){
        return new Promise((resolve, reject) => {
            this.$http.delete('/cria_vinculo_youtube/', {
                params:{slug: this.$store.state.sala.slug}
            }).then(
                resp=>{
                    console.log(resp)
                    this.$root.$emit('sala_atualizada')
                    resolve()
                },
                erro=>{
                    reject(erro)
                } 
            )
        })
    }
}

export function criaVinculoYoutube(){
    return new Promise((resolve)=>{
        this.$http.post('/cria_vinculo_youtube/', {
            slug:this.$store.state.sala.slug
        }).then(
            ()=>{
                this.getLiveYoutube()
                this.$root.$emit('sala_atualizada')
                resolve()
            },
            erro=>{
                this.$toast.open({
					message: erro.response.data,
					type: 'error',
					duration: 5000,
					position: 'top-right'
				})
            }
        )
    })
}

export function getLiveYoutube(){
    return new Promise((resolve, reject)=>{
        this.$http.get('/youtube_live/', {
            params:{slug: this.$store.state.sala.slug}
        }).then(
            resp=>{
                console.log('consultou youtube')
                console.log(resp.data)
                this.youtube_stats = {
                    broadcast: resp.data.broadcast,
                    stream: resp.data.stream,
                    infos: resp.data.infos,
                    transmitindo: resp.data.broadcast.items[0].status.lifeCycleStatus === 'live',
                    ultima_atualizacao: new Date()
                }
                console.log(resp.data.stream.snippet.title)
                this.show_overlay_youtube = false
                this.$root.$emit('youtube_respondeu')
                resolve()
            },
            erro=> {
                console.log('ERRO AO CONSULTAR YOUTUBE: ', erro)
                this.show_overlay_youtube = false
                this.$root.$emit('youtube_respondeu')
                reject(erro)
            }
        )
    })
}
export function getSala(callback=null){
    this.$http.get(`/salas/?slug=${(this.$route.params.slug)}`).then(resp=>{
        this.sala = resp.data.data[0]
        this.$store.commit('updateSala', resp.data.data[0])
        if(callback) callback()
    })
}
export function getStatusSala(){
    if(this.sala_tipo === 'moderador'){
        this.moderador_is_on = true
        this.$http.get('/status_sala/'+this.$store.state.sala.id+'/')
            .then(response=>{
                let resp = response.data
                console.log(resp)
                this.em_atividade = resp['em_atividade']
                this.$store.commit('updateSalaInfos', {
                    moderadores: resp['moderadores'],
                    apresentadores: resp['apresentadores'],
                    participantes: resp['participantes'],
                    microfone_bloqueado: resp['microfone_bloqueado'],
                    finalizado: resp['finalizado'],
                    subsalas_ativo: resp['subsalas_ativo'],
                    servico_jitsi: resp['servico_jitsi']
                })
            });
    }else {
        if (this.$store.state.sala.id) {
            this.$http.get('/status_sala/' + this.$store.state.sala.id + '/').then(response => {
                let resp = response.data
                if (!this.moderador_is_on && resp['moderador_is_on']) {
                    // MODERADOR ESTAVA OFFLINE E FICOU ONLINE
                    this.cancelaCountdown();
                    setTimeout(() => this.moderador_is_on = resp['moderador_is_on'], 3000)
                } else if (this.moderador_is_on && this.em_atividade && !resp['moderador_is_on']) {
                    // MODERADOR ESTAVA ONLINE E FICOU OFFLINE. A SALA ESTAVA ATIVA
                    if (!this.countdown_ativo) this.iniciaCountdown()
                }

                if (resp['em_atividade']) {
                    // SALA ESTÁ EM ATIVIDADE (POSSUI MODERADOR)
                    this.em_atividade = true
                } else if (this.em_atividade && !this.countdown_ativo) {
                    // SALA JÁ ESTEVE ATIVA, SE DESATIVOU, RETORNO DO MODERADOR FINALIZADO
                    this.em_atividade = false
                }
                // TRATA A ATIVAÇÃO/DESATIVAÇÃO DAS SUBSALAS
                if(this.$store.state.sala.subsalas_ativo !== resp['subsalas_ativo'] || this.$store.state.sala.subsalas_ativo === true){
                    if(resp['subsalas_ativo'] !== true){
                        this.$store.commit('updateSubsala', false)
                    }else{
                        let subsalas = this.$store.state.sala.subsalas;
                        let subsala = null
                        for(let s in subsalas) {
                            subsala = subsalas[s].participantes.filter(p=>p.user_id === this.$store.state.user.id)
                            if(subsala.length > 0){
                                this.$store.commit('updateSubsala', subsalas[s])
                            }
                        }
                        this.$root.$emit(resp['subsalas_ativo'] ? 'entrar_subsalas' : 'sair_subsalas')
                    }
                }
                // ATUALIZA A SALA NO VUEX
                this.$store.commit('updateSalaInfos', {
                    microfone_bloqueado: resp['microfone_bloqueado'],
                    finalizado: resp['finalizado'],
                    subsalas_ativo: resp['subsalas_ativo'],
                    servico_jitsi: resp['servico_jitsi']
                })
                this.microfone_bloqueado = resp['microfone_bloqueado']
            })
        }
    }
}
export function getStatusUsuario(){
    this.$http.head(`usuario_configs/?usuario=${this.$store.state.user.id}`).then(
        resp=>{
            if(!resp.headers['content-length'] || resp.headers['content-length'] !== this.usuario_length) {
                this.$http.get(`usuario_configs/?usuario=${this.$store.state.user.id}`).then(
                resp=> {
                    if(this.tempo_15 && resp.data['ultimo_acesso'] === 2){
                        this.$store.dispatch('userLogout')
                        this.$router.push({name: "login"})
                    }
                    // QUANDO REATIVAR ESTA PARTE DO CÓDIGO, LEMBRAR DE COLOCAR A CONDICIONAL PARA SALA ESPECTADOR
                    // if(this.mudo === resp.data['microfone'] || this.camera !== resp.data['video']){
                    //     this.mudo = !resp.data['microfone']
                    //     this.$refs.jitsiRef.jitsiApi.isAudioMuted().then(esta_mudo=>{
                    //         if(esta_mudo === resp.data['microfone']) {
                    //             this.$refs.jitsiRef.toggleMicrofone()
                    //         }
                    //     })
                    //     this.$refs.jitsiRef.jitsiApi.isVideoMuted().then(esta_mudo=>{
                    //         if(esta_mudo !== resp.data['video']){
                    //             this.$refs.jitsiRef.toggleCamera()
                    //         }
                    //     })
                    //     this.$store.commit('updateUserInfos', {
                    //         microfone: resp.data['microfone'],
                    //         video: resp.data['video']
                    //     })
                    // }
                })
            }
        }
    );
}
export function iniciaCountdown(){
    this.countdown_tempo = '5:00'
    this.ballon_avatar = false
    let min = parseInt(this.countdown_tempo.split(':')[0]), seg = parseInt(this.countdown_tempo.split(':')[1])
    this.countdown = setInterval(()=>{
        seg = seg === 0 ? 59 : seg - 1
        min = seg === 59 ? min - 1 : min
        this.$http.get(`/status_sala/${this.$store.state.sala.id}/`).then(r=>{
            if(r.data['moderador_is_on']) this.cancelaCountdown()
        })
        if(min === -1){
            this.moderador_is_on = false
            clearInterval(this.countdown)
            this.countdown = null
            this.countdown_ativo = false
            return
        }else{
            this.countdown_tempo =  min + ':' + (seg < 10 ? '0'+seg : seg)
        }
    }, 1000)
    this.countdown_ativo = true
}
export function iniciarGravacao(){
    this.loading_painel = true
    this.$refs.jitsiRef.iniciarGravacao()
}
export function iniciarSubsalas(){
    this.$bvModal.hide('modal-subSala')
    let sala = this.$store.state.sala
    sala.subsalas_ativo = true
    this.$http({
        method: 'put',
        url: `/salas/${sala.id}/`,
        data: sala
    }).then(response=>{
        if(response.status === 200){
            this.$toast.open({
                message: 'Sub-Salas iniciadas com sucesso!',
                type: 'success',
                duration: 5000,
                position: 'top-right'
            });
            this.subsalas_interval = setInterval(()=>{
                this.subsalas_ativas_segundos += 1
            }, 1000)
        }else{
            this.$toast.open({
                message: 'Erro ao iniciar Sub-Salas.',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            });
        }
    })
}
export function iniciaLoopConsultaYoutube(tempo=60000){
    if(this.interval_youtube_status !== null) {
        this.pararLoopConsultaYoutube()
    }
    this.interval_youtube_status = setInterval(() => {
        this.getLiveYoutube()
    }, tempo)
}
export function irHome(bvModalEvt) {
    bvModalEvt.preventDefault()
    this.$router.push({name:'home'})
}
export function jitsiCmd(comando, args={}){
    this.$refs.jitsiRef.executeCommand(comando, args)
}
export function jitsiId(){
    if(this.moderador_is_on && this.em_atividade) {
        let parts = this.$refs.jitsiRef.getUsuariosInfos()
        parts.forEach(part => {
            if (this.$store.state.user.nome === part['displayName']) {
                this.my_jitsi_id = part['participantId']
                this.$http.post('usuario_configs/', {
                    'usuario': this.$store.state.user.id,
                    'sala': this.$store.state.sala.id,
                    'jitsi_id': part['participantId']
                })
            }
        })
    }
}
export function logout() {
    this.$router.push({name: "login"})
}
export function openCL(){
    this.$refs.jitsiRef.cl_modal = true
}
export function pararGravacao(){
    this.loading_painel = true
    this.$refs.jitsiRef.pararGravacao()
}
export function pararLoopConsultaYoutube(){
    clearInterval(this.interval_youtube_status)
    this.interval_youtube_status = null
}
export function redirecionaProximoEvento(){
    if(this.$store.state.sala.proximo_evento_slug) {
        window.localStorage.setItem('redirect_sala', this.$store.state.sala.proximo_evento_slug)
    }
    window.location.href = window.location.origin
}
export function registraEntradaUsuario() {
    if(!this.verificaPermissao()){
        this.$toast.open({
            message: 'Você não tem permissão para acessar esta sala!',
            type: 'error',
            duration: 5000,
            position: 'top-right'
        });
        this.$router.push({name: 'home'});
    }
    this.$http.post('/acessos_salas/', {
        usuario: this.$store.state.user.id,
        tipo: 1,
        sala: this.$store.state.sala.id
    })
}
export function registraSaidaUsuario() {
    this.$http.post('/acessos_salas/', {
        usuario: this.$store.state.user.id,
        tipo: 2,
        sala: this.$store.state.sala.id
    })
    if(this.getIniciarJitsi && this.iniciouHorario && !this.finalizouHorario && this.moderador_is_on && this.evento_iniciado) {
        this.$refs.jitsiRef.removeJitsiWidget();
    }
}
export function rightClickHandler(e){
    e.preventDefault();
}
export function setLiveYoutube(tipo='iniciar', exibe_modal=true){
    this.loading_painel = true
    if(tipo === 'finalizar' && exibe_modal){
        this.$bvModal.show('modal-confirm_finalizar_transmissao')
        return true
    }
    let dataset = {
        slug: this.$store.state.sala.slug,
        tipo: tipo
    }
    this.$http.post('/youtube_live/', dataset).then(
        resp=>{
            console.log(resp)
            if(resp.status === 200) {
                let msg = tipo === 'iniciar' ? 'Foi solicitado ao Youtube que inicie a Transmissão. Acompanhe no Monitor.' : 'Foi solicitado ao Youtube a finalização da Transmissão. Acompanhe no Monitor.'
                let status = tipo === 'iniciar' ? 'live' : 'complete'
                let verificar = () => {
                    this.$http.get('/youtube_live/', {
                        params: {slug: this.$store.state.sala.slug}
                    }).then(
                        resp => {
                            this.youtube_stats = {
                                broadcast: resp.data.broadcast,
                                stream: resp.data.stream,
                                infos: resp.data.infos,
                                transmitindo: resp.data.broadcast.items[0].status.lifeCycleStatus === 'live',
                                ultima_atualizacao: new Date()
                            }
                            let bc = resp.data.broadcast
                            if (bc.items.length > 0 && bc.items[0].status.lifeCycleStatus === status) {
                                this.loading_painel = false
                                this.$toast.open({
                                    message: msg,
                                    type: 'success', duration: 5000, position: 'top-right'
                                });
                                if (tipo === 'iniciar') {
                                    this.is_alive = true;
                                    this.iniciaLoopConsultaYoutube(60000)
                                } else if (tipo === 'finalizar') {
                                    this.is_alive = false
                                    this.pararLoopConsultaYoutube()
                                    clearInterval(this.interval_youtube_status)
                                    this.interval_youtube_status = null
                                    this.$root.$emit('parar_gravacao')
                                }
                            } else {
                                setTimeout(() => verificar(), 3000)
                            }
                        },
                        erro => console.log('ERRO AO CONSULTAR YOUTUBE: ', erro),
                    ).finally(() => this.loading_painel = false)
                }
                setTimeout(() => {
                    verificar()
                }, 3000)
            }else{
                this.$toast.open({
                    message: 'O Youtube recusou a alteração de Status. Aguarde alguns segundos e tente novamente ',
                    type: 'error', duration: 5000, position: 'top-right'
                });
                this.loading_painel=false
            }
        },
        err=>{
            console.log(err);
            this.$toast.open({
                message: 'Ocorreu um erro na inicialização da Live.',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            })
        }
    ).finally(()=>this.loading_painel=false)
}
export function showRecursos() {
    this.show_modal = false;
    this.component_modal = "Recursos";
    this.show_modal = true;
}
export function showRegistros() {
    this.show_modal = false;
    this.component_modal = "Registros";
    this.show_modal = true;
}
export function toggleCamera(){
    this.$http.post(`usuario_configs/`,
        {
            sala: this.$store.state.sala.id,
            usuario: this.$store.state.user.id,
            camera: !this.camera
        }
    ).then(
        resp=>{
            if(resp.status === 200){
                this.$refs.jitsiRef.jitsiApi.executeCommand('toggleVideo')
            }else{
                this.$toast.open({
                    message: 'Erro ao alterar Status da câmera. Verifique a conexão e seu dispositivo.',
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                })
            }
        },
        err=>{
            console.log(err)
            this.$toast.open({
                message: 'Erro ao alterar Status da câmera. Verifique a conexão e seu dispositivo.',
                type: 'error',
                duration: 5000,
                position: 'top-right'
            })
        }
    )
}
export function toggleChat(){
    this.chat_visible = !this.chat_visible
    if(this.chat_visible){
        this.$refs.chat_component.mensagens_lidas = this.$refs.chat_component.mensagens_list.length
    }
}
export function toggleFullScreen() {
    this.$refs['fullscreen'].toggle();
}
export function toggleMicrofone(){
    this.$http.post(`usuario_configs/`,
        {
            sala: this.$store.state.sala.id,
            usuario: this.$store.state.user.id,
            microfone: this.mudo
        }
        ).then(resp=>{
            if(resp.status === 200){
                this.mudo = !this.mudo
                this.$refs.jitsiRef.jitsiApi.executeCommand('toggleAudio')
            }else{
                this.$toast.open({
                    message: resp.statusText,
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                });
            }
        },
        err=>{
            this.$toast.open({
                message: err.statusText,
                type: 'error',
                duration: 5000,
                position: 'top-right'
            });
        })
}
export function togglePaineis(){
    if(this.paineis){
        this.$refs.rowPaineis.style.cssText = 'display: none !important'
        this.$refs.jitsiRef.style.height = 'calc(100vh - 58px)'
        this.$refs.jitsiRef.style.minHeight = 'calc(100vh - 58px)'
    }else{
        this.$refs.rowPaineis.style.cssText = ''
        this.$refs.jitsiRef.style.height = '100%'
        this.$refs.jitsiRef.style.minHeight = '300px'
    }
    this.paineis = !this.paineis
}
export function uploadAvatar(e) {
    let data = new FormData();
    data.append('avatar', e.target.files[0]);
    data.append('username', this.$store.state.user.username);
    let config = {header: {'Content-Type': 'image/png'}}
    this.$http.put(`users/${this.$store.state.user.id}/`, data, config)
        .then(
            response => {
                this.$toast.open({
                    message: 'Sua foto foi atualizada com sucesso!',
                    type: 'success',
                    duration: 5000,
                    position: 'top-right'
                });
                this.$store.commit('setUser', response.data);
                this.ballon_avatar = false
                this.$refs.jitsiRef.executeCommand('avatarUrl', this.$store.state.user.avatar)
            }, () => {
                this.$toast.open({
                    message: 'Ocorreu um erro ao enviar sua foto!',
                    type: 'error',
                    duration: 5000,
                    position: 'top-right'
                })
            }
        )
}
export function verificaPermissao(){
    return this.$store.state.user.is_staff || this.$store.state.sala.privado !== true ? true : this.$store.state.sala.moderadores_list && this.$store.state.sala.moderadores_list.includes(this.$store.state.user.id) || this.$store.state.sala.apresentadores_list && this.$store.state.sala.apresentadores_list.includes(this.$store.state.user.id) || this.$store.state.participantes_list && this.$store.state.participantes_list.includes(this.$store.state.user.id)
}
export function verificarMudo(){
    this.$refs.jitsiRef.esta_mudo()
}