var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_vm._l((_vm.messages),function(message){return _c('b-alert',{key:message,attrs:{"variant":"success","show":"","dismissible":"","fade":""}},[_vm._v(" "+_vm._s(message)+" ")])}),(_vm.show)?_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"Digite o email e tecle Enter"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Adicionar")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.4rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1",attrs:{"title":tag,"variant":tagVariant},on:{"submit":_vm.onSubmit,"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])}),1)]}}],null,false,2788319365),model:{value:(_vm.form.emails),callback:function ($$v) {_vm.$set(_vm.form, "emails", $$v)},expression:"form.emails"}}),_c('b-button',{attrs:{"type":"submit","variant":"outline-primary"},on:{"submit":_vm.onSubmit}},[_vm._v("Enviar convite")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }