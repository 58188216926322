<template>
    <div>
        <component v-bind:is="componente" />
    </div>
</template>

<script>
import Dashboard from "@/components/Dashboard/Dashboard";
import DashboardMobile from "@/components/Dashboard/DashboardMobile";
import { isMobile } from 'mobile-device-detect';
export default {
    components: {Dashboard, DashboardMobile, isMobile},
	computed:{
		componente(){
			return isMobile ? 'DashboardMobile' : 'Dashboard'
		}
	}
}
</script>
