<template>
    <ul ref="draggableContainer" id="draggable-container" :style="positionStyle">
        <li id="draggable-header" ref="header" @mousedown="dragMouseDown">
            <ul class="horizontal header">
                <li><font-awesome-icon :icon="options.icon" size="sm"/></li>
                <li><slot name="header" /></li>
                <li v-if="close_event" class="text-right float-right text-black-50">
                    <font-awesome-icon icon="times" size="sm" @click="close_event" style="cursor: pointer"/>
                </li>
            </ul>
        </li>
        <li id="main-content" ref="content" :style="contentHeight"><slot name="main" /></li>
        <li id="footer" ref="footer"><slot name="footer" /></li>
    </ul>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            standard: {
                icon: 'arrows-alt',
                position:{
                    top: 0,
                    left: 0,
                },
                width: '100px',
                height: '100px',
                close_event: null
            }
        },
        close_event: {standard: null}
    },
    name: 'DraggableDiv',
    data: function () {
        return {
            is_mounted: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            }
        }
    },
    computed: {
        positionStyle() {
            if(!this.is_mounted || !this.options) return;
            return {
                top: this.options.position.top,
                left: this.options.position.left,
                width: this.options.width,
                height: this.options.height
            }
        },
        contentHeight(){
            if(!this.is_mounted || !this.options) return;
            let total = this.options.height.replace('px', '');
            let header = this.$refs.header.clientHeight;
            let footer = this.$refs.footer.clientHeight;
            header = header > 0 ? header : 32;
            return {height: (total - header - footer)+'px'}
        }
    },
    methods: {
        dragMouseDown: function (event) {
            event.preventDefault()
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        }
    },
    mounted() {
        this.is_mounted = true;
    }
}
</script>

<style scoped>
#draggable-container {

}

#draggable-header {
    border-radius: 4px 4px 0 0;
    background-color: rgba(255, 171, 0, .4);
    color: whitesmoke;
    padding: 5px 10px;
}

ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
li{
    position: relative;
}

ul.horizontal li {
    display: inline;
}

.header li:not(:last-child){
    padding-right: 10px;
}

#main-content{
    padding: 5px 10px;
}
</style>