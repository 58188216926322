<template>
    <div id="app" :style="this.$route.name==='login' && !is_mobile ? {backgroundColor:'#D1D1CF'} : ''">
		<n-progress-container></n-progress-container>
        <div :class="{
            'fundo_escuro': ['sala', 'sala_moderador', 'sala_espectador', 'sala_apresentador', 'sala_WS', 'sala_moderador_WS'].includes(this.$route.name)
        }">
<!--			<transition :name="$store.state.transition">-->
			<transition name="fade" mode="out-in">
				<router-view/>
			</transition>
        </div>
    </div>
</template>


<script>
import 'moment/locale/pt-br'
import NProgressContainer from 'vue-nprogress/src/NprogressContainer'
import { isMobile } from 'mobile-device-detect'
import {dados, check_version} from "@/modulos/utils";

export default {
	components:{
		NProgressContainer
	},
	Components:{
		isMobile
	},
	data(){
		return dados
	},
	computed:{
		is_mobile(){
			return isMobile
		}
	},
	methods:{
		check_version
	},
    created() {
        this.$moment.locale('pt-br')
		// this.check_version()
    },
    mounted() {
        this.$moment.locale('pt-br')
    },
    metaInfo: {
		meta: [
			{ httpEquiv: 'Cache-Control', content: 'no-cache, no-store, must-revalidate'},
			{ httpEquiv: 'Pragma', content: 'no-cache'},
			{ httpEquiv: 'Expires', content: '0'}
		]
    }
}
</script>

<style>
body{
    font-size: 14px;
}
a.nav-link{
	color: inherit !important;
}
.fundo_escuro{
    background-color: #474747;
    height: 100vh;
}
#app{
    height: 100vh;
}
*:focus{
    outline:0 !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
ul.horizontal li {
    display: inline;
}

.router-link-active {
  color: red;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s ease;
}
.fade-leave {}
.fade-leave-active {
  transition: opacity 1s ease;
  opacity: 0;
}


.slide-left-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}
.slide-left-enter-to {
  position: absolute;
  right: 0;
}
.slide-left-enter-from {
  position: absolute;
  right: -100%;
}
.slide-left-leave-to {
  position: absolute;
  left: -100%;
}
.slide-left-leave-from {
  position: absolute;
  left: 0;
}


.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 1s ease;
}
.slide-right-enter-to {
  position: absolute;
  left: 0;
}
.slide-right-enter-from {
  position: absolute;
  left: -100%;
}
.slide-right-leave-to {
  position: absolute;
  right: -100%;
}
.slide-right-leave-from {
  position: absolute;
  right: 0;
}
</style>